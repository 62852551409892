import { useI18n } from 'panda-i18n';
import React from 'react';
import { CnEllipsis } from '../../../../components/cn-ellipsis';
import { CnIcon } from '../../../cn-icon';
import './index.scss';
import { EntryPointsContent } from '../entry-points-content';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
import classNames from 'classnames';
export var EntryPoints = function (props) {
    var icon = props.icon, iconBgColor = props.iconBgColor, title = props.title, viewMore = props.viewMore, dataSource = props.dataSource, onItemClick = props.onItemClick;
    var $i18n = useI18n();
    return withNativeProps(props, React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-m-entry-points') },
        icon || title || (viewMore === null || viewMore === void 0 ? void 0 : viewMore.visible) ? (React.createElement("div", { className: "cn-ui-m-entry-points-header" },
            React.createElement("div", { className: "cn-ui-m-entry-points-header-icon", style: { background: iconBgColor } },
                React.createElement(CnIcon, { size: "small", type: icon })),
            React.createElement(CnEllipsis, { className: "cn-ui-m-entry-points-header-title" }, title),
            (viewMore === null || viewMore === void 0 ? void 0 : viewMore.visible) ? (React.createElement("div", { className: "cn-ui-m-entry-points-header-view-more", onClick: viewMore.onClick }, viewMore.children || (React.createElement(React.Fragment, null,
                $i18n.get({ id: 'More', dm: '更多' }),
                React.createElement(CnIcon, { size: "small", type: "arrow-right" }))))) : null)) : null,
        React.createElement(EntryPointsContent, { onItemClick: onItemClick, dataSource: dataSource })));
};
