import './index.scss';
import classNames from 'classnames';
import React, { createContext, useContext } from 'react';
import { CnFormLayoutProps } from './types/form-layout';
import { useWrapperAlign } from './hooks/use-wrapper-align';
import { useLabelTextAlign } from './hooks/use-label-text-align';
import { useFormLabelAlign } from '@cainiaofe/cn-ui-common';
export { CnFormLayoutProps };
export var FormLayoutDeepContext = createContext(null);
export var useFormLayout = function () {
    var result = useContext(FormLayoutDeepContext);
    if (result)
        return result;
    // 兜底处理，避免组件在无上下文的时候抛出异常
    var temp = {
        labelAlign: 'left',
        wrapperAlign: 'left',
        labelTextAlign: 'left',
    };
    return temp;
};
export var CnFormLayout = function (props) {
    var children = props.children, className = props.className, style = props.style, size = props.size;
    var labelAlign = useFormLabelAlign(props.labelAlign);
    var wrapperAlign = useWrapperAlign(props.wrapperAlign);
    var labelTextAlign = useLabelTextAlign(props.labelTextAlign);
    return (React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-m-form-layout', className), style: style },
        React.createElement(FormLayoutDeepContext.Provider, { value: {
                size: size,
                labelAlign: labelAlign,
                wrapperAlign: wrapperAlign,
                labelWidth: props.labelWidth,
                labelTextAlign: labelTextAlign,
            } }, children)));
};
CnFormLayout.displayName = 'CnFormLayout';
/** 兼容一码多端 */
CnFormLayout.useFormShallowLayout = function () { return ({}); };
/** 兼容一码多端 */
CnFormLayout.useFormDeepLayout = useFormLayout;
/** 兼容一码多端 */
CnFormLayout.useFormLayout = useFormLayout;
