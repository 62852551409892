import { __assign, __rest } from "tslib";
import './styles/index.scss';
import React from 'react';
import { withI18n } from 'panda-i18n';
import { CnCascaderSelect as CnCascaderSelectView } from './cascader-select';
import locale from '@/locales';
import { CnCascaderSelectV2 } from '../cn-cascader-select-v2';
import pick from 'lodash/pick';
var CascaderSelect = function (props) {
    var version = props.version, multiple = props.multiple, restProps = __rest(props, ["version", "multiple"]);
    if (version === 'v2' || multiple) {
        var propsToPick = ['value'];
        var pickedProps = pick(props, propsToPick);
        return (React.createElement(CnCascaderSelectV2, __assign({ dataSource: restProps.dataSource, disabled: props.disabled, readOnly: props.readOnly, hasClear: props.hasClear, defaultValue: props.defaultValue, placeholder: props.placeholder, separator: props.separator, rootTipText: props.rootTipText, size: props.size, className: props.className, drawerClassName: props.drawerClassName, onChange: props.onChange, loadData: props.loadData, onSelect: props.onSelect, renderContent: props.renderContent, insideFilter: props.insideFilter, multiple: multiple, showSearch: props.showSearch, insideForm: props.insideForm, isArrayValue: props.isArrayValue, requestConfig: props.requestConfig }, pickedProps)));
    }
    return React.createElement(CnCascaderSelectView, __assign({}, restProps));
};
export var CnCascaderSelect = withI18n(CascaderSelect, {
    locale: locale,
});
