import { formatValue } from './format-value';
/**
 * 获取时间戳
 * @param v dayjs格式日期值
 * @param processTime 是否处理时间开始/结束
 * @param endOfDay 是否取时间结束
 * @returns
 */
export var getValueFromDayjs = function (v, processTime, endOfDay) {
    var fmt = function () {
        var getValue = function (p, type) {
            if (!p || !p.valueOf) {
                return null;
            }
            if (!processTime) {
                return p.valueOf();
            }
            if (type) {
                return p.endOf('date').valueOf();
            }
            return p.startOf('date').valueOf();
        };
        if (Array.isArray(endOfDay)) {
            return endOfDay.map(function (type) { return function (i) {
                return getValue(i, type);
            }; });
        }
        return function (i) { return getValue(i, endOfDay); };
    };
    return formatValue(v, fmt());
};
