import { __assign } from "tslib";
import * as React from 'react';
import isFunction from 'lodash/isFunction';
export function HocBaseComponents(BaseComponent, options) {
    var _a;
    var props = options.props, _b = options.plugins, plugins = _b === void 0 ? [] : _b, ref = options.ref, dataName = options.dataName;
    var baseComponentRef = React.useRef();
    // 初始化 expandProps 对象，包含 props、context 和 BaseComponent
    var expandProps = {
        props: __assign({}, props),
        context: {},
        BaseComponent: BaseComponent,
    };
    // 如果 ref 存在，将 ref 设置到 props 中，此操作需要提前，避免插件中获取不到 ref
    if (ref) {
        expandProps.props.ref = ref;
    }
    var _loop_1 = function (handleFun) {
        // 执行插件处理函数，获取返回的 props、context 和 render
        var _c = handleFun(expandProps.props, expandProps.context), pluginMergedProps = _c.props, context = _c.context, render = _c.render;
        // 如果 render 是一个函数且 baseComponentRef.current 不存在
        if (isFunction(render) && !baseComponentRef.current) {
            var UpperBaseComponent_1 = expandProps.BaseComponent;
            // 将 expandProps.BaseComponent 设置为一个新的函数组件
            expandProps.BaseComponent = React.forwardRef(function (baseComponentProps, upperRef) {
                // 将 baseComponentProps 和 ref 传递给 render 函数，获取新的 props
                // React.forwardRef 也具备此逻辑，需要验证下面的逻辑是否有必要
                if (typeof baseComponentProps === 'object' &&
                    baseComponentProps &&
                    typeof upperRef === 'object' &&
                    upperRef) {
                    return render(__assign(__assign({}, baseComponentProps), { ref: upperRef }), UpperBaseComponent_1);
                }
                return render(baseComponentProps, UpperBaseComponent_1);
            });
        }
        // 更新 expandProps 对象，合并新的 props 和 context
        expandProps = __assign(__assign({}, expandProps), { props: pluginMergedProps, context: context });
    };
    // 遍历插件数组，对每个插件执行处理函数
    for (var _i = 0, plugins_1 = plugins; _i < plugins_1.length; _i++) {
        var handleFun = plugins_1[_i];
        _loop_1(handleFun);
    }
    baseComponentRef.current =
        (_a = baseComponentRef.current) !== null && _a !== void 0 ? _a : expandProps.BaseComponent;
    var ExpandBaseComponent = baseComponentRef.current;
    if (dataName) {
        expandProps.props['data-name'] = dataName;
    }
    var eProps = __assign({}, expandProps.props);
    if (baseComponentRef.current) {
        return React.createElement(ExpandBaseComponent, __assign({}, eProps));
    }
    return React.createElement(BaseComponent, __assign({}, eProps));
}
