import { __assign } from "tslib";
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useRef } from 'react';
import { useStyleOverrides } from '../../hooks/use-style-overrides';
import More from './more';
import './styles/index.scss';
export var CnScrollView = React.forwardRef(function (props, ref) {
    var id = props.id, children = props.children, hasMore = props.hasMore, _a = props.loadMore, loadMore = _a === void 0 ? null : _a, _b = props.scrollEventThrottle, scrollEventThrottle = _b === void 0 ? 500 : _b, moreRender = props.moreRender, style = props.style;
    var overrides = useStyleOverrides(props, 'scroll-view');
    var $ref = useRef(null);
    var handler = useCallback(debounce(function (entries) {
        if (entries[0].isIntersecting) {
            if (hasMore) {
                loadMore && loadMore();
            }
        }
    }, scrollEventThrottle), [loadMore]);
    useEffect(function () {
        if (!window.IntersectionObserver) {
            console.error('IntersectionObserver is not supported');
            return;
        }
        var interInstance = new window.IntersectionObserver(handler);
        if (!$ref.current) {
            return;
        }
        interInstance.observe($ref.current);
        return function () {
            interInstance.disconnect();
        };
    }, [handler]);
    var styledScrollView = __assign(__assign({ overflow: 'auto' }, style), { height: props.height ? "".concat(props.height, "px") : 'auto' });
    return (React.createElement("div", __assign({}, overrides, { ref: ref, id: id, style: styledScrollView }),
        children,
        hasMore && React.createElement(More, { ref: $ref, moreRender: moreRender, "data-testid": "cn-scroll-view-more" })));
});
CnScrollView.defaultProps = {
    scrollEventThrottle: 500,
};
CnScrollView.displayName = 'CnScrollView';
export * from './types';
