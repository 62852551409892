import isFunction from 'lodash/isFunction';
export function getFormattedValue(inputValue, format, innerAfter, label) {
    var formatedValue = "".concat(inputValue);
    if (format && isFunction(format)) {
        formatedValue = format("".concat(inputValue));
    }
    var formatedValueWithInnerAfter = "".concat(formatedValue).concat(innerAfter);
    var vhasLabel = label + formatedValueWithInnerAfter;
    return vhasLabel;
}
