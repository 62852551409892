import * as React from 'react';
import { CnIcon } from '@/components/cn-icon';
import { StaticFileIcon } from '@/components/cn-oss-upload/components/upload-list-item/icons';
import { CnEllipsis } from '@/components/cn-ellipsis';
import { CnImageViewer } from '@/components/cn-image-viewer';
import { CnBox } from '@/components/cn-box';
import { calcFileSize, canUseImageViewer, } from '@cainiaofe/cn-ui-common';
export var FormatFile = function (props) {
    var value = props.value, mode = props.mode;
    if (Array.isArray(value)) {
        if (mode === 'simple') {
            var showNum = 3;
            var hasMore = value.length > showNum;
            return (React.createElement(CnBox, { direction: "row", spacing: 8 },
                value.slice(0, showNum).map(function (file) { return (React.createElement("div", { className: "cn-ui-m-card-items-format-file-ext" },
                    React.createElement(StaticFileIcon, { file: file }))); }),
                hasMore ? (React.createElement("div", { className: "cn-ui-m-card-items-format-more" },
                    showNum,
                    "+")) : null));
        }
        return (React.createElement("div", { className: "cn-ui-m-card-items-format-file cn-oss-upload-list" }, value.map(function (file) { return (React.createElement("div", { className: "cn-oss-upload-list-item-wrapper" },
            React.createElement("div", { className: "cn-oss-upload-list-item-content", onClick: function (e) {
                    e.stopPropagation();
                    var src = file.url;
                    if (canUseImageViewer(file)) {
                        CnImageViewer.open({ src: src });
                        return;
                    }
                    if (src)
                        window.open(src);
                } },
                React.createElement("div", { className: "cn-oss-upload-list-item-file-ext" },
                    React.createElement(StaticFileIcon, { file: file })),
                React.createElement("div", { className: "cn-oss-upload-list-item-info" },
                    React.createElement(CnEllipsis, { className: "cn-oss-upload-list-item-info-name", ellipsisPosition: "middle", endCharCount: 4, hasTooltip: false }, file.name),
                    file.size ? (React.createElement("div", { className: "cn-oss-upload-list-item-info-size" },
                        "(",
                        calcFileSize(file.size),
                        ")")) : null)),
            React.createElement("div", { onClick: function (event) {
                    event.stopPropagation();
                    window.open(file.downloadUrl || file.url);
                }, className: "cn-oss-upload-list-item-actions-item" },
                React.createElement(CnIcon, { type: "download", size: "small" })))); })));
    }
    return React.createElement(React.Fragment, null, "- -");
};
