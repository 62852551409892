import { __assign, __awaiter, __generator } from "tslib";
import * as ReactDOM from 'react-dom';
// Let compiler not to search module usage
var fullClone = __assign({}, ReactDOM);
var version = fullClone.version, reactRender = fullClone.render, unmountComponentAtNode = fullClone.unmountComponentAtNode;
var createRoot;
try {
    var mainVersion = Number((version || '').split('.')[0]);
    if (mainVersion >= 18 && fullClone.createRoot) {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        createRoot = fullClone.createRoot;
    }
}
catch (e) {
    // Do nothing;
}
function toggleWarning(skip) {
    var __SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED = fullClone.__SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;
    if (__SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED &&
        typeof __SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED === 'object') {
        __SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.usingClientEntryPoint =
            skip;
    }
}
var MARK = '__antd_mobile_root__';
function legacyRender(node, container) {
    reactRender(node, container);
}
function concurrentRender(node, container) {
    toggleWarning(true);
    var root = container[MARK] || createRoot(container);
    toggleWarning(false);
    root.render(node);
    container[MARK] = root;
}
export function render(node, container) {
    if (createRoot) {
        concurrentRender(node, container);
        return;
    }
    legacyRender(node, container);
}
// ========================== Unmount =========================
function legacyUnmount(container) {
    return unmountComponentAtNode(container);
}
function concurrentUnmount(container) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            // Delay to unmount to avoid React 18 sync warning
            return [2 /*return*/, Promise.resolve().then(function () {
                    var _a;
                    (_a = container[MARK]) === null || _a === void 0 ? void 0 : _a.unmount();
                    delete container[MARK];
                })];
        });
    });
}
export function unmount(container) {
    if (createRoot) {
        return concurrentUnmount(container);
    }
    return legacyUnmount(container);
}
