import * as React from 'react';
import classNames from 'classnames';
import { CnTabContext } from './context';
import './panel.scss';
var clsPrefix = 'cn-ui-m-tab';
export var CnTabPanel = React.forwardRef(function (props) {
    var _a;
    var _b = props || {}, itemKey = _b.itemKey, children = _b.children;
    var activeKey = React.useContext(CnTabContext).activeKey;
    var active = "".concat(activeKey) === "".concat(itemKey);
    var cls = classNames((_a = {},
        _a["".concat(clsPrefix, "-panel")] = true,
        _a["".concat(active ? 'active' : 'hidden')] = true,
        _a));
    return React.createElement("div", { className: cls }, children);
});
CnTabPanel.displayName = 'CnTabPanel';
