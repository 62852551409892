import { sortNodesByPos } from './sort-nodes-by-pos';
/**
 * 级联多选，获取提交数据
 * @param dataSource 数据源
 * @returns 提交数据
 */
export function getSubmitData(dataSource) {
    var values = [];
    var records = [];
    var checkedData = [];
    var indeterminateData = [];
    var getSelectedData = function (curList, flag) {
        if (flag === void 0) { flag = false; }
        for (var _i = 0, curList_1 = curList; _i < curList_1.length; _i++) {
            var item = curList_1[_i];
            var label = item.label, pos = item.pos, curValue = item.value, children = item.children, touchOrder = item.touchOrder, checked = item.checked, indeterminate = item.indeterminate;
            var curRecord = {
                label: label,
                pos: pos,
                value: curValue,
                touchOrder: touchOrder,
            };
            if (Array.isArray(children)) {
                Object.assign(curRecord, { children: children });
            }
            if (checked) {
                checkedData.push(curRecord);
                if (!flag) {
                    values.push(curValue);
                    records.push(curRecord);
                }
            }
            if (indeterminate) {
                indeterminateData.push(curRecord);
            }
            if (Array.isArray(children)) {
                getSelectedData(item.children, item.checked);
            }
        }
    };
    getSelectedData(dataSource, false);
    sortNodesByPos(records);
    return {
        values: values,
        records: records,
        details: {
            checkedData: checkedData,
            indeterminateData: indeterminateData,
        },
    };
}
