import { __assign } from "tslib";
import * as React from 'react';
import { CnIcon } from '@/components/cn-icon';
import { CnPopover } from '@/components/cn-popover';
import './cn-card-title.scss';
export var CardTitle = function (props) {
    var title = props.title, desc = props.desc, icon = props.icon, action = props.action;
    var iconProps = React.useMemo(function () {
        if (icon) {
            if (typeof icon === 'string') {
                return {
                    type: icon,
                };
            }
            return icon;
        }
        return null;
    }, [icon]);
    return (React.createElement("div", { className: "cn-ui-m-card-title" },
        React.createElement("div", { className: "cn-ui-m-card-title-header" },
            iconProps && React.createElement(CnIcon, __assign({ className: "icon" }, iconProps)),
            React.createElement("div", { className: "title" }, title),
            desc && (
            // 2023-10-12 已于设计师陈宁沟通，默认值改为 dark
            React.createElement(CnPopover, { mode: "dark", content: desc, trigger: "click" },
                React.createElement(CnIcon, { type: "icon-help", className: "desc" })))),
        React.createElement("div", { className: "cn-ui-m-card-title-footer" }, action)));
};
