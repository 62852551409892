import { __assign, __awaiter, __generator, __rest } from "tslib";
import React, { Fragment, useMemo } from 'react';
import classNames from 'classnames';
import { CnIcon } from '@/components/cn-icon';
import { CnButton } from '@/components/cn-button';
import { CnModal } from '@/components/cn-modal';
import { useI18n } from 'panda-i18n';
import { isValidArray } from '@/utils/func';
import { mergeProps } from '@/utils/with-default-props';
import isString from 'lodash/isString';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
var FooterWrapper = function (wrapperProps) {
    var centered = wrapperProps.centered, children = wrapperProps.children, others = __rest(wrapperProps, ["centered", "children"]);
    if (centered) {
        return React.createElement("div", __assign({}, others), children);
    }
    return React.createElement(Fragment, null, children);
};
var MESSAGE_TYPE = {
    alert: 'help',
    confirm: '',
    success: 'complete-r-fill',
    error: 'error-r-fill',
    warning: 'exclamation-t-fill',
    notice: 'exclamation-r-fill',
    help: 'exclamation-r-fill',
};
var defaultProps = {
    visible: false,
    type: 'normal',
    size: 'large',
    footer: true,
    centered: false,
    platform: 'ios',
};
export var CnDialog = function (props, ref) {
    var $i18n = useI18n();
    var _a = mergeProps(defaultProps, props), _b = _a.prefix, prefix = _b === void 0 ? 'cn-ui-m-' : _b, titleProp = _a.title, content = _a.content, type = _a.type, size = _a.size, visible = _a.visible, contentClassName = _a.contentClassName, _c = _a.bgImg, bgImg = _c === void 0 ? '' : _c, platform = _a.platform, closeModeProp = _a.closeMode, footerActionsProp = _a.footerActions, footer = _a.footer, centered = _a.centered, _d = _a.okProps, okProps = _d === void 0 ? { text: '', children: '' } : _d, _e = _a.cancelProps, cancelProps = _e === void 0 ? { text: '', children: '' } : _e, className = _a.className, showIconProp = _a.showIcon, children = _a.children, showBottomCloseIcon = _a.showBottomCloseIcon, _f = _a.onOk, onOk = _f === void 0 ? function () { } : _f, _g = _a.onCancel, onCancel = _g === void 0 ? function () { } : _g, _h = _a.onClose, onClose = _h === void 0 ? function () { } : _h, others = __rest(_a, ["prefix", "title", "content", "type", "size", "visible", "contentClassName", "bgImg", "platform", "closeMode", "footerActions", "footer", "centered", "okProps", "cancelProps", "className", "showIcon", "children", "showBottomCloseIcon", "onOk", "onCancel", "onClose"]);
    var showIcon = showIconProp === true ? true : !centered;
    var closeMode = useMemo(function () {
        if (closeModeProp) {
            return closeModeProp;
        }
        return centered
            ? ['ok', 'mask', 'cancel']
            : ['close', 'ok', 'mask', 'cancel'];
    }, [closeModeProp, centered]);
    var footerActions = useMemo(function () {
        if (footerActionsProp) {
            return footerActionsProp;
        }
        if (['alert', 'success', 'error', 'notice', 'warning', 'help'].indexOf(type) >
            -1) {
            return ['ok'];
        }
        return ['cancel', 'ok'];
    }, [footerActionsProp, type]);
    var iconName = useMemo(function () { return MESSAGE_TYPE[type] || ''; }, [type]);
    var clsPrefix = "".concat(prefix, "dialog");
    var closable = closeMode.includes('close');
    var clses = useMemo(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return ({
            dialog: classNames(CN_UI_HASH_CLASS_NAME, "".concat(clsPrefix), className, (_a = {},
                _a["".concat(clsPrefix, "--centered")] = centered,
                _a)),
            header: classNames("".concat(clsPrefix, "-header"), (_b = {},
                _b["".concat(clsPrefix, "-header--centered")] = centered,
                _b)),
            closeWrapper: classNames("".concat(clsPrefix, "-close-wrapper"), (_c = {},
                _c["".concat(clsPrefix, "-close-wrapper--content")] = !titleProp && closable,
                _c["".concat(clsPrefix, "-close-wrapper--centered")] = centered && closable,
                _c)),
            close: classNames("".concat(clsPrefix, "-close"), (_d = {},
                _d["".concat(clsPrefix, "-close--centered")] = centered,
                _d)),
            container: classNames("".concat(clsPrefix, "-container"), (_e = {},
                _e["".concat(clsPrefix, "-container--centered ").concat(clsPrefix, "-container--").concat(platform)] = centered,
                _e)),
            icon: classNames((_f = {},
                _f["".concat(clsPrefix, "-icon")] = true,
                _f["".concat(clsPrefix, "-icon--").concat(type)] = true,
                _f["".concat(clsPrefix, "-icon--centered")] = centered,
                _f)),
            title: classNames("".concat(clsPrefix, "-title"), (_g = {},
                _g["".concat(clsPrefix, "-title--centered")] = centered,
                _g["".concat(clsPrefix, "-title--").concat(platform)] = platform && centered,
                _g["".concat(clsPrefix, "-title--fullwidth")] = titleProp && closable,
                _g)),
            content: classNames(contentClassName, (_h = {},
                _h["".concat(clsPrefix, "-content")] = !centered,
                _h["".concat(clsPrefix, "-content--centered ").concat(clsPrefix, "-content--centered-").concat(platform)] = centered,
                _h)),
            innerContent: classNames("".concat(clsPrefix, "-inner-content"), (_j = {},
                _j["".concat(clsPrefix, "-inner-content--fullwidth")] = !closable || (closable && titleProp),
                _j["".concat(clsPrefix, "-inner-content--centered")] = centered,
                _j)),
            footer: classNames((_k = {},
                _k["".concat(clsPrefix, "-footer")] = !centered,
                _k["".concat(clsPrefix, "-footer--centered ").concat(clsPrefix, "-footer--centered-").concat(platform)] = centered,
                _k)),
        });
    }, [clsPrefix, className, centered, closable, titleProp, platform]);
    var okBtnText = okProps.text ||
        okProps.children ||
        $i18n.get({ id: 'Confirmation', dm: '确定', ns: 'CnDialog' });
    var cancelText = cancelProps.text ||
        cancelProps.children ||
        $i18n.get({ id: 'Cancel', dm: '取消', ns: 'CnDialog' });
    var renderInnerContent = function () {
        var innerContent = content;
        if (isString(content)) {
            innerContent = (React.createElement("span", { className: "".concat(clsPrefix, "-content-text") }, content));
        }
        else if (children) {
            innerContent = children;
        }
        if (bgImg) {
            return (React.createElement("div", { className: "".concat(clsPrefix, "-content-bgImgWrapper") },
                React.createElement("img", { className: "".concat(clsPrefix, "-content-bgImg"), src: bgImg }),
                React.createElement("div", { className: "".concat(clsPrefix, "-content-innerWrapper") }, innerContent)));
        }
        else {
            return innerContent;
        }
    };
    var renderTitle = function () {
        var title = titleProp || null;
        if (title) {
            title = isString(title) ? (React.createElement("span", { className: clses.title, style: {
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    lineClamp: '3',
                } }, title)) : (React.createElement("div", { className: clses.title }, title));
        }
        return title;
    };
    var renderFooter = function () {
        var wrapperClassName = classNames("".concat(clsPrefix, "-btn-col"), "".concat(clsPrefix, "-btn-col--").concat(platform));
        if (footer) {
            if (typeof footer !== 'boolean') {
                return React.createElement("div", { className: clses.footer }, footer);
            }
            if (isValidArray(footerActions)) {
                return (React.createElement("div", { className: [clses.footer, "footer-".concat(footerActions.length)].join(' ') }, footerActions.map(function (actionType, index) {
                    var _a;
                    var buttonProps = okProps;
                    if (typeof actionType === 'object') {
                        buttonProps = actionType;
                    }
                    else if (actionType === 'cancel') {
                        buttonProps = cancelProps;
                    }
                    var text = buttonProps.text, btnClassNameProp = buttonProps.className, model = buttonProps.model, _onClick = buttonProps.onClick, restProps = __rest(buttonProps, ["text", "className", "model", "onClick"]);
                    var isCancel = actionType === 'cancel';
                    var btnClassName = classNames(btnClassNameProp, (_a = {},
                        _a["".concat(clsPrefix, "-btn--item")] = true,
                        _a["".concat(clsPrefix, "-btn")] = !centered,
                        _a["".concat(clsPrefix, "-btn--centered")] = centered,
                        _a["".concat(clsPrefix, "-btn--centered-").concat(actionType)] = centered,
                        _a));
                    var btnText;
                    if (text) {
                        btnText = text;
                    }
                    else if (isCancel) {
                        btnText = cancelText;
                    }
                    else {
                        btnText = okBtnText;
                    }
                    return (React.createElement(FooterWrapper, { key: "mt_dialog_".concat(index), centered: centered, className: centered ? wrapperClassName : '' },
                        React.createElement(CnButton, __assign({ key: "mt_dialog_".concat(index), type: isCancel || text ? 'normal' : 'primary', size: size }, restProps, { className: btnClassName, onClick: function (e) { return __awaiter(void 0, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!isString(actionType)) return [3 /*break*/, 4];
                                            if (!isCancel) return [3 /*break*/, 1];
                                            onCancel(e);
                                            return [3 /*break*/, 3];
                                        case 1: return [4 /*yield*/, onOk(e)];
                                        case 2:
                                            _a.sent();
                                            _a.label = 3;
                                        case 3:
                                            if (closeMode.includes(actionType)) {
                                                onClose(actionType, e);
                                            }
                                            return [3 /*break*/, 5];
                                        case 4:
                                            if (actionType.onClick) {
                                                actionType.onClick(e);
                                            }
                                            _a.label = 5;
                                        case 5: return [2 /*return*/];
                                    }
                                });
                            }); } }), btnText)));
                })));
            }
        }
        return null;
    };
    var titleEl = renderTitle();
    var showHeader = titleEl || (!centered && iconName) || (closable && titleProp);
    return withNativeProps(props, React.createElement(CnModal, __assign({}, others, { ref: ref, bgImg: bgImg, visible: visible, maskClosable: closeMode.includes('mask'), placement: "center", containerClassName: clses.dialog, onClose: onClose }),
        React.createElement("div", { className: clses.container },
            showIcon && centered && iconName ? (React.createElement(CnIcon, { type: "icon-".concat(iconName), className: clses.icon, size: "xxl" })) : null,
            React.createElement("div", { className: "".concat(clsPrefix, "-main") },
                showHeader ? (React.createElement("div", { className: clses.header },
                    showIcon && !centered && iconName ? (React.createElement(CnIcon, { type: "icon-".concat(iconName), className: clses.icon, size: "small" })) : null,
                    titleEl)) : null,
                React.createElement("div", { className: clses.content },
                    React.createElement("div", { className: clses.innerContent }, renderInnerContent())))),
        renderFooter(),
        showBottomCloseIcon ? (React.createElement(CnIcon, { type: "error-r", className: "".concat(clsPrefix, "-bottom-close-icon"), size: "xxl", onClick: function () { return onClose(''); } })) : null));
};
CnDialog.displayName = 'CnDialog';
