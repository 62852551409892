import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
export var Portal = function (props) {
    var children = props.children, _a = props.container, container = _a === void 0 ? document.body : _a;
    var domRef = useRef(document.createElement('div'));
    var hasMount = useRef(false);
    useEffect(function () {
        if (!container)
            return undefined;
        var dom = domRef.current;
        return function () {
            container.removeChild(dom);
        };
    }, [container]);
    if (container && !hasMount.current) {
        hasMount.current = true;
        container.appendChild(domRef.current);
    }
    return createPortal(children, domRef.current);
};
