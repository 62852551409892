import './index.scss';
import * as React from 'react';
import { withNativeProps } from '@/utils/with-native-props';
import { CnSafeArea } from '@/components/cn-safe-area';
import { CnButtonGroup } from '@/components/cn-button-group';
import { usePageFooterHeight } from './hooks/use-page-footer-height';
import classNames from 'classnames';
export var CnPageFooter = function (props) {
    var id = props.id, leftSlot = props.leftSlot, middleSecondarySlot = props.middleSecondarySlot, middleSlot = props.middleSlot, rightSlot = props.rightSlot, _a = props.isOuterCnPage, isOuterCnPage = _a === void 0 ? false : _a, noPadding = props.noPadding;
    var ref = React.useRef(null);
    usePageFooterHeight({ ref: ref, isOuterCnPage: isOuterCnPage });
    var children = React.useMemo(function () {
        var temp = [];
        React.Children.forEach(leftSlot, function (item) {
            temp.push(item);
        });
        React.Children.forEach(middleSecondarySlot, function (item) {
            temp.push(item);
        });
        React.Children.forEach(middleSlot, function (item) {
            temp.push(item);
        });
        React.Children.forEach(rightSlot, function (item) {
            temp.push(item);
        });
        if (temp.length) {
            return (React.createElement(CnButtonGroup, { size: "large", fullWidth: true }, temp));
        }
        return null;
    }, [leftSlot, middleSecondarySlot, middleSlot, rightSlot]);
    return withNativeProps(props, React.createElement("div", { ref: ref, id: id, className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-m-page-footer') },
        React.createElement("div", { className: classNames('cn-ui-m-page-footer-content', {
                'cn-ui-m-page-footer-content-with-padding': !noPadding,
            }) },
            children,
            props.children),
        React.createElement(CnSafeArea, null)));
};
CnPageFooter.displayName = 'CnPageFooter';
