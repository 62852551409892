import { __assign } from "tslib";
import * as React from 'react';
import { useI18n, withI18n } from 'panda-i18n';
import locale from '@/locales';
import { observer, useField } from '@formily/react';
import { CnOSSUpload as OSSUpload, } from '@/components/cn-oss-upload';
import { getFormUploadProps } from '@cainiaofe/cn-ui-common';
export var CnOSSUpload = withI18n(observer(function (props) {
    var $i18n = useI18n();
    var fileListRef = React.useRef([]);
    var field = useField();
    return (React.createElement(OSSUpload, __assign({}, props, getFormUploadProps({
        fileListRef: fileListRef,
        field: field,
        props: props,
        uploadErrMsg: $i18n.get({
            id: 'PleaseWaitForTheUploadTo_2061225702',
            dm: '请等待上传结束',
            ns: 'CnOssUpload',
        }),
    }))));
}), { locale: locale, componentName: 'CnOssUpload' });
CnOSSUpload.displayName = 'CnOSSUpload';
