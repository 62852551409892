export function removePluginDuplicates(arr) {
    if (!Array.isArray(arr)) {
        return [];
    }
    var seen = new Set();
    var result = [];
    for (var i = arr.length - 1; i >= 0; i--) {
        var element = arr[i];
        var name_1 = element.name;
        if (!seen.has(name_1)) {
            seen.add(name_1);
            result.unshift(element);
        }
    }
    return result;
}
