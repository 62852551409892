/**
 * 根据精度返回format字符串
 * @param precision 精度
 * @returns formatStrig
 */
export var getFormatStrFromPrecision = function (precision) {
    if (precision === 'day') {
        return 'YYYY-MM-DD';
    }
    else if (precision === 'hour') {
        return 'YYYY-MM-DD HH:00:00';
    }
    else if (precision === 'minute') {
        return 'YYYY-MM-DD HH:mm:00';
    }
    else if (precision === 'second') {
        return 'YYYY-MM-DD HH:mm:ss';
    }
    else if (precision === 'month') {
        return 'YYYY-MM';
    }
    else if (precision === 'year') {
        return 'YYYY';
    }
    else {
        return 'YYYY-MM-DD';
    }
};
