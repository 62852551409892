import { __assign, __rest } from "tslib";
import * as React from 'react';
import { forwardRef } from 'react';
import classNames from 'classnames';
import ListItemCaption from './caption';
import ListItemExtra from './extra';
import ItemContent from './content';
import { CnListItemAction, CnListItemMedia } from './media';
import ItemTitle from './title';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import { CnIcon } from '@/components/cn-icon';
var arrowIconMap = {
    right: 'arrow-right',
    up: 'arrow-up',
    down: 'arrow-down',
    loading: 'loading',
};
var renderExtra = function (ext) {
    if (isString(ext)) {
        return React.createElement(ListItemExtra, null, ext);
    }
    else if (ext) {
        return ext;
    }
    return null;
};
var ListItem = function (props, ref) {
    var _a;
    var children = props.children, _b = props.prefix, prefix = _b === void 0 ? 'cn-ui-m-' : _b, arrow = props.arrow, media = props.media, title = props.title, subTitle = props.subTitle, description = props.description, extra = props.extra, className = props.className, onClick = props.onClick, _c = props.disabled, disabled = _c === void 0 ? false : _c, mediaImgUrl = props.mediaImgUrl, mediaIcon = props.mediaIcon, extraBadge = props.extraBadge, extraTags = props.extraTags, others = __rest(props, ["children", "prefix", "arrow", "media", "title", "subTitle", "description", "extra", "className", "onClick", "disabled", "mediaImgUrl", "mediaIcon", "extraBadge", "extraTags"]);
    return (React.createElement("div", __assign({}, others, { className: classNames("".concat(prefix, "list-item"), (_a = {},
            _a["".concat(prefix, "list-item--clickable")] = isFunction(onClick),
            _a["".concat(prefix, "list-item--disabled")] = disabled,
            _a), className), ref: ref, onClick: function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            if (!disabled && onClick) {
                onClick.apply(void 0, args);
            }
        } }),
        React.createElement("div", { className: "".concat(prefix, "list-item-inner") },
            media ? React.createElement(CnListItemMedia, null, media) : null,
            mediaImgUrl ? React.createElement(CnListItemMedia, { mediaImgUrl: mediaImgUrl }) : null,
            mediaIcon ? React.createElement(CnListItemMedia, { icon: mediaIcon }) : null,
            React.createElement("div", { className: "".concat(prefix, "list-item-container") },
                title || description ? (React.createElement(ItemContent, null,
                    title ? React.createElement(ItemTitle, null, title) : null,
                    subTitle ? React.createElement(ListItemCaption, null, subTitle) : null,
                    description ? (React.createElement(ListItemCaption, null, description)) : null)) : null,
                extraBadge ? React.createElement(CnListItemAction, { extraBadge: extraBadge }) : null,
                extraTags ? React.createElement(CnListItemAction, { extraTags: extraTags }) : null,
                extra || isString(arrow) ? (React.createElement(CnListItemAction, null,
                    renderExtra(extra),
                    isString(arrow) ? (React.createElement(CnIcon, { size: "xs", className: "".concat(prefix, "list-item-icon"), type: arrowIconMap[arrow] })) : null)) : null,
                children))));
};
var CnListItem = forwardRef(ListItem);
CnListItem.displayName = 'CnListItem';
export { CnListItem };
