import { __assign } from "tslib";
import $i18n from 'panda-i18n';
import React from 'react';
import classNames from 'classnames';
import { CnIcon } from '../cn-icon';
import { CnDrawer } from '../cn-drawer';
import { CnEntryPoints } from '../cn-entry-points';
export var MoreMenu = function (props) {
    var _a, _b;
    var activeKey = props.activeKey, dataSource = props.dataSource, updateActiveKey = props.updateActiveKey, hideMenuKeys = props.hideMenuKeys, boxRef = props.boxRef, moreDrawerVisible = props.moreDrawerVisible, updateMoreDrawerVisible = props.updateMoreDrawerVisible;
    var formatMenuData = function (menuData) {
        if (!Array.isArray(menuData) || menuData.length === 0)
            return [];
        return menuData.map(function (item) { return (__assign(__assign({}, item), { label: item.title || item.text, value: item.key, children: Array.isArray(item.children) && item.children.length === 0
                ? null
                : formatMenuData(item.children) })); });
    };
    var activeKeyChange = function (item) {
        updateActiveKey(item === null || item === void 0 ? void 0 : item.key, item);
        updateMoreDrawerVisible(false);
    };
    var renderTotalMenu = function () {
        return Array.isArray(dataSource) && dataSource.length
            ? dataSource.map(function (item) {
                var hasChildren = Array.isArray(item.children) && item.children.length;
                return (React.createElement(React.Fragment, null,
                    React.createElement(CnEntryPoints, { title: item.title, className: "cn-bottom-nav-bar-more-entry-points", icon: item.icon, onItemClick: activeKeyChange, dataSource: formatMenuData(hasChildren ? item.children : [item]) })));
            })
            : null;
    };
    var bottomNavBarHeight = (_a = boxRef === null || boxRef === void 0 ? void 0 : boxRef.current) === null || _a === void 0 ? void 0 : _a.clientHeight;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classNames('cn-bottom-nav-bar-item', {
                'cn-bottom-nav-bar-item-active': hideMenuKeys.includes(activeKey) || moreDrawerVisible,
            }), onClick: function () {
                updateMoreDrawerVisible(!moreDrawerVisible);
            } },
            React.createElement(CnIcon, { outerClassName: "cn-bottom-nav-bar-item-icon", size: 'xl', type: "ellipsis-more" }),
            React.createElement("div", { className: "cn-bottom-nav-bar-item-title" }, $i18n.get({ id: 'More', dm: '更多', ns: 'CnBottomNavBar' }))),
        React.createElement(CnDrawer, { visible: moreDrawerVisible, className: "cn-bottom-nav-bar-more-drawer", placement: "bottom", onClose: function () {
                updateMoreDrawerVisible(false);
            }, closeMode: ['close', 'mask'], style: {
                bottom: "".concat(bottomNavBarHeight || 0, "px"),
                height: "".concat(window.innerHeight - (((_b = boxRef === null || boxRef === void 0 ? void 0 : boxRef.current) === null || _b === void 0 ? void 0 : _b.clientHeight) || 0) - 80, "px"),
            }, noCard: true }, renderTotalMenu())));
};
