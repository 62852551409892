import { __awaiter, __generator } from "tslib";
import { CnMessage } from '@/components/cn-message';
export var loading = function (title, processor) { return __awaiter(void 0, void 0, void 0, function () {
    var loadingTimeoutInstance;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                title = title || 'Loading...';
                loadingTimeoutInstance = setTimeout(function () {
                    CnMessage.show(title);
                }, 100);
                _a.label = 1;
            case 1:
                _a.trys.push([1, , 3, 4]);
                return [4 /*yield*/, processor()];
            case 2: return [2 /*return*/, _a.sent()];
            case 3:
                CnMessage.hide();
                clearTimeout(loadingTimeoutInstance);
                return [7 /*endfinally*/];
            case 4: return [2 /*return*/];
        }
    });
}); };
