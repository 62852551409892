import $i18n from 'panda-i18n';
import React from 'react';
import classNames from 'classnames';
import './index.scss';
import { date2StringByMode } from '@/utils/date-to-string-by-mode';
var prefix = 'cn-ui-m-date-range-picker-pro-drawer-tab';
export var DrawerTab = function (props) {
    var _a, _b, _c, _d;
    var _e = props.activeKey, activeKey = _e === void 0 ? 'begin' : _e, mode = props.mode, onTabChange = props.onTabChange, isCorrect = props.isCorrect, data = props.data, format = props.format;
    var isBegin = activeKey === 'begin';
    var isEnd = activeKey === 'end';
    var beginCls = classNames([
        "".concat(prefix, "--wrapper-item"),
        (_a = {},
            _a["".concat(prefix, "--wrapper-item--active")] = isBegin,
            _a),
    ]);
    var endCls = classNames([
        "".concat(prefix, "--wrapper-item"),
        (_b = {},
            _b["".concat(prefix, "--wrapper-item--active")] = isEnd,
            _b["".concat(prefix, "--wrapper-item--error")] = !isCorrect,
            _b),
    ]);
    return (React.createElement("div", { className: "".concat(prefix, "--wrapper") },
        React.createElement("div", { className: beginCls, onClick: function () { return onTabChange('begin'); } },
            React.createElement("div", null, $i18n.get({
                id: 'StartTime',
                dm: '开始时间',
                ns: 'CnDatePickerPro',
            })),
            React.createElement("div", { className: classNames("".concat(prefix, "--wrapper-item-value")) }, (data === null || data === void 0 ? void 0 : data.length) ? date2StringByMode(data[0], mode, format) : null),
            React.createElement("div", { className: classNames("".concat(prefix, "--wrapper-active-line"), (_c = {},
                    _c["".concat(prefix, "--wrapper-active-line--enter")] = isBegin,
                    _c["".concat(prefix, "--wrapper-active-line--exit")] = !isBegin,
                    _c)) },
                React.createElement("div", { className: "".concat(prefix, "--wrapper-active-line-block") }))),
        React.createElement("div", { className: endCls, onClick: function () { return onTabChange('end'); } },
            React.createElement("div", null, $i18n.get({ id: 'EndTime', dm: '截止时间', ns: 'CnDatePickerPro' })),
            React.createElement("div", { className: classNames("".concat(prefix, "--wrapper-item-value")) }, (data === null || data === void 0 ? void 0 : data.length) > 1 ? date2StringByMode(data[1], mode, format) : null),
            React.createElement("div", { className: classNames("".concat(prefix, "--wrapper-active-line"), (_d = {},
                    _d["".concat(prefix, "--wrapper-active-line--enter")] = isEnd,
                    _d["".concat(prefix, "--wrapper-active-line--exit")] = !isEnd,
                    _d)) },
                React.createElement("div", { className: "".concat(prefix, "--wrapper-active-line-block") })))));
};
