import { __spreadArray } from "tslib";
/**
 * 获取合并值
 * @param values 级联数中选择的values
 * @param value 传入的value
 * @returns
 */
export function getMergedValue(values, value) {
    if (!Array.isArray(values)) {
        return [];
    }
    var curValues = __spreadArray([], values, true);
    if (Array.isArray(value)) {
        value.forEach(function (item) {
            if (!curValues.includes(item)) {
                curValues.push(item);
            }
        });
    }
    return curValues;
}
