import { __assign, __rest } from "tslib";
import classNames from 'classnames';
import React, { useContext } from 'react';
import { CnList } from '../cn-list';
import { CnCheckbox } from '../cn-checkbox';
import SelectContext from './context';
export default function Option(props) {
    var _a;
    var _b = props.disabled, disabled = _b === void 0 ? false : _b, children = props.children, className = props.className, value = props.value, _c = props.isNew, isNew = _c === void 0 ? false : _c, _d = props.onClick, onClick = _d === void 0 ? function () { } : _d, _e = props.selected, selected = _e === void 0 ? false : _e, _f = props.multiple, multiple = _f === void 0 ? true : _f, title = props.title, others = __rest(props, ["disabled", "children", "className", "value", "isNew", "onClick", "selected", "multiple", "title"]);
    var prefix = useContext(SelectContext).prefix;
    var clsPrefix = "".concat(prefix, "select-option");
    return (React.createElement(CnList.Item, __assign({}, others, { className: classNames(CN_UI_HASH_CLASS_NAME, clsPrefix, className, (_a = {},
            _a["".concat(clsPrefix, "--disabled")] = disabled,
            _a["".concat(clsPrefix, "--selected")] = selected,
            _a)), title: children || title, media: multiple ? (React.createElement(CnCheckbox, { checked: selected, disabled: disabled || isNew, className: "".concat(clsPrefix, "-checkbox") })) : undefined, onClick: function () {
            if (!disabled) {
                onClick(value);
            }
        } })));
}
Option.displayName = 'CnSelectOption';
