import { CnFormItem } from '@/form/cn-form-item';
import { CnInput } from '@/form/cn-input';
import { CnInputTextArea } from '@/form/cn-input-textarea';
import { CnNumberPicker } from '@/form/cn-number-picker';
import { CnOSSUpload } from '@/form/cn-oss-upload';
import { CnOSSImageUpload } from '@/form/cn-oss-image-upload';
import { CnSelect } from '@/form/cn-select';
import { CnAsyncSelect } from '@/form/cn-async-select';
import { CnDepartmentSelect } from '@/form/cn-department-select';
import { CnEmployeeSelect } from '@/form/cn-employee-select';
import { CnFormGrid } from '@/form/cn-form-grid';
import { CnCard, CnCardSubCard } from '@/form/cn-card';
import { CnSwitch } from '@/form/cn-switch';
import { CnRating } from '@/form/cn-rating';
import { CnRadioGroup } from '@/form/cn-radio-group';
import { CnCascaderSelect } from '@/form/cn-cascader-select';
import { CnSelector } from '@/form/cn-selector';
import { CnFormArrayCard } from '@/form/cn-form-array-card';
import { CnTimePickerPro } from '@/form/cn-time-picker-pro';
import { CnReadOnly } from '@/form/cn-read-only';
import { CnSearch } from '@/form/cn-search';
import { CnCheckbox, CnCheckboxGroup } from '@/form/cn-checkbox-group';
import { CnDatePickerPro, CnWeekPickerPro, CnMonthPickerPro, CnYearPickerPro, } from '@/form/cn-date-picker-pro';
import { CnRangeDatePickerPro } from '@/form/cn-range-date-picker-pro';
import { CnAgreement } from '@/form/cn-agreement';
import { CnResult } from '@/form/cn-result';
import { CnStep, CnStepItem } from '@/form/cn-step';
import { CnRangeTimePickerPro } from '@/form/cn-range-time-picker-pro';
export var componentMap = {
    CnFormItem: CnFormItem,
    CnFilterItem: CnFormItem,
    CnFilterProItem: CnFormItem,
    CnSelect: CnSelect,
    CnAsyncSelect: CnAsyncSelect,
    CnDepartmentSelect: CnDepartmentSelect,
    CnEmployeeSelect: CnEmployeeSelect,
    CnInput: CnInput,
    CnInputTextArea: CnInputTextArea,
    CnNumberPicker: CnNumberPicker,
    CnOSSUpload: CnOSSUpload,
    CnOSSImageUpload: CnOSSImageUpload,
    CnFormGrid: CnFormGrid,
    CnCard: CnCard,
    CnCardSubCard: CnCardSubCard,
    CnSwitch: CnSwitch,
    CnRating: CnRating,
    CnRadioGroup: CnRadioGroup,
    CnReadOnly: CnReadOnly,
    CnCascaderSelect: CnCascaderSelect,
    CnSelector: CnSelector,
    CnFormArrayCard: CnFormArrayCard,
    CnTimePickerPro: CnTimePickerPro,
    CnDatePickerPro: CnDatePickerPro,
    CnCheckbox: CnCheckbox,
    CnCheckboxGroup: CnCheckboxGroup,
    CnWeekPickerPro: CnWeekPickerPro,
    CnMonthPickerPro: CnMonthPickerPro,
    CnYearPickerPro: CnYearPickerPro,
    CnDateRangePickerPro: CnRangeDatePickerPro,
    CnRangeDatePickerPro: CnRangeDatePickerPro,
    CnAgreement: CnAgreement,
    CnResult: CnResult,
    CnStep: CnStep,
    CnStepItem: CnStepItem,
    CnSearch: CnSearch,
    CnRangeTimePickerPro: CnRangeTimePickerPro,
};
