import { __assign } from "tslib";
import { isFinite, isPlainObject, padStart } from 'lodash';
/**
 * 处理员工数据源
 * @param dataSource
 * @returns 返回key为工号的map数据
 */
export function transformToEmployeeMap(dataSource) {
    var employeeMap = {};
    dataSource.forEach(function (employeeItem) {
        if (employeeItem.workNo) {
            if (!employeeMap[employeeItem.workNo]) {
                employeeMap[employeeItem.workNo] = employeeItem;
            }
        }
    });
    return employeeMap;
}
/** 第一位是数字则补0 */
export var workNoPadZero = function (vo) {
    if (isFinite(+(vo === null || vo === void 0 ? void 0 : vo[0]))) {
        return padStart(vo, 6, '0');
    }
    return vo;
};
export function getFormattedValue(value, valuePaddingZero) {
    if (!value) {
        return undefined;
    }
    var list = Array.isArray(value) ? value : [value];
    return list.reduce(function (acc, item) {
        if (typeof item === 'string') {
            acc.push({
                value: valuePaddingZero ? workNoPadZero(item) : item,
            });
        }
        else if (isPlainObject(item)) {
            var workNo = item.value || item.workNo || '';
            acc.push(__assign(__assign({}, item), { value: valuePaddingZero ? workNoPadZero(workNo) : workNo }));
        }
        return acc;
    }, []);
}
