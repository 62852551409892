import { __assign } from "tslib";
import React, { useContext } from 'react';
import { CnForm } from '@/form/cn-form';
import { CnFormItem } from '@/form/cn-form-item';
import { CardItemsStateCtx } from '../../context/card-items';
import { ItemFormat } from '../item-format';
/** 通过表单的样式渲染 */
export var ItemForm = function (props) {
    var _a = useContext(CardItemsStateCtx), columns = _a.columns, labelAlign = _a.detailLabelAlign;
    return (React.createElement(CnForm, { hasFooterSubmit: false, formLayoutProps: { labelAlign: labelAlign } }, columns.map(function (column, index) {
        var title = column.title, dataIndex = column.dataIndex, tip = column.tip;
        return (React.createElement(CnFormItem, { title: title || dataIndex, key: dataIndex || index, tip: tip },
            React.createElement(ItemFormat, __assign({}, props, { mode: "detail", column: column }))));
    })));
};
