import { __assign } from "tslib";
import { removePluginDuplicates } from './remove-plugin-duplicates';
// 自动注册到全局的插件
//
var PluginManager = /** @class */ (function () {
    function PluginManager() {
        this.plugin = {};
        this.registeredPlugin = {};
        this.getGlobalPlugin();
    }
    PluginManager.prototype.getGlobalPlugin = function () {
        var plugin = window.CN_UI_PLUGIN || [];
        for (var _i = 0, plugin_1 = plugin; _i < plugin_1.length; _i++) {
            var _a = plugin_1[_i], name_1 = _a.name, pluginFun = _a.plugin, pluginFuns = _a.plugins;
            if (Array.isArray(this.plugin[name_1])) {
                this.plugin[name_1] = removePluginDuplicates(this.plugin[name_1].concat(pluginFuns !== null && pluginFuns !== void 0 ? pluginFuns : pluginFun));
            }
            else {
                this.plugin[name_1] = pluginFuns !== null && pluginFuns !== void 0 ? pluginFuns : pluginFun;
            }
        }
    };
    PluginManager.prototype.registerPlugin = function (pluginList) {
        var _a;
        var registerPlugin = (_a = pluginList === null || pluginList === void 0 ? void 0 : pluginList.map) === null || _a === void 0 ? void 0 : _a.call(pluginList, function (plugin) {
            return {
                name: plugin.componentName,
                plugins: removePluginDuplicates(plugin.plugins.map(function (item) { return ({
                    name: item === null || item === void 0 ? void 0 : item.name,
                    method: item.sourceCode,
                    lowCodeConfig: item.lowCode,
                }); })),
            };
        });
        var registerComponentMap = {};
        if (Array.isArray(window.CN_UI_PLUGIN)) {
            window.CN_UI_PLUGIN.forEach(function (item) {
                var _a;
                var _b;
                if (!registerComponentMap[item.name]) {
                    registerComponentMap[item.name] = {
                        name: item.name,
                        plugins: (_b = item.plugins) !== null && _b !== void 0 ? _b : [],
                    };
                }
                else {
                    (_a = registerComponentMap[item.name].plugins).push.apply(_a, item.plugins);
                }
            });
        }
        registerPlugin.forEach(function (item) {
            var _a;
            var _b;
            if (!registerComponentMap[item.name]) {
                registerComponentMap[item.name] = {
                    name: item.name,
                    plugins: (_b = item.plugins) !== null && _b !== void 0 ? _b : [],
                };
            }
            else {
                (_a = registerComponentMap[item.name].plugins).push.apply(_a, item.plugins);
            }
        });
        // 去重并转换为数组
        var registerDuplicatesPlugin = [];
        Object.values(registerComponentMap).forEach(function (item) {
            registerDuplicatesPlugin.push({
                name: item.name,
                plugins: removePluginDuplicates(item.plugins),
            });
        });
        window.CN_UI_PLUGIN = registerDuplicatesPlugin;
        // 注册即生效，后续需要删除 loadPlugins 和 loadFullPlugins 能力
        this.getGlobalPlugin();
        this.loadFullPlugins();
    };
    PluginManager.prototype.loadPlugins = function (componentName) {
        var _this = this;
        if (Array.isArray(componentName)) {
            componentName.forEach(function (name) {
                _this.registeredPlugin[name] = _this.plugin[name] || [];
            });
        }
        else {
            this.registeredPlugin[componentName] = this.plugin[componentName] || [];
        }
    };
    PluginManager.prototype.loadFullPlugins = function () {
        this.registeredPlugin = __assign({}, this.plugin);
    };
    // getPluginsByComponentName(componentName) {
    PluginManager.prototype.getPluginsByComponentName = function (componentName) {
        var _this = this;
        var _a;
        if (Array.isArray(componentName)) {
            return componentName.reduce(function (plugin, name) {
                return plugin.concat(_this.registeredPlugin[name] || []);
            }, []);
            // .map((item) => item.method);
        }
        return (_a = this.registeredPlugin[componentName]) !== null && _a !== void 0 ? _a : [];
    };
    // getPluginsByPluginName(pluginName) {
    PluginManager.prototype.getPluginByPluginName = function (pluginName, componentName) {
        var _a, _b;
        var componentPlugin = (_a = this.plugin[componentName]) !== null && _a !== void 0 ? _a : [];
        var fullPluginList = Object.values(this.plugin).flat();
        var pluginList = componentName ? componentPlugin : fullPluginList;
        return (_b = pluginList === null || pluginList === void 0 ? void 0 : pluginList.find) === null || _b === void 0 ? void 0 : _b.call(pluginList, function (item) { return item.name === pluginName; });
    };
    return PluginManager;
}());
export { PluginManager };
