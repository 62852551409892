import { __assign } from "tslib";
import React from 'react';
import { findInArray, getDataSourceFromChildren, isUndef, isValidArray } from '@/utils/func';
import SelectValue from './section/select-value';
export function removeItemFromArray(arr, val) {
    if (arr === void 0) { arr = []; }
    if (val === void 0) { val = ''; }
    return arr.filter(function (v) { return v.value !== val; });
}
export function getInitValues(props) {
    var defaultValue = props.defaultValue, value = props.value;
    var initValues;
    if ('value' in props) {
        initValues = value;
    }
    else if (!isUndef(defaultValue)) {
        initValues = defaultValue;
    }
    if (!Array.isArray(initValues)) {
        if (!isUndef(initValues)) {
            initValues = [initValues];
        }
        else {
            initValues = [];
        }
    }
    return initValues;
}
export function findValueItem(arr, value) {
    for (var i = 0; i < arr.length; i++) {
        if (arr[i].value === value) {
            return arr[i];
        }
        if (arr[i].children) {
            var valueItem = findValueItem(arr[i].children, value);
            if (valueItem) {
                return valueItem;
            }
        }
    }
    return null;
}
export function getPreviewValue(props) {
    var useDetailValue = props.useDetailValue, children = props.children, dataSource = props.dataSource, mode = props.mode;
    var initValues = getInitValues(props);
    var ds = dataSource || [];
    var arr = [];
    if (!dataSource && React.Children.count(children) > 0) {
        ds = getDataSourceFromChildren(children);
    }
    if (useDetailValue && isValidArray(initValues)) {
        arr = initValues;
    }
    else if (isValidArray(initValues)) {
        arr = initValues.map(function (v) {
            var rs = findValueItem(ds, v);
            return (rs || {
                label: v,
                value: v,
            });
        });
    }
    // 如果是单选项， 返回单个值
    if (mode === 'single') {
        return isValidArray(arr) ? arr[0] : '';
    }
    return arr;
}
/**
 * get scrollview height
 */
export function getScrollHeight() {
    return "".concat((screen.availHeight - 200) / (screen.width / 750), "rpx");
}
/**
 * build-in renderSelection
 */
export function innerRenderSelection(items, values) {
    var labels = !Array.isArray(items) && items.label ? [items.label] : items.map(function (item) { return item.label; });
    if (labels.length === 0) {
        return null;
    }
    return React.createElement(SelectValue, __assign(__assign({}, values), { items: labels }));
}
export function isSelected(curItems, value) {
    var rs = findInArray(curItems, function (item) { return item.value === value; });
    return !!rs;
}
export function isEqualItems(arr1, arr2, strictMode) {
    if (arr1 === void 0) { arr1 = []; }
    if (arr2 === void 0) { arr2 = []; }
    if (strictMode === void 0) { strictMode = false; }
    if (!Array.isArray(arr1) || !Array.isArray(arr2) || arr1.length !== arr2.length) {
        return false;
    }
    var filterFn = function (item) { return item.value; };
    if (strictMode) {
        filterFn = function (item) { return item.label + item.value; };
    }
    return arr1.map(filterFn).sort().toString() === arr2.map(filterFn).sort().toString();
}
/**
 * 获取用于展示的事件返回值
 * @param selectedItems
 * @param useDetailValue
 * @param isSingle
 */
export function getValidEvtValues(_a) {
    var selectedItems = _a.selectedItems, useDetailValue = _a.useDetailValue, isSingle = _a.isSingle;
    var value = isSingle ? null : [];
    var items = isSingle ? {} : [];
    if (isValidArray(selectedItems)) {
        value = selectedItems[0].value;
        if (useDetailValue) {
            value = isSingle ? selectedItems[0] : selectedItems;
        }
        else if (!isSingle) {
            value = selectedItems.map(function (item) { return item.value; });
        }
        items = isSingle ? selectedItems[0] : selectedItems;
    }
    return {
        value: value,
        items: items,
    };
}
/**
 * default search filter
 * @param searchText
 * @param item
 */
export function defaultLocalFilter(searchText, item) {
    try {
        return new RegExp(searchText).test(item.label);
    }
    catch (e) {
        return item.label.includes(searchText);
    }
}
/**
 * 与pc保持一致，搜索的key不区分大小写，搜索项不区分label和value
 */
export function defaultLocalFilterLowerCase(searchText, item) {
    try {
        var searchTextLowerCase = searchText.toLowerCase();
        var newItem = {
            label: item.label.toLowerCase(),
            value: item.value.toLowerCase(),
        };
        return (new RegExp(searchTextLowerCase).test(newItem.label) ||
            new RegExp(searchTextLowerCase).test(newItem.value));
    }
    catch (e) {
        return item.label.includes(searchText);
    }
}
