import React from 'react';
import './cn-demo-block.scss';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
var CnDemoBlock = function (props) {
    var _a = props.padding, padding = _a === void 0 ? '12px 12px' : _a;
    return withNativeProps(props, React.createElement("div", { className: "cn-ui-m-demo-block" },
        React.createElement("div", { className: "title" }, props.title),
        React.createElement("div", { className: "main", style: {
                padding: padding,
            } }, props.children)));
};
CnDemoBlock.displayName = 'CnDemoBlock';
export default CnDemoBlock;
