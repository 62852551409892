import { __assign, __rest } from "tslib";
import * as React from 'react';
import { CnBox } from '@/components/cn-box';
import { observer } from '@formily/react';
export var CnFormGrid = observer(function (props) {
    var children = props.children, otherProps = __rest(props, ["children"]);
    if (!children) {
        return null;
    }
    return React.createElement(CnBox, __assign({}, otherProps), children);
});
