import { __assign, __rest } from "tslib";
import * as React from 'react';
import classNames from 'classnames';
import { CnModal } from '@/components/cn-modal';
import { CnIcon } from '@/components/cn-icon';
import isFunction from 'lodash/isFunction';
export var CnPopup = function (props) {
    var _a = props.prefix, prefix = _a === void 0 ? 'cn-ui-m-' : _a, _b = props.closeIconType, closeIconType = _b === void 0 ? 'icon-error' : _b, _c = props.bgColor, bgColor = _c === void 0 ? 'transparent' : _c, visible = props.visible, children = props.children, _d = props.closeMode, closeMode = _d === void 0 ? ['mask', 'close'] : _d, className = props.className, style = props.style, onClose = props.onClose, others = __rest(props, ["prefix", "closeIconType", "bgColor", "visible", "children", "closeMode", "className", "style", "onClose"]);
    var clsPrefix = "".concat(prefix, "dialog-popup");
    var containerStyle = style || {};
    if (bgColor) {
        containerStyle.backgroundColor = bgColor;
    }
    // 关闭事件
    var handleClose = function (reason) {
        if (isFunction(onClose) &&
            Array.isArray(closeMode) &&
            closeMode.indexOf(reason) > -1) {
            onClose(reason);
        }
    };
    return (React.createElement(CnModal, __assign({}, others, { visible: visible, containerClassName: clsPrefix, onClose: handleClose }),
        React.createElement("div", { className: classNames(className, "".concat(clsPrefix, "-container")), style: containerStyle }, children),
        (closeMode === null || closeMode === void 0 ? void 0 : closeMode.includes('close')) && (React.createElement(CnIcon, { className: "".concat(clsPrefix, "-popup-close"), type: closeIconType, size: "large", onClick: function () { return handleClose('close'); } }))));
};
CnPopup.displayName = 'CnPopup';
