import dayjs from 'dayjs';
import en from 'dayjs/locale/en';
import es from 'dayjs/locale/es';
import fr from 'dayjs/locale/fr';
import ja from 'dayjs/locale/ja';
import ko from 'dayjs/locale/ko';
import pl from 'dayjs/locale/pl';
import pt from 'dayjs/locale/pt';
import ptBR from 'dayjs/locale/pt-br';
import ru from 'dayjs/locale/ru';
import tr from 'dayjs/locale/tr';
import zhCN from 'dayjs/locale/zh-cn';
import zhHK from 'dayjs/locale/zh-hk';
import zhTW from 'dayjs/locale/zh-tw';
// 扩展语种中dayjs部分的支持
if (window._PANDA_EXUI) {
    Object.keys(window._PANDA_EXUI).forEach(function (lang) {
        var extraUiDayjs = window._PANDA_EXUI[lang].dayjs;
        if (extraUiDayjs) {
            dayjs.locale(typeof extraUiDayjs === 'function' ? extraUiDayjs() : extraUiDayjs, null, true);
        }
    });
}
export default [en, es, fr, ja, ko, pl, pt, ru, tr, zhCN, zhHK, zhTW, ptBR];
