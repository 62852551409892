import * as React from 'react';
import dayjs from 'dayjs';
import { FormatUser } from './user';
import { FormatCurrencyWithUnit, getNumFromStr, } from './currency-with-unit';
import { FormatProgress } from './progress';
import { FormatTag } from './tag';
import { FormatText } from './text';
import { FormatMultipleText } from './multiple-text';
import { FormatImageText } from './image-text';
import { FormatFile } from './file';
import { DefaultFormat } from './default-format';
import { FormatEnumeration } from './enumeration';
import { getValueByDataIndex } from './util';
export var ItemFormat = function (props) {
    var mode = props.mode, rowIndex = props.rowIndex, data = props.data, column = props.column;
    var dataIndex = column.dataIndex, format = column.format, cell = column.cell;
    var value = getValueByDataIndex(data, dataIndex);
    if (cell && typeof cell === 'function') {
        return (React.createElement(DefaultFormat, { mode: mode, rowIndex: rowIndex, data: data, column: column }));
    }
    if (format === 'text') {
        return (React.createElement(FormatText, { mode: mode, value: value, column: column, data: data, rowIndex: rowIndex }));
    }
    if (value === '- -') {
        return value;
    }
    if (format === 'user') {
        return React.createElement(FormatUser, { value: value });
    }
    if (format === 'currency-with-unit') {
        return React.createElement(FormatCurrencyWithUnit, { value: value });
    }
    if (format === 'progress') {
        return React.createElement(FormatProgress, { value: value });
    }
    if (format === 'tag') {
        return React.createElement(FormatTag, { mode: mode, value: value });
    }
    if (format === 'percent') {
        return "".concat(value, "%");
    }
    if (format === 'image-text') {
        return React.createElement(FormatImageText, { mode: mode, value: value });
    }
    if (format === 'multiple-text') {
        return (React.createElement(FormatMultipleText, { mode: mode, value: value }));
    }
    if (format === 'currency') {
        return React.createElement(React.Fragment, null, getNumFromStr(value));
    }
    if (format === 'number') {
        return React.createElement(React.Fragment, null, getNumFromStr(value));
    }
    if (format === 'bank') {
        return React.createElement(FormatText, { mode: mode, value: value });
    }
    if (format === 'date-time') {
        if (value) {
            return React.createElement(React.Fragment, null, dayjs(value).format('YYYY-MM-DD HH:mm:ss'));
        }
        else {
            return '- -';
        }
    }
    if (format === 'date') {
        if (value) {
            return React.createElement(React.Fragment, null, dayjs(value).format('YYYY-MM-DD'));
        }
        else {
            return '- -';
        }
    }
    if (format === 'file') {
        return React.createElement(FormatFile, { mode: mode, value: value });
    }
    if (format === 'enumeration') {
        return (React.createElement(FormatEnumeration, { value: value, column: column }));
    }
    return (React.createElement(DefaultFormat, { mode: mode, rowIndex: rowIndex, data: data, column: column }));
};
