/* eslint-disable react-hooks/rules-of-hooks */
import { useMediaQuery } from 'react-responsive';
export var responsiveBreakPoints = {
    xs: '320px',
    sm: '360px',
    md: '604px',
    lg: '848px',
    xl: '1200px',
};
export var judgeResponsiveSize = function () {
    var size = 'md';
    if (useMediaQuery({ maxWidth: responsiveBreakPoints.xs })) {
        size = 'xs';
    }
    else if (useMediaQuery({
        minWidth: responsiveBreakPoints.xs,
        maxWidth: responsiveBreakPoints.sm,
    })) {
        size = 'sm';
    }
    else if (useMediaQuery({
        minWidth: responsiveBreakPoints.sm,
        maxWidth: responsiveBreakPoints.md,
    })) {
        size = 'md';
    }
    else if (useMediaQuery({
        minWidth: responsiveBreakPoints.md,
        maxWidth: responsiveBreakPoints.lg,
    })) {
        size = 'lg';
    }
    else if (useMediaQuery({ minWidth: responsiveBreakPoints.lg })) {
        size = 'xl';
    }
    return size;
};
