// getLeft, getScrollLeft, getWidth
export function getLeft(id) {
    var ele = document.getElementById(id);
    if (ele) {
        return ele.getBoundingClientRect().left;
    }
    return 0;
}
export function getWidth(id) {
    var ele = document.getElementById(id);
    if (ele) {
        return ele.getBoundingClientRect().width;
    }
    return 0;
}
export function getScrollLeft(id) {
    var ele = document.getElementById(id);
    if (ele) {
        return ele.scrollLeft;
    }
    return 0;
}
