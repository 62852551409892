import { __extends } from "tslib";
import * as React from 'react';
import { findDOMNode } from 'react-dom';
var Wrapper = /** @class */ (function (_super) {
    __extends(Wrapper, _super);
    function Wrapper() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.element = null;
        return _this;
    }
    Wrapper.prototype.componentDidMount = function () {
        this.componentDidUpdate();
    };
    Wrapper.prototype.componentDidUpdate = function () {
        // eslint-disable-next-line
        var node = findDOMNode(this);
        if (node instanceof Element) {
            this.element = node;
        }
        else {
            this.element = null;
        }
    };
    Wrapper.prototype.render = function () {
        return React.Children.only(this.props.children);
    };
    return Wrapper;
}(React.Component));
export { Wrapper };
