import React from 'react';
import $i18n from 'panda-i18n';
import { formilyReact } from '@/form/formily';
import { isAdditionComponent } from '../utils';
import { CnFormArrayBase } from '@/form/cn-form-array-base';
export var Addition = function (props) {
    var additionText = props.additionText, defaultValue = props.defaultValue, schema = props.schema;
    var hasAddition = schema
        .mapProperties(function (_) {
        return isAdditionComponent(_);
    })
        .filter(Boolean).length > 0;
    if (hasAddition) {
        return (React.createElement("div", null, schema.reduceProperties(function (addition, additionSchema, key) {
            if (isAdditionComponent(additionSchema)) {
                return (React.createElement(formilyReact.RecursionField, { schema: additionSchema, name: key }));
            }
            return addition;
        }, null)));
    }
    /** 此处逻辑跟 PC 不一致，H5 独有 */
    return (React.createElement(CnFormArrayBase.Addition, { title: additionText ||
            $i18n.get({ id: 'New', dm: '新增', ns: 'CnFormArrayCard' }), defaultValue: defaultValue }));
};
