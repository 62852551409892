import { CnList as UICnList } from './list';
import { CnListItem } from './item/index';
import CnListItemCaption from './item/caption';
import CnListItemExtra from './item/extra';
import CnListItemContent from './item/content';
import { CnListItemAction, CnListItemMedia } from './item/media';
import CnListItemTitle from './item/title';
import './styles/index.scss';
// @ts-ignore
var CnList = UICnList;
CnList.Item = CnListItem;
CnList.ItemAction = CnListItemAction;
CnList.ItemMedia = CnListItemMedia;
CnList.ItemTitle = CnListItemTitle;
CnList.ItemCaption = CnListItemCaption;
CnList.ItemExtra = CnListItemExtra;
CnList.ItemContent = CnListItemContent;
export { CnList, CnListItem };
