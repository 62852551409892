import { __awaiter, __generator } from "tslib";
import LRU from 'lru-cache';
import { getChildren, getDept } from './service';
var lruOptions = {
    max: 10,
};
var lru = new LRU(lruOptions);
export var getSubDeptCacheByKey = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var cacheResult, expandResult;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                cacheResult = lru.get("expand-".concat(params.value));
                if (cacheResult) {
                    return [2 /*return*/, cacheResult];
                }
                return [4 /*yield*/, getChildren(params)];
            case 1:
                expandResult = _a.sent();
                lru.set("expand-".concat(params.value), expandResult);
                return [2 /*return*/, expandResult];
        }
    });
}); };
export var getDeptCacheByKey = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var cacheResult, expandResult;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                cacheResult = lru.get("info-".concat(params.value));
                if (cacheResult) {
                    return [2 /*return*/, cacheResult];
                }
                return [4 /*yield*/, getDept(params)];
            case 1:
                expandResult = _a.sent();
                lru.set("info-".concat(params.value), expandResult[0]);
                return [2 /*return*/, expandResult[0]];
        }
    });
}); };
