import { __assign, __rest } from "tslib";
import classNames from 'classnames';
import React, { forwardRef, } from 'react';
import { isNumber } from '@/utils/func';
import { defaultTextRender, progressBackgroundColor, progressiveColor, progressSize, statusColor, } from './utils';
var getPercent = function (percent) {
    if (percent < 0) {
        return 0;
    }
    else if (percent > 100) {
        return 100;
    }
    return percent;
};
var LinearProgress = function (props, ref) {
    var _a;
    var _b = props.prefix, prefix = _b === void 0 ? 'cn-ui-m-' : _b, _c = props.percent, percent = _c === void 0 ? 0 : _c, color = props.color, _d = props.size, size = _d === void 0 ? 'medium' : _d, backgroundColor = props.backgroundColor, _e = props.progressive, progressive = _e === void 0 ? false : _e, _f = props.state, state = _f === void 0 ? 'normal' : _f, _g = props.textRender, textRender = _g === void 0 ? defaultTextRender : _g, hasBorder = props.hasBorder, className = props.className, rtl = props.rtl, others = __rest(props, ["prefix", "percent", "color", "size", "backgroundColor", "progressive", "state", "textRender", "hasBorder", "className", "rtl"]);
    var progressiveColorEnabled = function () {
        if (progressive) {
            return progressiveColor(percent);
        }
        else {
            return color || statusColor(state);
        }
    };
    var trackStyle = {
        height: progressSize(size),
        backgroundColor: progressBackgroundColor(backgroundColor),
    };
    var barStyle = {
        height: progressSize(size),
        backgroundColor: progressiveColorEnabled(),
        width: "".concat(getPercent(percent), "%"),
    };
    if (isNumber(size) && size > 0) {
        var borderRadiusVal = "".concat(size / 2, "rpx");
        trackStyle.borderRadius = borderRadiusVal;
        barStyle.borderRadius = borderRadiusVal;
    }
    var clsPrefix = "".concat(prefix, "progress");
    var text = textRender(percent, { rtl: rtl });
    return (React.createElement("div", __assign({ ref: ref }, others, { className: classNames(CN_UI_HASH_CLASS_NAME, clsPrefix, "".concat(clsPrefix, "-line--").concat(size), "".concat(clsPrefix, "-line-").concat(size, "-corner"), className) }),
        React.createElement("div", { className: classNames("".concat(clsPrefix, "-track"), "".concat(clsPrefix, "-line-").concat(size, "-corner"), (_a = {},
                _a["".concat(clsPrefix, "-line-show-border")] = hasBorder,
                _a)), style: trackStyle },
            React.createElement("div", { className: classNames("".concat(clsPrefix, "-line-bar"), "".concat(clsPrefix, "-line-").concat(size, "-corner")), style: barStyle })),
        text !== '' && text !== undefined && text !== null ? (React.createElement("div", { className: "".concat(clsPrefix, "-line-text") }, text)) : null));
};
export default forwardRef(LinearProgress);
