import cloneDeep from 'lodash/cloneDeep';
import { addPosToDataSource } from './add-pos';
import { initializeData } from './initialize-data';
/**
 * 数据源处理
 * @param dataSource 数据源
 * @param value 值
 * @returns 新的数据源
 */
export var getNewDataSource = function (dataSource, value) {
    if (Array.isArray(dataSource)) {
        var newDataSource = cloneDeep(dataSource);
        // 给dataSource增加pos属性，标识层级，与PC侧一致
        addPosToDataSource(newDataSource, '');
        // 初始化，更新数据源的checked和indeterminate状态
        initializeData(value, newDataSource);
        return newDataSource;
    }
    return [];
};
