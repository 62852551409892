import isString from 'lodash/isString';
/**
 * 获取滚动容器
 * 因为 document 不存在 scroll 等属性, 因此排除 document
 * window | HTMLElement
 * @param {ScrollContainerElement} [container='body']
 * @returns {ScrollContainer}
 */
export var getScrollContainer = function (container) {
    if (container === void 0) { container = 'body'; }
    if (isString(container)) {
        return container
            ? document.querySelector(container)
            : window;
    }
    if (typeof container === 'function') {
        return container();
    }
    return container;
};
