import './styles/index.scss';
import { withI18n } from 'panda-i18n';
import locale from '@/locales';
import { attachPropertiesToComponent } from '@/utils/attach-properties-to-component';
import { CnDrawer as UICnDrawer } from './drawer';
import { CnDrawerFooter } from './footer';
import { CnDrawerHeader } from './header';
var Drawer = withI18n(UICnDrawer, { locale: locale });
export var CnDrawer = attachPropertiesToComponent(Drawer, {
    Header: CnDrawerHeader,
    Footer: CnDrawerFooter,
});
CnDrawer.displayName = 'CnDrawer';
