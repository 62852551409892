/**
 * 获取节点及其父节点列表
 * @param dataSource 数据源
 * @param pos 节点位置
 * @returns 节点及其父节点列表
 */
export var getNodeAndParentsByPos = function (dataSource, pos) {
    for (var _i = 0, dataSource_1 = dataSource; _i < dataSource_1.length; _i++) {
        var node = dataSource_1[_i];
        if (node.pos === pos) {
            return { node: node, parents: [] };
        }
        if (node.children) {
            var foundNode = getNodeAndParentsByPos(node.children, pos);
            if (foundNode) {
                foundNode.parents.unshift(node);
                return foundNode;
            }
        }
    }
    return undefined;
};
