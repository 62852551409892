import { __assign, __rest } from "tslib";
import React, { forwardRef } from 'react';
import CircleProgress from './circle';
import LinearProgress from './linear';
var CnProgress = forwardRef(function (_a, ref) {
    var _b = _a.shape, shape = _b === void 0 ? 'line' : _b, others = __rest(_a, ["shape"]);
    return shape === 'line' ? (React.createElement(LinearProgress, __assign({}, others, { ref: ref }))) : (React.createElement(CircleProgress, __assign({}, others, { ref: ref })));
});
CnProgress.displayName = 'CnProgress';
CnProgress.defaultProps = {
    size: 'medium',
    shape: 'line',
    percent: 0,
    state: 'normal',
    progressive: false,
    hasBorder: false,
    textRender: function (percent) { return "".concat(Math.trunc(percent * 100) / 100, "%"); },
};
export default CnProgress;
