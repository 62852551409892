/**
 * 给数据源添加pos, example: { pos:"0-0-0-1" }
 * @param dataSource 数据源
 * @param pos 层级
 */
export function addPosToDataSource(dataSource, pos) {
    dataSource.forEach(function (node, index) {
        var newPos = !pos ? "".concat(index) : "".concat(pos, "-").concat(index);
        node.pos = newPos;
        if (node.children) {
            addPosToDataSource(node.children, newPos);
        }
    });
}
