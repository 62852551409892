import { __assign, __rest } from "tslib";
var attachParentKey = function (item, parentKey) {
    var _a;
    if (!((_a = item.children) === null || _a === void 0 ? void 0 : _a.length))
        return __assign(__assign({}, item), { parentKey: parentKey });
    return __assign(__assign({}, item), { parentKey: parentKey, newChildren: item.children.map(function (subItem) {
            return attachParentKey(subItem, item.value);
        }) });
};
export var calcSelectedList = function (dataSource, value) {
    if (!value)
        return [];
    if (!(dataSource === null || dataSource === void 0 ? void 0 : dataSource.length))
        return [];
    var tempDataSource = dataSource.map(function (item) { return attachParentKey(item, '0'); });
    var dataMap = {};
    var mapDataSource = function (list) {
        if (!(list === null || list === void 0 ? void 0 : list.length))
            return;
        for (var _i = 0, list_1 = list; _i < list_1.length; _i++) {
            var item = list_1[_i];
            dataMap[item.value] = item;
            mapDataSource(item.newChildren);
        }
    };
    mapDataSource(tempDataSource);
    var selectedList = [];
    var getSelected = function (tempValue) {
        if (!tempValue)
            return;
        if (!dataMap[tempValue])
            return;
        var _a = dataMap[tempValue], parentKey = _a.parentKey, newChildren = _a.newChildren, restItem = __rest(_a, ["parentKey", "newChildren"]);
        selectedList.push(restItem);
        getSelected(parentKey);
    };
    getSelected(value);
    return selectedList.reverse();
};
