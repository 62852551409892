import { __assign } from "tslib";
import * as React from 'react';
import cx from 'classnames';
import { withNativeProps } from '@/utils/with-native-props';
import omit from 'lodash/omit';
import './cn-tag.scss';
var PRESET_COLOR_REG = /blue|green|orange|red|turquoise|yellow/;
/**
 * @category 组件
 * @description 标签
 */
export var CnTag = function (props) {
    var _a;
    var children = props.children, type = props.type, disabled = props.disabled;
    var classes = cx(CN_UI_HASH_CLASS_NAME, (_a = {
            'cn-ui-m-tag': true
        },
        _a["cn-ui-m-tag-".concat(type)] = !!type,
        _a['cn-ui-m-tag-disabled'] = !!disabled,
        _a));
    var isPresetColor = function () {
        var color = props.color;
        if (!color) {
            return false;
        }
        return PRESET_COLOR_REG.test(color);
    };
    var getTagStyle = function () {
        var _a = props.color, color = _a === void 0 ? '' : _a, style = props.style;
        var _isPresetColor = isPresetColor();
        var customColorStyle = {
            backgroundColor: color,
            borderColor: color,
            color: '#fff',
        };
        return __assign(__assign({}, (color && !_isPresetColor ? customColorStyle : null)), style);
    };
    return withNativeProps(props, React.createElement("div", __assign({ className: classes }, omit(props, 'className'), { style: getTagStyle() }), children));
};
CnTag.displayName = 'CnTag';
CnTag.defaultProps = {
    type: 'normal',
};
