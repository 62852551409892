import { __assign } from "tslib";
import * as React from 'react';
import { CnStatusTag } from '@/components/cn-tag';
import { withNativeProps } from '@/utils/with-native-props';
import isPlainObject from 'lodash/isPlainObject';
import './index.scss';
import classNames from 'classnames';
var renderDescription = function (description) {
    var result = [];
    if (Array.isArray(description)) {
        description.forEach(function (descriptionItem) {
            result.push(React.createElement("div", { className: "cn-ui-m-detail-info-description-item" }, descriptionItem));
        });
    }
    else {
        result.push(React.createElement("div", { className: "cn-ui-m-detail-info-description-item" }, description));
    }
    return result;
};
/**
 * @category 组件
 * @description 详情摘要信息展示卡片
 */
export var CnDetailInfo = function (props) {
    var title = props.title, description = props.description, status = props.status, tagText = props.tagText, cnStatusTagProps = props.cnStatusTagProps, propsAvatar = props.avatar, rightSlot = props.rightSlot;
    var avatar = isPlainObject(propsAvatar) ? propsAvatar === null || propsAvatar === void 0 ? void 0 : propsAvatar.src : propsAvatar;
    return withNativeProps(props, React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-m-detail-info') },
        avatar && React.createElement("img", { className: "cn-ui-m-detail-info-avatar", src: avatar }),
        React.createElement("div", { className: "cn-ui-m-detail-info-description" },
            React.createElement("div", { className: "cn-ui-m-detail-info-description-hd" },
                React.createElement("div", { className: "cn-ui-m-detail-info-description-title" }, title),
                status && (React.createElement("div", { className: "cn-ui-m-detail-info-description-tag" },
                    React.createElement(CnStatusTag, __assign({ status: status }, cnStatusTagProps), tagText)))),
            React.createElement("div", { className: "cn-ui-m-detail-info-description-ft" }, renderDescription(description))),
        React.createElement("div", { className: "cn-ui-m-detail-info-right-slot" }, rightSlot)));
};
CnDetailInfo.displayName = 'CnDetailInfo';
CnDetailInfo.defaultProps = {};
