import { useEffect, useRef } from 'react';
/**
 * 渲染完成时，显示某个属性不再支持
 * @param comp 组件名称
 * @param props props
 * @param oldName 旧属性
 * @param newName 新属性
 */
export function useDeprecated(comp, props, oldName, newName, defaultValue) {
    useEffect(function () {
        if (oldName in props && console && console.warn) {
            console.warn("Warning: ".concat(comp, ".").concat(oldName, " is deprecated and will be removed in a future version, please use ").concat(newName, " instead"));
        }
    }, []);
    // 旧属性兼容
    if (newName in props) {
        return props[newName];
    }
    if (oldName in props) {
        return props[oldName];
    }
    return defaultValue;
}
var counter = 0;
export function guid(prefix) {
    if (prefix === void 0) { prefix = ''; }
    counter += 1;
    return "".concat(prefix).concat(counter);
}
export function useGuid(prefix) {
    if (prefix === void 0) { prefix = ''; }
    var id = useRef(guid(prefix));
    return id.current;
}
