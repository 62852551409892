import ReactDOM from 'react-dom';
function renderWithDriver(element, onHide) {
    var root = document.createElement('div');
    var hid = false;
    var hide = function () {
        if (hid) {
            return;
        }
        try {
            hid = true;
            // eslint-disable-next-line react/no-deprecated
            ReactDOM.unmountComponentAtNode(root);
            document.body.removeChild(root);
            if (onHide) {
                onHide();
            }
        }
        catch (e) {
            throw new Error('remove element error');
        }
    };
    document.body.appendChild(root);
    // eslint-disable-next-line react/no-deprecated
    ReactDOM.render(element, root);
    return hide;
}
/**
 * 将元素快捷渲染到Root中
 * @param element
 * @param onHide
 * @return () => void
 */
export function renderToRoot(element, onHide) {
    return renderWithDriver(element, onHide);
}
