import * as React from 'react';
import { clsPrefix } from '../../constant';
export default function LengthTip(props) {
    var value = props.value, maxLength = props.maxLength, _a = props.showLimitHint, showLimitHint = _a === void 0 ? false : _a;
    /** 有限制输入长度时，右侧显示输入字数 */
    if (!maxLength || !showLimitHint)
        return null;
    return (React.createElement("div", { className: "".concat(clsPrefix, "-length-tips") },
        React.createElement("span", { className: "".concat(clsPrefix, "-length-tips-value") }, typeof value === 'number' || typeof value === 'string'
            ? String(value).length
            : 0),
        maxLength ? React.createElement("span", null,
            "/",
            maxLength) : null));
}
