import { __spreadArray } from "tslib";
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear';
import isLeapYear from 'dayjs/plugin/isLeapYear';
dayjs.extend(isoWeek);
dayjs.extend(isoWeeksInYear);
dayjs.extend(isLeapYear);
var precisionRankRecord = {
    year: 0,
    month: 1,
    day: 2,
    hour: 3,
    minute: 4,
    second: 5,
};
export function defaultRenderLabel(type, data) {
    switch (type) {
        case 'minute':
        case 'second':
        case 'hour':
            return "0".concat(data.toString()).slice(-2);
        default:
            return data.toString();
    }
}
export function generateDatePickerColumns(selected, min, max, precision, renderLabel, filter) {
    var ret = [];
    var minYear = min.getFullYear();
    var minMonth = min.getMonth() + 1;
    var minDay = min.getDate();
    var minHour = min.getHours();
    var minMinute = min.getMinutes();
    var minSecond = min.getSeconds();
    var maxYear = max.getFullYear();
    var maxMonth = max.getMonth() + 1;
    var maxDay = max.getDate();
    var maxHour = max.getHours();
    var maxMinute = max.getMinutes();
    var maxSecond = max.getSeconds();
    var rank = precisionRankRecord[precision];
    var selectedYear = parseInt(selected[0], 10);
    var firstDayInSelectedMonth = dayjs(convertStringArrayToDate([selected[0], selected[1], '1']));
    var selectedMonth = parseInt(selected[1], 10);
    var selectedDay = parseInt(selected[2], 10);
    var selectedHour = parseInt(selected[3], 10);
    var selectedMinute = parseInt(selected[4], 10);
    var isInMinYear = selectedYear === minYear;
    var isInMaxYear = selectedYear === maxYear;
    var isInMinMonth = isInMinYear && selectedMonth === minMonth;
    var isInMaxMonth = isInMaxYear && selectedMonth === maxMonth;
    var isInMinDay = isInMinMonth && selectedDay === minDay;
    var isInMaxDay = isInMaxMonth && selectedDay === maxDay;
    var isInMinHour = isInMinDay && selectedHour === minHour;
    var isInMaxHour = isInMaxDay && selectedHour === maxHour;
    var isInMinMinute = isInMinHour && selectedMinute === minMinute;
    var isInMaxMinute = isInMaxHour && selectedMinute === maxMinute;
    var generateColumn = function (from, to, precision2) {
        var column = [];
        for (var i = from; i <= to; i++) {
            column.push(i);
        }
        var prefix = selected.slice(0, precisionRankRecord[precision2]);
        var currentFilter = filter === null || filter === void 0 ? void 0 : filter[precision2];
        if (currentFilter && typeof currentFilter === 'function') {
            column = column.filter(function (i) {
                return currentFilter(i, {
                    get date() {
                        var stringArray = __spreadArray(__spreadArray([], prefix, true), [i.toString()], false);
                        return convertStringArrayToDate(stringArray);
                    },
                });
            });
        }
        return column;
    };
    if (rank >= precisionRankRecord.year || precision === 'quarter') {
        var lower = minYear;
        var upper = maxYear;
        var years = generateColumn(lower, upper, 'year');
        ret.push(years.map(function (v) {
            return {
                label: renderLabel('year', v),
                value: v.toString(),
            };
        }));
    }
    // 季度需要单独处理
    if (precision === 'quarter') {
        ret.push([1, 2, 3, 4].map(function (v) {
            return {
                label: renderLabel('quarter', "Q".concat(v)),
                value: v.toString(),
            };
        }));
        return ret;
    }
    if (rank >= precisionRankRecord.month) {
        var lower = isInMinYear ? minMonth : 1;
        var upper = isInMaxYear ? maxMonth : 12;
        var months = generateColumn(lower, upper, 'month');
        ret.push(months.map(function (v) {
            return {
                label: renderLabel('month', v),
                value: v.toString(),
            };
        }));
    }
    if (rank >= precisionRankRecord.day) {
        var lower = isInMinMonth ? minDay : 1;
        var upper = isInMaxMonth ? maxDay : firstDayInSelectedMonth.daysInMonth();
        var days = generateColumn(lower, upper, 'day');
        ret.push(days.map(function (v) {
            return {
                label: renderLabel('day', v),
                value: v.toString(),
            };
        }));
    }
    if (rank >= precisionRankRecord.hour) {
        var lower = isInMinDay ? minHour : 0;
        var upper = isInMaxDay ? maxHour : 23;
        var hours = generateColumn(lower, upper, 'hour');
        ret.push(hours.map(function (v) {
            return {
                label: renderLabel('hour', v),
                value: v.toString(),
            };
        }));
    }
    if (rank >= precisionRankRecord.minute) {
        var lower = isInMinHour ? minMinute : 0;
        var upper = isInMaxHour ? maxMinute : 59;
        var minutes = generateColumn(lower, upper, 'minute');
        ret.push(minutes.map(function (v) {
            return {
                label: renderLabel('minute', v),
                value: v.toString(),
            };
        }));
    }
    if (rank >= precisionRankRecord.second) {
        var lower = isInMinMinute ? minSecond : 0;
        var upper = isInMaxMinute ? maxSecond : 59;
        var seconds = generateColumn(lower, upper, 'second');
        ret.push(seconds.map(function (v) {
            return {
                label: renderLabel('second', v),
                value: v.toString(),
            };
        }));
    }
    return ret;
}
export function convertDateToStringArray(date) {
    if (!date)
        return [];
    return [
        date.getFullYear().toString(),
        (date.getMonth() + 1).toString(),
        date.getDate().toString(),
        date.getHours().toString(),
        date.getMinutes().toString(),
        date.getSeconds().toString(),
    ];
}
export function convertQuarterDateToStringArray(date) {
    if (!date)
        return [];
    var quarter = Math.floor(date.getMonth() / 3) + 1;
    return [date.getFullYear().toString(), quarter.toString()];
}
export function convertStringArrayToDate(value) {
    var _a, _b, _c, _d, _e, _f;
    var yearString = (_a = value[0]) !== null && _a !== void 0 ? _a : '1900';
    var monthString = (_b = value[1]) !== null && _b !== void 0 ? _b : '1';
    var dateString = (_c = value[2]) !== null && _c !== void 0 ? _c : '1';
    var hourString = (_d = value[3]) !== null && _d !== void 0 ? _d : '0';
    var minuteString = (_e = value[4]) !== null && _e !== void 0 ? _e : '0';
    var secondString = (_f = value[5]) !== null && _f !== void 0 ? _f : '0';
    return new Date(parseInt(yearString, 10), parseInt(monthString, 10) - 1, parseInt(dateString, 10), parseInt(hourString, 10), parseInt(minuteString, 10), parseInt(secondString, 10));
}
export function convertQuarterArrayToDate(value) {
    var _a, _b;
    var yearString = (_a = value[0]) !== null && _a !== void 0 ? _a : '1900';
    var quarterString = (_b = value[1]) !== null && _b !== void 0 ? _b : '1';
    var month = (parseInt(quarterString, 10) - 1) * 3; // 计算季度对应的月份
    var date = new Date(parseInt(yearString, 10), month, 1); // 使用年份和月份创建 Date 对象
    return date;
}
