import * as React from 'react';
import { CnProgress } from '@/components/cn-progress';
export var FormatProgress = function (props) {
    var value = props.value;
    if (value && typeof value === 'object') {
        return (React.createElement(CnProgress, { size: value.size || 'large', state: value.state, percent: value.value }));
    }
    if (!Number.isNaN(Number(value))) {
        return React.createElement(CnProgress, { size: "large", percent: Number(value) });
    }
    return React.createElement(React.Fragment, null, value || '- -');
};
