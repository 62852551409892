import { __assign, __rest } from "tslib";
import * as React from 'react';
import { forwardRef, Fragment } from 'react';
import classNames from 'classnames';
import { CnIcon } from '@/components/cn-icon';
import { CnBadge } from '@/components/cn-badge';
import { CnStatusTag } from '@/components/cn-tag';
var Media = function (props, ref) {
    var action = props.action, _a = props.prefix, prefix = _a === void 0 ? 'cn-ui-m-' : _a, icon = props.icon, children = props.children, className = props.className, mediaImgUrl = props.mediaImgUrl, extraTags = props.extraTags, extraBadge = props.extraBadge, others = __rest(props, ["action", "prefix", "icon", "children", "className", "mediaImgUrl", "extraTags", "extraBadge"]);
    var avatar = (React.createElement("img", { src: mediaImgUrl, style: { width: 40, height: 40, borderRadius: '50%' } }));
    var tags = extraTags === null || extraTags === void 0 ? void 0 : extraTags.map(function (item, index) {
        return (React.createElement(CnStatusTag, { key: index, status: item.status, type: item.type }, item.text));
    });
    return (React.createElement("div", __assign({ ref: ref }, others, { className: classNames("".concat(prefix, "list-item-media"), action ? "".concat(prefix, "list-item-media--last") : null, className) }),
        extraTags ? tags : null,
        extraBadge ? React.createElement(CnBadge, { shape: "dot" }) : null,
        mediaImgUrl ? avatar : null,
        icon ? (React.createElement(CnIcon, { className: "".concat(prefix, "list-item-icon"), type: icon })) : null,
        React.createElement(Fragment, null, children)));
};
export var CnListItemMedia = forwardRef(Media);
var Action = function (props, ref) {
    return React.createElement(CnListItemMedia, __assign({}, props, { action: true, ref: ref }));
};
export var CnListItemAction = forwardRef(Action);
export default CnListItemMedia;
