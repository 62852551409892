import { canUseDom } from './can-use-dom';
var defaultRoot = canUseDom ? window : undefined;
var overflowStylePatterns = ['scroll', 'auto', 'overlay'];
function isElement(node) {
    var ELEMENT_NODE_TYPE = 1;
    return node.nodeType === ELEMENT_NODE_TYPE;
}
export function getScrollParent(el, root) {
    if (root === void 0) { root = defaultRoot; }
    var node = el;
    while (node && node !== root && isElement(node)) {
        if (node === document.body) {
            return root;
        }
        var overflowY = window.getComputedStyle(node).overflowY;
        if (overflowStylePatterns.includes(overflowY) &&
            node.scrollHeight > node.clientHeight) {
            return node;
        }
        node = node.parentNode;
    }
    return root;
}
