/**
 * 获取pos下的所有子节点
 * 用途：用于顶部导航，以及点击一个节点的定位，从而更新navList
 * @param dataSource 数据源
 * @param pos 节点位置
 * @returns 子节点
 */
export function getNodeByPos(dataSource, pos) {
    var targetNode = null;
    function traverse(nodes) {
        for (var _i = 0, nodes_1 = nodes; _i < nodes_1.length; _i++) {
            var node = nodes_1[_i];
            if (node.pos === pos) {
                targetNode = node;
                return;
            }
            if (node.children) {
                traverse(node.children);
            }
        }
    }
    traverse(dataSource);
    return targetNode;
}
