import React from 'react';
function RegionFlag(props) {
    var value = props.value;
    var clsPrefix = 'cn-ui-m-currency-select';
    if (!value) {
        return React.createElement("div", { className: "".concat(clsPrefix, "-region-flag-placeholder") });
    }
    return (React.createElement("img", { className: "".concat(clsPrefix, "-region-flag"), src: "//caijing-resource.oss-accelerate.aliyuncs.com/country/".concat(value, ".svg") }));
}
export default RegionFlag;
