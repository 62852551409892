// 图片上传、附件上传、长文本、图片查看组件 在表单中必须以上下结构展示
export var topLayoutList = [
    'CnDdRichEditor',
    'CnRating',
    'CnInputTextArea',
    'CnOSSImageUpload',
    'CnOSSUpload',
    'CnImageViewer',
    // start 兼容逻辑，待低码非CN命名组件治理完成后，可删除
    'TextArea',
    'OSSImageUpload',
    'Upload',
    'ImageViewer',
    // end
];
