import some from 'lodash/some';
import pickBy from 'lodash/pickBy';
import compact from 'lodash/compact';
import values from 'lodash/values';
/**
 * 判断是否有效查询值
 * @param query
 */
export function hasTruthyValue(query) {
    return some(values(pickBy(query, function (value) { return ![undefined, null].includes(value); })), function (value) {
        if (Array.isArray(value)) {
            return some(compact(value));
        }
        return value !== undefined && value !== null;
    });
}
