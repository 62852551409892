import { __assign } from "tslib";
function isValidArray(obj) {
    return Array.isArray(obj) && obj.length > 0;
}
/**
 * 深度优先遍历
 * @param node
 * @param fn
 */
export function dfs(node, callback) {
    var cur;
    var par;
    var children;
    var ctrl;
    var i;
    var nodes = Array.isArray(node) ? node.slice(0).reverse() : [node];
    var parents = [];
    var childrenName = 'children';
    if (typeof nodes[0] === 'undefined' && nodes.length === 1) {
        return;
    }
    for (i = nodes.length - 1; i >= 0; i--) {
        parents.push(undefined);
    }
    while (nodes.length > 0) {
        cur = nodes.pop();
        par = parents.pop();
        ctrl = {};
        callback.call(cur, cur, par, ctrl);
        if (ctrl.stop) {
            break;
        }
        children = cur && cur[childrenName] ? cur[childrenName] : [];
        for (i = ctrl.cutoff ? -1 : children.length - 1; i >= 0; i--) {
            nodes.push(children[i]);
            parents.push(cur);
        }
    }
    return node;
}
/**
 * 查找
 * @param node
 * @param fn
 */
export function find(tree, callback) {
    var found;
    dfs(tree, function (node, par, ctrl) {
        if (callback.call(node, node, par)) {
            ctrl.stop = true;
            found = this;
        }
    });
    return found;
}
/**
 * 根据执行条件过滤
 * @param tree
 * @param callback
 * @returns {[]}
 */
export function filter(tree, callback) {
    var rs = [];
    dfs(tree, function (node, par) {
        if (callback(node, par)) {
            rs.push(node);
        }
    });
    return rs;
}
export function getSiblings(tree, val) {
    var p;
    dfs(tree, function (node, par) {
        if (node.value === val) {
            p = par;
        }
    });
    if (!p) {
        return tree;
    }
    else {
        return filter(tree, function (node, par) {
            return par && par.value === p.value;
        });
    }
}
/**
 * 根据 value 数组，获取
 * @param tree
 * @param values
 */
export function getItemsFromDataSource(tree, values) {
    if (tree === void 0) { tree = []; }
    if (values === void 0) { values = []; }
    if (!(typeof values === 'undefined') && !Array.isArray(values)) {
        values = [values];
    }
    if (!isValidArray(tree) || !isValidArray(values)) {
        return [];
    }
    return values.map(function (val) {
        return (find(tree, function (it) { return it.value === val; }) || {
            lavel: val,
            value: val,
        });
    });
}
/**
 * 获取第一个叶子节点
 * @param tree
 * @returns {[]|*[]}
 */
export function getFirstValue(tree) {
    if (tree === void 0) { tree = []; }
    var ret = [];
    if (!Array.isArray(tree) || tree.length === 0) {
        return ret;
    }
    var node = tree[0];
    do {
        if (node) {
            ret.push(node);
        }
        node = Array.isArray(node.children) && node.children.length > 0 ? node.children[0] : null;
    } while (node);
    return ret.map(function (item) { return item.value; });
}
/**
 * 根据 传入的 tree ， 获取选中值列表的对应数据
 * @param data
 * @param value
 */
export function getDataByValues(tree, values) {
    if (tree === void 0) { tree = []; }
    if (values === void 0) { values = []; }
    var ret = [];
    var len = values.length;
    var child = tree;
    for (var i = 0; i < len; i++) {
        var val = values[i];
        var l = child.length;
        var flag = false;
        for (var j = 0; j < l; j++) {
            var item = child[j];
            if (item.value === val) {
                flag = true;
                if (Array.isArray(item.children)) {
                    child = item.children;
                }
                ret.push(item);
                break;
            }
        }
        if (!flag) {
            ret.push({
                label: val,
                value: val,
            });
        }
    }
    return ret;
}
/**
 * 根据节点，返回 path
 * @param tree
 * @param value
 */
export function getFullPathByValue(tree, value, key) {
    if (key === void 0) { key = 'value'; }
    var path = [];
    var nodes = Array.isArray(tree) ? tree : [tree];
    function helper(node) {
        for (var i = 0; i < node.length; i++) {
            var item = node[i];
            if (item[key] === value) {
                path.push(value);
                return true;
            }
            if (item.children && helper(item.children)) {
                path.push(item[key]);
                return true;
            }
        }
        return false;
    }
    // 根节点的子元素
    helper(nodes);
    if (path.length > 0) {
        return path.reverse();
    }
    // 当没有匹配的path时，直接返回一个包含value的list
    return [value];
}
/**
 * 获取树的深度
 * @param treeData 数据源
 * @param childrenName 子数据的名称
 * @returns 树的深度
 */
export function getTreeDepth(treeData, childrenName) {
    if (childrenName === void 0) { childrenName = 'children'; }
    var walker = function (tree) {
        var depth = 0;
        tree.forEach(function (item) {
            if (item[childrenName]) {
                depth = Math.max(depth, walker(item[childrenName]) + 1);
            }
            else {
                depth = Math.max(depth, 1);
            }
        });
        return depth;
    };
    return walker(treeData);
}
export function applyFieldNames(fieldNames) {
    var _a = fieldNames || {}, label = _a.label, value = _a.value, children = _a.children;
    return {
        label: label || 'label',
        value: value || 'value',
        children: children || 'children',
    };
}
export function loopDataSource(dataSource, fieldNames) {
    var _a = applyFieldNames(fieldNames), fieldLabel = _a.label, fieldValue = _a.value, fieldChildren = _a.children;
    var result = [];
    dataSource.forEach(function (item) {
        if (item[fieldChildren]) {
            var children = loopDataSource(item[fieldChildren]);
            result.push(__assign(__assign({}, item), { label: item[fieldLabel], value: item[fieldValue], children: children }));
        }
        else {
            result.push(__assign(__assign({}, item), { label: item[fieldLabel], value: item[fieldValue] }));
        }
    });
    return result;
}
