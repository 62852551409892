import { __assign } from "tslib";
import React, { useContext, useMemo } from 'react';
import { CardItemsStateCtx } from '../context/card-items';
import { CnButton } from '@/components/cn-button';
export function useItemButtons(props) {
    var data = props.data, rowIndex = props.rowIndex;
    var operateColumn = useContext(CardItemsStateCtx).operateColumn;
    return useMemo(function () {
        var buttons = (operateColumn === null || operateColumn === void 0 ? void 0 : operateColumn.buttons) || [];
        return buttons
            .map(function (item, index) {
            try {
                if (typeof item === 'function')
                    return item(data, index);
                return item;
            }
            catch (e) {
                console.error(e);
            }
            return null;
        })
            .filter(function (item, index) {
            try {
                if (!item)
                    return false;
                if (typeof item.visible === 'function') {
                    return item.visible(data, index);
                }
                if (typeof item.hidden === 'function') {
                    return !item.hidden(data, index);
                }
                return item.visible !== false;
            }
            catch (e) {
                console.error(e);
            }
            return false;
        })
            .map(function (item, index) {
            if (!item)
                return null;
            try {
                if (item.children) {
                    var disabled = false;
                    if (typeof item.disabled === 'function') {
                        disabled = item.disabled(data, index);
                    }
                    else {
                        disabled = item.disabled;
                    }
                    // 优先级 isDiabled > disabled
                    if (typeof item.isDisabled === 'function') {
                        disabled = item.isDisabled(data, index);
                    }
                    var button = {
                        disabled: disabled,
                        children: item.children,
                        onClick: function (e) {
                            var _a;
                            (_a = item.onClick) === null || _a === void 0 ? void 0 : _a.call(item, e, data, rowIndex);
                        },
                    };
                    return (React.createElement(CnButton, __assign({}, button, { key: "".concat(Date.now() + index) }), button.children));
                }
                return item;
            }
            catch (e) {
                console.error(e);
            }
            return null;
        })
            .filter(Boolean);
    }, [data, operateColumn, rowIndex]);
}
