import isString from 'lodash/isString';
import { isNumber } from '@/utils/func';
export var addRpx = function (value) {
    if (!value) {
        return value;
    }
    return isString(value) ? value : "".concat(value, "rpx");
};
export var getValue = function (cssProp) {
    if (!cssProp) {
        return 0;
    }
    if (isNumber(cssProp)) {
        return cssProp;
    }
    var index = cssProp.indexOf('rpx');
    if (index === -1) {
        return Number(cssProp);
    }
    return Number(cssProp.substring(0, index));
};
export var statusColor = function (status) {
    var colorMap = {
        normal: '#005BE5',
        error: '#EC3E3E',
        success: '#11D3B2',
    };
    return colorMap[status];
};
export var progressBackgroundColor = function (color) { return color || '#f2f4f8'; };
export var progressSize = function (size) {
    var sizeMap = {
        small: 2,
        medium: 4,
        large: 8,
    };
    return sizeMap[size] || size;
};
export var progressiveColor = function (percent) {
    if (percent < 33) {
        return '#EB2F2F';
    }
    if (percent > 66) {
        return '#05D161';
    }
    return '#D97B00';
};
export var progressCircleWidthSize = function (size) {
    var sizeMap = {
        small: 18,
        medium: 28,
        large: 43,
    };
    return sizeMap[size] || size;
};
export var progressCircleFontSize = function (size) {
    var sizeMap = {
        small: 12,
        medium: 14,
        large: 20,
    };
    return sizeMap[size] || size;
};
export var defaultTextRender = function (percent) {
    return "".concat(Number(percent.toFixed(2)), "%");
};
