/**
 * 改变父节点状态，主要是全选、半选、未选中
 * @param node 节点
 */
export function updateParentNodeState(node) {
    if (!node.children || node.children.length === 0) {
        return;
    }
    var allChildrenChecked = true;
    var someChildrenChecked = false;
    for (var _i = 0, _a = node.children; _i < _a.length; _i++) {
        var child = _a[_i];
        if (!child.disabled) {
            allChildrenChecked = allChildrenChecked && child.checked;
            someChildrenChecked =
                someChildrenChecked || child.checked || child.indeterminate;
        }
    }
    var checked = false;
    var indeterminate = false;
    if (allChildrenChecked) {
        checked = true;
    }
    else if (someChildrenChecked) {
        indeterminate = true;
    }
    node.checked = checked;
    node.indeterminate = indeterminate;
}
