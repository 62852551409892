import dayjs from 'dayjs';
/**
 * 日期范围两个值是否都有效, 且年月日不相等
 * @param dateRange
 * @returns
 */
export function getRangeValid(dateRange) {
    if (!dateRange || !Array.isArray(dateRange)) {
        return false;
    }
    var start = dateRange[0], end = dateRange[1];
    if (!start || !end) {
        return false;
    }
    // 将每个日期转换为当天开始，然后比较年月日部分
    var startClipped = dayjs(start).startOf('day');
    var endClipped = dayjs(end).startOf('day');
    var isRangeValid = startClipped.isSame(endClipped, 'date');
    return !isRangeValid;
}
