import React from 'react';
import classNames from 'classnames';
var SelectValue = function (props) {
    var _a;
    var items = props.items, prefix = props.prefix, disabled = props.disabled, type = props.type;
    var clsPrefix = "".concat(prefix, "select");
    var isInverse = type === 'inverse';
    return (React.createElement("div", { className: classNames((_a = {},
            _a["".concat(clsPrefix, "-value--inverse")] = isInverse,
            _a["".concat(clsPrefix, "-value--disabled")] = disabled,
            _a["".concat(clsPrefix, "-value--inverse-disabled")] = disabled && isInverse,
            _a)) }, items.join('，')));
};
export default SelectValue;
