import { __assign, __rest } from "tslib";
import * as React from 'react';
import classNames from 'classnames';
import { CnBox } from '@/components/cn-box';
import { Indicator } from './indicator';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
import omit from 'lodash/omit';
var prefix = 'cn-ui-m-';
/** Loading */
export var CnLoading = function (props) {
    var _a, _b, _c;
    var tip = props.tip, visible = props.visible, children = props.children, className = props.className, style = props.style, tipAlign = props.tipAlign, isGradient = props.isGradient, size = props.size, inverse = props.inverse, isRenderChilren = props.isRenderChilren, others = __rest(props, ["tip", "visible", "children", "className", "style", "tipAlign", "isGradient", "size", "inverse", "isRenderChilren"]);
    var cls = classNames(CN_UI_HASH_CLASS_NAME, (_a = {
            'cn-ui-m-loading': true
        },
        _a["cn-ui-m-loading-".concat(size)] = true,
        _a["".concat(prefix, "open")] = visible,
        _a['cn-ui-m-loading-inverse'] = inverse,
        _a['cn-ui-m-loading-relative'] = isRenderChilren,
        _a));
    var svgCls = classNames((_b = {
            'cn-ui-m-load-svg': true
        },
        _b["cn-ui-m-load-svg-".concat(size)] = true,
        _b));
    var indicatorDom = isGradient ? (React.createElement(Indicator, { className: svgCls, inverse: inverse })) : (React.createElement("div", { className: "cn-load" }));
    var spacing = React.useMemo(function () {
        var spacingNum = 8;
        switch (size) {
            case 'large':
                spacingNum = 12;
                break;
            case 'small':
                spacingNum = 4;
                break;
            default:
                break;
        }
        return spacingNum;
    }, [size]);
    var tipCls = classNames((_c = {},
        _c["".concat(prefix, "loading-tip")] = true,
        _c['cn-ui-m-loading-with-children'] = isRenderChilren,
        _c));
    var restProps = omit(others, ['$i18n', 'disableScroll']);
    // 不做遮罩，直接不渲染子节点
    if (visible) {
        return withNativeProps(props, React.createElement("div", __assign({ className: cls, style: style }, restProps),
            React.createElement(CnBox, { spacing: spacing, direction: tipAlign === 'right' ? 'row' : 'column', align: "center", justify: "center", className: tipCls },
                React.createElement("div", { className: "".concat(prefix, "loading-indicator") }, indicatorDom),
                React.createElement("div", { className: "".concat(prefix, "loading-tip-content") }, tip)),
            isRenderChilren && (React.createElement("div", { className: "".concat(prefix, "loading-component") },
                React.createElement("div", { className: "".concat(prefix, "loading-masker") }),
                children))));
    }
    return withNativeProps(props, React.createElement("div", __assign({ className: className, style: style }, restProps), children));
};
CnLoading.displayName = 'CnLoading';
CnLoading.defaultProps = {
    visible: true,
    animate: null,
    tipAlign: 'bottom',
    size: 'medium',
    disableScroll: false,
    isGradient: true,
    isRenderChilren: false,
};
