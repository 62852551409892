/** panda-disable */
// 遵循规范 https://zh.wikipedia.org/wiki/ISO_4217
export default {
    AED: {
        icon: '🇦🇪',
        country: 'AE',
        value: 'AED',
        label: '阿联酋迪拉姆',
        precision: '2',
    },
    AFA: {
        icon: '🇦🇫',
        country: 'AF',
        value: 'AFA',
        label: '阿富汗尼',
        precision: '2',
    },
    ARA: {
        icon: '🇦🇷',
        country: 'ARG',
        value: 'ARA',
        label: '阿根廷奥斯特拉尔',
        precision: '2',
    },
    AUD: {
        icon: '🇦🇺',
        country: 'AU',
        value: 'AUD',
        label: '澳大利亚元',
        precision: '2',
    },
    BDT: {
        icon: '🇧🇩',
        country: 'BD',
        value: 'BDT',
        label: '孟加拉塔卡',
        precision: '2',
    },
    BHD: {
        icon: '🇧🇭',
        country: 'BH',
        value: 'BHD',
        label: '巴林第纳尔',
        precision: '2',
    },
    BRC: {
        icon: '🇧🇷',
        country: 'BR',
        value: 'BRC',
        label: '巴西克鲁扎多',
        precision: '2',
    },
    BRL: {
        icon: '🇧🇷',
        country: 'BR',
        value: 'BRL',
        label: '巴西雷亚尔',
        precision: '2',
    },
    CAD: {
        icon: '🇨🇦',
        country: 'CA',
        value: 'CAD',
        label: '加拿大元',
        precision: '2',
    },
    CHF: {
        icon: '🇨🇭',
        country: 'CH',
        value: 'CHF',
        label: '瑞士法郎',
        precision: '2',
    },
    CLP: {
        icon: '🇨🇱',
        country: 'CL',
        value: 'CLP',
        label: '智利比索',
        precision: '0',
    },
    CNY: {
        icon: '🇨🇳',
        country: 'CN',
        value: 'CNY',
        label: '人民币元',
        precision: '2',
    },
    CZK: {
        icon: '🇨🇿',
        country: 'CZ',
        value: 'CZK',
        label: '捷克克朗',
        precision: '2',
    },
    DKK: {
        icon: '🇩🇰',
        country: 'DK',
        value: 'DKK',
        label: '丹麦克朗',
        precision: '2',
    },
    ESB: {
        icon: '🇪🇸',
        country: 'ES',
        value: 'ESB',
        label: '西班牙比塞塔（B账户）',
        precision: '0',
    },
    EUR: {
        icon: '🇪🇺',
        country: 'EU',
        value: 'EUR',
        label: '欧元',
        precision: '2',
    },
    GBP: {
        icon: '🇬🇧',
        country: 'GB',
        value: 'GBP',
        label: '英镑',
        precision: '2',
    },
    HKD: {
        icon: '🇭🇰',
        country: 'CN',
        value: 'HKD',
        label: '港元',
        precision: '2',
    },
    HUF: {
        icon: '🇭🇺',
        country: 'HU',
        value: 'HUF',
        label: '匈牙利福林',
        precision: '0',
    },
    IDR: {
        icon: '🇮🇩',
        country: 'IDN',
        value: 'IDR',
        label: '印尼盾',
        precision: '2',
    },
    ILS: {
        icon: '🇮🇱',
        country: 'IL',
        value: 'ILS',
        label: '以色列新谢克尔',
        precision: '2',
    },
    INR: {
        icon: '🇮🇳',
        country: 'IN',
        value: 'INR',
        label: '印度卢比',
        precision: '2',
    },
    JPY: {
        icon: '🇯🇵',
        country: 'JP',
        value: 'JPY',
        label: '日圆',
        precision: '0',
    },
    KES: {
        icon: '🇰🇪',
        country: 'KE',
        value: 'KES',
        label: '肯尼亚先令',
        precision: '2',
    },
    KHR: {
        icon: '🇰🇭',
        country: 'KH',
        value: 'KHR',
        label: '柬埔寨瑞尔',
        precision: '2',
    },
    KRW: {
        icon: '🇰🇷',
        country: 'KR',
        value: 'KRW',
        label: '韩元',
        precision: '0',
    },
    LKR: {
        icon: '🇱🇰',
        country: 'LK',
        value: 'LKR',
        label: '斯里兰卡卢比',
        precision: '2',
    },
    LVL: {
        icon: '🇱🇻',
        country: 'LV',
        value: 'LVL',
        label: '拉脱维亚拉特',
        precision: '2',
    },
    MGF: {
        icon: '🇲🇬',
        country: 'MG',
        value: 'MGF',
        label: '马达加斯加法郎',
        precision: '2',
    },
    MMK: {
        icon: '🇲🇲',
        country: 'MM',
        value: 'MMK',
        label: '缅元',
        precision: '2',
    },
    MOP: {
        icon: '🇲🇴',
        country: 'CN',
        value: 'MOP',
        label: '澳门元',
        precision: '2',
    },
    MXN: {
        icon: '🇲🇽',
        country: 'MX',
        value: 'MXN',
        label: '墨西哥比索',
        precision: '2',
    },
    MYR: {
        icon: '🇲🇾',
        country: 'MY',
        value: 'MYR',
        label: '马来西亚令吉',
        precision: '2',
    },
    MZM: {
        icon: '🇲🇿',
        country: 'MZ',
        value: 'MZM',
        label: '莫桑比克梅蒂卡尔',
        precision: '2',
    },
    NAD: {
        icon: '🇳🇦',
        country: 'NA',
        value: 'NAD',
        label: '纳米比亚元',
        precision: '2',
    },
    NOK: {
        icon: '🇳🇴',
        country: 'NO',
        value: 'NOK',
        label: '挪威克朗',
        precision: '2',
    },
    NPR: {
        icon: '🇳🇵',
        country: 'NP',
        value: 'NPR',
        label: '尼泊尔卢比',
        precision: '2',
    },
    NZD: {
        icon: '🇳🇿',
        country: 'NZ',
        value: 'NZD',
        label: '新西兰元',
        precision: '2',
    },
    PEI: {
        icon: '🇵🇪',
        country: 'PE',
        value: 'PEI',
        label: '秘鲁印地',
        precision: '2',
    },
    PHP: {
        icon: '🇵🇭',
        country: 'PH',
        value: 'PHP',
        label: '菲律宾比索',
        precision: '2',
    },
    PKR: {
        icon: '🇵🇰',
        country: 'PK',
        value: 'PKR',
        label: '巴基斯坦卢比',
        precision: '2',
    },
    PLN: {
        icon: '🇵🇱',
        country: 'PL',
        value: 'PLN',
        label: '波兰兹罗提',
        precision: '2',
    },
    ROL: {
        icon: '🇷🇴',
        country: 'RO',
        value: 'ROL',
        label: '罗马尼亚列伊',
        precision: '2',
    },
    RON: {
        icon: '🇷🇴',
        country: 'RO',
        value: 'RON',
        label: '罗马尼亚列伊',
        precision: '2',
    },
    RUB: {
        icon: '🇷🇺',
        country: 'RU',
        value: 'RUB',
        label: '俄罗斯卢布',
        precision: '2',
    },
    SAR: {
        icon: '🇸🇦',
        country: 'SA',
        value: 'SAR',
        label: '沙特里亚尔',
        precision: '2',
    },
    SEK: {
        icon: '🇸🇪',
        country: 'SE',
        value: 'SEK',
        label: '瑞典克朗',
        precision: '2',
    },
    SGD: {
        icon: '🇸🇬',
        country: 'SG',
        value: 'SGD',
        label: '新加坡元',
        precision: '2',
    },
    SRG: {
        icon: '🇸🇷',
        country: 'SR',
        value: 'SRG',
        label: '苏里南盾',
        precision: '2',
    },
    SUR: {
        icon: '🇷🇺',
        country: 'RU',
        value: 'SUR',
        label: '俄罗斯卢布',
        precision: '2',
    },
    THB: {
        icon: '🇹🇭',
        country: 'TH',
        value: 'THB',
        label: '泰铢',
        precision: '2',
    },
    TMM: {
        icon: '🇹🇲',
        country: 'TM',
        value: 'TMM',
        label: '土库曼斯坦马纳特',
        precision: '0',
    },
    TRL: {
        icon: '🇹🇷',
        country: 'TR',
        value: 'TRL',
        label: '土耳其里拉',
        precision: '0',
    },
    TRY: {
        icon: '🇹🇷',
        country: 'TR',
        value: 'TRY',
        label: '土耳其里拉',
        precision: '2',
    },
    TWD: {
        icon: '🇨🇳',
        country: 'CN',
        value: 'TWD',
        label: '新台币',
        precision: '0',
    },
    UGS: {
        icon: '🇺🇬',
        country: 'UG',
        value: 'UGS',
        label: '乌干达先令',
        precision: '0',
    },
    UGX: {
        icon: '🇺🇬',
        country: 'UG',
        value: 'UGX',
        label: '乌干达先令',
        precision: '0',
    },
    USD: {
        icon: '🇺🇸',
        country: 'US',
        value: 'USD',
        label: '美元',
        precision: '2',
    },
    VEB: {
        icon: '🇻🇪',
        country: 'VE',
        value: 'VEB',
        label: '委内瑞拉玻利瓦尔',
        precision: '2',
    },
    VEF: {
        icon: '🇻🇪',
        country: 'VE',
        value: 'VEF',
        label: '委内瑞拉玻利瓦尔',
        precision: '2',
    },
    VES: {
        icon: '🇻🇪',
        country: 'VE',
        value: 'VES',
        label: '委内瑞拉玻利瓦尔',
        precision: '2',
    },
    VND: {
        icon: '🇻🇳',
        country: 'VN',
        value: 'VND',
        label: '越南盾',
        precision: '0',
    },
    ZAR: {
        icon: '🇿🇦',
        country: 'ZA',
        value: 'ZAR',
        label: '南非兰特',
        precision: '2',
    },
    BEF: {
        icon: '🇧🇪',
        country: 'BE',
        value: 'BEF',
        label: '比利时法郎',
        precision: '2',
    },
    DEM: {
        icon: '🇩🇪',
        country: 'DE',
        value: 'DEM',
        label: '德国马克',
        precision: '2',
    },
    ESP: {
        icon: '🇪🇸',
        country: 'ES',
        value: 'ESP',
        label: '西班牙比塞塔',
        precision: '0',
    },
    FRF: {
        icon: '🇫🇷',
        country: 'FR',
        value: 'FRF',
        label: '法国法郎',
        precision: '2',
    },
    ITL: {
        icon: '🇮🇹',
        country: 'IT',
        value: 'ITL',
        label: '意大利里拉',
        precision: '0',
    },
    KPW: {
        icon: '🇰🇵',
        country: 'KP',
        value: 'KPW',
        label: '朝鲜圆',
        precision: '2',
    },
    NLG: {
        icon: '🇳🇱',
        country: 'NLD',
        value: 'NLG',
        label: '荷兰盾',
        precision: '2',
    },
};
