import { __assign, __spreadArray } from "tslib";
import React from 'react';
import classNames from 'classnames';
import { CnIcon } from '@/components/cn-icon';
import { CnButton } from '@/components/cn-button';
import { formilyReact, formilyShared, } from '@/form/formily';
import { useArray } from '../hooks';
import { cssPrefix } from '../constant/css-prefix';
var isValid = formilyShared.isValid, clone = formilyShared.clone;
var getSchemaDefaultValue = function (schema) {
    if ((schema === null || schema === void 0 ? void 0 : schema.type) === 'array')
        return [];
    if ((schema === null || schema === void 0 ? void 0 : schema.type) === 'object')
        return {};
    if ((schema === null || schema === void 0 ? void 0 : schema.type) === 'void') {
        for (var key in schema.properties) {
            var value = getSchemaDefaultValue(schema.properties[key]);
            if (isValid(value))
                return value;
        }
    }
};
var getDefaultValue = function (defaultValue, schema) {
    if (isValid(defaultValue))
        return clone(defaultValue);
    if (Array.isArray(schema === null || schema === void 0 ? void 0 : schema.items)) {
        return getSchemaDefaultValue(schema.items[0]);
    }
    return getSchemaDefaultValue(schema.items);
};
var useField = formilyReact.useField;
export var Addition = function (props) {
    var _a, _b, _c, _d;
    var self = useField();
    var array = useArray();
    if (!array)
        return null;
    if (((_a = array.field) === null || _a === void 0 ? void 0 : _a.pattern) !== 'editable' &&
        ((_b = array.field) === null || _b === void 0 ? void 0 : _b.pattern) !== 'disabled') {
        return null;
    }
    var schema = formilyReact.useFieldSchema();
    // 最大数量限制
    var maxItems = schema.maxItems;
    var disabled = props.disabled ||
        ((_c = array.props) === null || _c === void 0 ? void 0 : _c.disabled) ||
        array.field.disabled ||
        (maxItems && ((_d = array.field.value) === null || _d === void 0 ? void 0 : _d.length) >= maxItems);
    return (React.createElement(CnButton, __assign({ type: "normal", fullWidth: true, size: "large" }, props, { disabled: disabled, className: classNames(CN_UI_HASH_CLASS_NAME, "".concat(cssPrefix, "-addition"), props.className), onClick: function (e) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
            if (disabled)
                return;
            e.stopPropagation();
            var propsDefaultValue = typeof props.defaultValue === 'function'
                ? props.defaultValue()
                : props.defaultValue;
            var defaultValue = getDefaultValue(propsDefaultValue, array.schema);
            if (props.method === 'unshift') {
                (_b = (_a = array.field) === null || _a === void 0 ? void 0 : _a.unshift) === null || _b === void 0 ? void 0 : _b.call(_a, defaultValue);
                (_d = (_c = array.props) === null || _c === void 0 ? void 0 : _c.onAdd) === null || _d === void 0 ? void 0 : _d.call(_c, 0);
            }
            else {
                (_f = (_e = array.field) === null || _e === void 0 ? void 0 : _e.push) === null || _f === void 0 ? void 0 : _f.call(_e, defaultValue);
                var newValue_1 = (_g = array === null || array === void 0 ? void 0 : array.field) === null || _g === void 0 ? void 0 : _g.value;
                // 新增一行时数据置空
                if (newValue_1 === null || newValue_1 === void 0 ? void 0 : newValue_1.length) {
                    newValue_1 = __spreadArray([], newValue_1, true);
                    newValue_1[((_j = (_h = array === null || array === void 0 ? void 0 : array.field) === null || _h === void 0 ? void 0 : _h.value) === null || _j === void 0 ? void 0 : _j.length) - 1] = propsDefaultValue !== null && propsDefaultValue !== void 0 ? propsDefaultValue : {};
                    setTimeout(function () {
                        var _a;
                        (_a = array.field) === null || _a === void 0 ? void 0 : _a.setValue(newValue_1);
                    });
                }
                (_l = (_k = array.props) === null || _k === void 0 ? void 0 : _k.onAdd) === null || _l === void 0 ? void 0 : _l.call(_k, ((_o = (_m = array === null || array === void 0 ? void 0 : array.field) === null || _m === void 0 ? void 0 : _m.value) === null || _o === void 0 ? void 0 : _o.length) - 1);
            }
            (_p = props.onClick) === null || _p === void 0 ? void 0 : _p.call(props, e);
        } }),
        React.createElement(CnIcon, { type: "icon-add", size: "small" }),
        props.title || self.title));
};
