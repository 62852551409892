import React, { useMemo } from 'react';
import { CnBox } from '../../../../components/cn-box';
import { EntryPointsTags } from '../entry-points-tags';
import './index.scss';
export var EntryPointsContent = function (props) {
    var onItemClick = props.onItemClick, dataSource = props.dataSource;
    var hasChildren = useMemo(function () {
        return dataSource === null || dataSource === void 0 ? void 0 : dataSource.some(function (item) { return item.children; });
    }, [dataSource]);
    if (!(dataSource === null || dataSource === void 0 ? void 0 : dataSource.length))
        return null;
    if (!hasChildren) {
        return (React.createElement("div", { className: "cn-ui-m-entry-points-content" },
            React.createElement(EntryPointsTags, { onItemClick: onItemClick, dataSource: dataSource })));
    }
    return (React.createElement(CnBox, { wrap: false, spacing: 12 }, dataSource === null || dataSource === void 0 ? void 0 : dataSource.map(function (item) { return (React.createElement("div", { className: "cn-ui-m-entry-points-content" },
        React.createElement("div", { className: "cn-ui-m-entry-points-content-title" }, item.label),
        React.createElement(EntryPointsTags, { onItemClick: onItemClick, dataSource: item.children }))); })));
};
