import { __assign } from "tslib";
import React from 'react';
import locale from '@/locales';
import { useI18n, withI18n } from 'panda-i18n';
import { hide as hideMessage, show as showMessage } from '../cn-message/func';
import './index.scss';
import { Indicator } from './indicator';
import classNames from 'classnames';
var CnLoadingToast = withI18n(function () {
    var $i18n = useI18n();
    return (React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-m-loading-toast') },
        React.createElement("div", { className: "cn-ui-m-loading-anim" },
            React.createElement(Indicator, null)),
        React.createElement("div", { className: "cn-ui-m-loading-text" }, $i18n.get({ id: 'Loading', dm: '加载中' }))));
}, { locale: locale, componentName: 'CnLoading' });
export var show = function (config) {
    if (config === void 0) { config = {}; }
    showMessage(__assign({ type: 'loading', custRender: React.createElement(CnLoadingToast, null) }, config));
};
export var hide = function () {
    hideMessage();
};
