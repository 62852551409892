import { __assign, __rest } from "tslib";
import $i18n from 'panda-i18n';
import classNames from 'classnames';
import React from 'react';
import { CnButton } from '@/components/cn-button';
import { isValidArray } from '@/utils/func';
var BottomButton = function (props) {
    var _a, _b;
    var _c = props.prefix, prefix = _c === void 0 ? 'cn-ui-m-' : _c, _d = props.actions, actions = _d === void 0 ? ['cancel', 'ok'] : _d, _e = props.size, size = _e === void 0 ? 'large' : _e, okText = props.okText, cancelText = props.cancelText, className = props.className, okProps = props.okProps, cancelProps = props.cancelProps, _f = props.enableSafeArea, enableSafeArea = _f === void 0 ? false : _f, _g = props.showDividingLine, showDividingLine = _g === void 0 ? true : _g, children = props.children, onOk = props.onOk, onCancel = props.onCancel, others = __rest(props, ["prefix", "actions", "size", "okText", "cancelText", "className", "okProps", "cancelProps", "enableSafeArea", "showDividingLine", "children", "onOk", "onCancel"]);
    var clsPrefix = "".concat(prefix, "bottom-btn");
    var clses = {
        bottomBtn: classNames(clsPrefix, className, (_a = {},
            _a["".concat(clsPrefix, "--dividing")] = showDividingLine,
            _a["".concat(clsPrefix, "--safe")] = enableSafeArea,
            _a)),
        extra: "".concat(clsPrefix, "-extra"),
        container: classNames("".concat(clsPrefix, "-container"), (_b = {},
            _b["".concat(clsPrefix, "-container--col-2")] = isValidArray(actions) && actions.length === 2,
            _b)),
    };
    var _h = okProps || {}, okBtnText = _h.text, otherOkBtnProps = __rest(_h, ["text"]);
    var _j = cancelProps || {}, cancelBtnText = _j.text, otherCancelBtnProps = __rest(_j, ["text"]);
    var newOkBtnText = okText ||
        okBtnText ||
        $i18n.get({ id: 'Determine', dm: '确定', ns: 'CnSelect' });
    var newCancelBtnText = cancelText ||
        cancelBtnText ||
        $i18n.get({ id: 'Cancel', dm: '取消', ns: 'CnSelect' });
    return (React.createElement("div", __assign({}, others, { className: clses.bottomBtn }),
        children ? React.createElement("div", { className: clses.extra }, children) : null,
        React.createElement("div", { className: clses.container }, actions.map(function (act) {
            var isCancel = act === 'cancel';
            var text = isCancel ? newCancelBtnText : newOkBtnText;
            var btnProps = __assign({ onClick: isCancel ? onCancel : onOk, size: size }, (isCancel ? otherCancelBtnProps : otherOkBtnProps));
            return (React.createElement(CnButton, __assign({ key: "bottom-btn-".concat(act), type: isCancel ? 'normal' : 'primary' }, btnProps), text));
        }))));
};
BottomButton.displayName = 'BottomButton';
export default BottomButton;
