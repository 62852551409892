import classNames from 'classnames';
import React from 'react';
import './index.scss';
export function Footer(props) {
    var index = props.index, value = props.value, swipeTo = props.swipeTo;
    if ((value === null || value === void 0 ? void 0 : value.length) <= 1)
        return null;
    return (React.createElement("div", { className: "cn-ui-m-image-viewer-footer" }, value.map(function (_, i) { return (React.createElement("span", { key: _, onClick: function (e) {
            e.stopPropagation();
            swipeTo(i);
        }, className: classNames('cn-ui-m-image-viewer-footer-item', {
            'cn-ui-m-image-viewer-footer-item-active': i === index % value.length,
        }) },
        React.createElement("span", { className: "cn-ui-m-image-viewer-footer-item-inner" }))); })));
}
