import $i18n from 'panda-i18n';
import React, { useContext, useEffect, useRef } from 'react';
import { CardItemsStateCtx } from '../../context/card-items';
import './index.scss';
export var LoadMore = function () {
    var loadMoreDomRef = useRef(null);
    var _a = useContext(CardItemsStateCtx), dataSource = _a.dataSource, loading = _a.loading, hasNextPage = _a.hasNextPage, loadNextPage = _a.loadNextPage;
    useEffect(function () {
        if (!window.IntersectionObserver) {
            console.error('IntersectionObserver is not supported');
            return;
        }
        var dom = loadMoreDomRef.current;
        if (loading || !hasNextPage || !dom)
            return;
        var io = new IntersectionObserver(function (entries) {
            if (entries[0].intersectionRatio <= 0)
                return;
            loadNextPage();
        }, { threshold: 1 });
        io.observe(dom);
        return function () {
            io.unobserve(dom);
            io.disconnect();
        };
    }, [loadNextPage, loading, hasNextPage]);
    if (!dataSource.length)
        return null;
    if (loading) {
        return (React.createElement("div", { className: "cn-ui-m-card-items-more-loading" }, $i18n.get({ id: 'Loading', dm: '加载中', ns: 'CnCardItems' })));
    }
    if (hasNextPage) {
        return (React.createElement("div", { ref: loadMoreDomRef, className: "cn-ui-m-card-items-load-more" }, $i18n.get({
            id: 'PullDownToLoadMore',
            dm: '下拉加载更多',
            ns: 'CnCardItems',
        })));
    }
    return (React.createElement("div", { className: "cn-ui-m-card-items-no-more" }, $i18n.get({ id: 'NoMore', dm: '没有更多了', ns: 'CnCardItems' })));
};
