import { __assign } from "tslib";
import { toggleNode } from './toggle-node';
import { updateNodeState } from './update-node-state';
/**
 * 在树中选中节点，主要用于用户主动点击的回调
 * @param nodes 节点列表
 * @param pos 节点位置
 * @param checked 是否选中
 * @returns 更新后的节点列表
 */
export function selectNodeInTree(nodes, pos, checked) {
    return nodes.map(function (node) {
        if ((node === null || node === void 0 ? void 0 : node.pos) === pos) {
            var toggledNode = toggleNode(node, checked);
            return updateNodeState(toggledNode);
        }
        else if (node.children && node.children.length > 0) {
            return __assign(__assign({}, node), { children: selectNodeInTree(node.children, pos, checked) });
        }
        return node;
    });
}
