export function getValue(entity, path) {
    var current = entity;
    for (var i = 0; i < path.length; i += 1) {
        if (current === null || current === undefined) {
            return undefined;
        }
        current = current[path[i]];
    }
    return current;
}
export function getValueByDataIndex(data, dataIndex) {
    var dataPath = [];
    if (dataIndex.includes('.')) {
        dataPath = dataIndex.split('.');
    }
    else {
        dataPath = [dataIndex];
    }
    var value = getValue(data, dataPath);
    if (value === undefined) {
        value = '- -';
    }
    return value;
}
