import $i18n from 'panda-i18n';
import locale from '@/locales';
import Select from './select';
import CnSelectOption from './option';
import './styles/index.scss';
// FIXME: 由于内部组件有直接调用, 暂时使用主动初始化ns实例的方案
$i18n.init({ locale: locale, componentName: 'CnSelect' });
// @ts-ignore select
var CnSelect = Select;
CnSelect.Option = CnSelectOption;
CnSelect.displayName = 'CnSelect';
export { CnSelect, CnSelectOption };
