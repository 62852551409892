import { __assign, __rest } from "tslib";
import './cn-input-textarea.scss';
import $i18n from 'panda-i18n';
import * as React from 'react';
import cx from 'classnames';
import { useValue } from '@/utils/use-value';
import isNumber from 'lodash/isNumber';
import { filterFormAndNonDomProps, withNativeProps } from '@cainiaofe/cn-ui-common';
export var CnInputTextArea = function (props) {
    var _a;
    var className = props.className, _b = props.type, type = _b === void 0 ? 'normal' : _b, _c = props.rows, rows = _c === void 0 ? 3 : _c, _d = props.placeholder, placeholder = _d === void 0 ? $i18n.get({
        id: 'gxms-pc_Enter.CNTM',
        dm: '请输入',
        ns: 'CnInputTextarea',
    }) : _d, 
    // keyboardType,
    // useOriginalRef,
    disabled = props.disabled, maxLength = props.maxLength, showLimitHint = props.showLimitHint, readOnly = props.readOnly, autoFocus = props.autoFocus, align = props.align, highlightable = props.highlightable, 
    // composition,
    onFocus = props.onFocus, onBlur = props.onBlur, onChange = props.onChange, 
    // onConfirm,
    // onCompositionStart,
    // onCompositionEnd,
    rest = __rest(props, ["className", "type", "rows", "placeholder", "disabled", "maxLength", "showLimitHint", "readOnly", "autoFocus", "align", "highlightable", "onFocus", "onBlur", "onChange"]);
    // textarea原生属性
    var originProps = filterFormAndNonDomProps(rest);
    var clsPrefix = 'cn-ui-m-textarea';
    var _e = React.useState(false), isFocused = _e[0], setIsFocused = _e[1];
    var _f = useValue(props, '', {
        fitValue: function (v) {
            if (isNumber(maxLength) && maxLength > 0) {
                return "".concat(v).length > maxLength ? "".concat(v).substr(0, maxLength) : v;
            }
            return v;
        },
    }), value = _f[0], setValue = _f[1], isControlled = _f[2], setPropValue = _f[3];
    // className样式计算
    var classes = {
        textareaContainer: cx(CN_UI_HASH_CLASS_NAME, clsPrefix, "".concat(clsPrefix, "--").concat(type), (_a = {},
            _a["".concat(clsPrefix, "--highlighted")] = highlightable && isFocused,
            _a["".concat(clsPrefix, "--read-only")] = readOnly,
            _a), className),
        readOnly: cx("".concat(clsPrefix, "-read-only-wrapper")),
        textarea: cx("".concat(clsPrefix, "-wrapper"), "".concat(clsPrefix, "--").concat(align)),
    };
    /** onChange事件 */
    var onChangeHandle = function (e) {
        e.stopPropagation();
        var val = e.target.value;
        if (isControlled) {
            // 受控模式下同时更新 prop 值
            setPropValue(val);
        }
        else {
            setValue(val);
        }
        onChange === null || onChange === void 0 ? void 0 : onChange(val, e);
    };
    /** onFocus事件 */
    var onFocusHandle = function () {
        onFocus === null || onFocus === void 0 ? void 0 : onFocus();
        setIsFocused(true);
    };
    /** onBlur事件 */
    var onBlurHandle = function () {
        onBlur === null || onBlur === void 0 ? void 0 : onBlur();
        setIsFocused(false);
    };
    /** 只读模式，没有其他，只读态高度自适应,识别 /n 换行 */
    var renderReadOnly = function () {
        return (React.createElement("div", { className: "".concat(clsPrefix, "-readonly-div") }, value.split('\n').map(function (item) {
            return React.createElement("p", null, item);
        })));
    };
    /** textarea 输入模式 */
    var renderTextarea = function () {
        return (React.createElement("textarea", __assign({}, originProps, { className: classes.textarea, rows: rows, value: value, placeholder: placeholder, autoFocus: autoFocus, maxLength: maxLength, disabled: !!disabled, onInput: onChangeHandle, onChange: onChangeHandle, onBlur: onBlurHandle, onFocus: onFocusHandle })));
    };
    /** 有限制输入长度时，右侧显示输入字数 */
    var renderLengthTips = function () {
        if (!maxLength || !showLimitHint || !!readOnly || !!disabled) {
            return null;
        }
        return (React.createElement("div", { className: "".concat(clsPrefix, "-length-tips") },
            React.createElement("span", { className: "".concat(clsPrefix, "-length-tips-value") }, value.length),
            maxLength ? React.createElement("span", null,
                "/",
                maxLength) : null));
    };
    return withNativeProps(props, React.createElement("div", { className: classes.textareaContainer },
        readOnly ? renderReadOnly() : renderTextarea(),
        renderLengthTips()));
};
CnInputTextArea.displayName = 'CnInputTextArea';
CnInputTextArea.defaultProps = {
    showLimitHint: false,
};
