import { __assign, __rest } from "tslib";
import $i18n from 'panda-i18n';
import classNames from 'classnames';
import React, { forwardRef, Fragment, useCallback, useImperativeHandle, useState, } from 'react';
import { CnButton } from '@/components/cn-button';
import { CnIcon } from '@/components/cn-icon';
import { CnSafeArea } from '@/components/cn-safe-area';
import { CnDrawer } from '@/components/cn-drawer';
import { BottomButton } from '../cn-select/bottom-button';
import isString from 'lodash/isString';
import { stopPropagation } from '@/utils/func';
import { getValidChildren } from '@/utils/children';
import isFunction from 'lodash/isFunction';
var SelectDrawer = function (props, ref) {
    var _a, _b, _c, _d, _e;
    var _f = props.prefix, prefix = _f === void 0 ? 'cn-ui-m-' : _f, alignProp = props.align, children = props.children, _g = props.drawerTitle, drawerTitle = _g === void 0 ? '' : _g, iconTypeProp = props.iconType, _h = props.content, content = _h === void 0 ? '' : _h, placeholder = props.placeholder, _j = props.type, type = _j === void 0 ? 'normal' : _j, _k = props.buttonPosition, buttonPosition = _k === void 0 ? 'top' : _k, className = props.className, drawerClassName = props.drawerClassName, formLabel = props.formLabel, insideForm = props.insideForm, insideFilter = props.insideFilter, _l = props.disabled, disabled = _l === void 0 ? false : _l, _m = props.readOnly, readOnly = _m === void 0 ? false : _m, _o = props.showIcon, showIcon = _o === void 0 ? true : _o, _p = props.hasClear, hasClear = _p === void 0 ? false : _p, hideButton = props.hideButton, _q = props.showToolbar, showToolbarProp = _q === void 0 ? true : _q, notFoundContent = props.notFoundContent, onOk = props.onOk, onCancel = props.onCancel, onClear = props.onClear, onVisibleChange = props.onVisibleChange, okText = props.okText, cancelText = props.cancelText, zIndex = props.zIndex, sizeProp = props.size, showClear = props.showClear, isOverflowEllipsis = props.isOverflowEllipsis, others = __rest(props, ["prefix", "align", "children", "drawerTitle", "iconType", "content", "placeholder", "type", "buttonPosition", "className", "drawerClassName", "formLabel", "insideForm", "insideFilter", "disabled", "readOnly", "showIcon", "hasClear", "hideButton", "showToolbar", "notFoundContent", "onOk", "onCancel", "onClear", "onVisibleChange", "okText", "cancelText", "zIndex", "size", "showClear", "isOverflowEllipsis"]);
    var _r = useState(false), visible = _r[0], setVisible = _r[1];
    var _children = getValidChildren(children);
    var align = alignProp || 'left';
    var size = sizeProp || 'medium';
    var shouldShowClear = !disabled && hasClear && (isFunction(showClear) ? showClear() : content);
    var shouldShowIcon = showIcon && !shouldShowClear;
    var isInverse = type === 'inverse';
    var iconType = iconTypeProp || (insideFilter ? 'triangle-down-fill' : 'arrow-right');
    // notice: showToolbarProp 为 false 是，直接隐藏 title 和所有按钮
    var showToolbar = showToolbarProp && buttonPosition === 'top' && !hideButton;
    var showBottomBtn = showToolbarProp && buttonPosition === 'bottom' && !hideButton;
    var clsPrefix = "".concat(prefix, "selectdrawer");
    var doHide = useCallback(function (reason) {
        if (visible) {
            setVisible(false);
        }
        if (onVisibleChange) {
            onVisibleChange(false, reason);
        }
    }, [visible, setVisible, onVisibleChange]);
    var handleOk = useCallback(function () {
        var shouldHide = true;
        if (onOk) {
            shouldHide = onOk();
        }
        if (shouldHide !== false) {
            doHide();
        }
    }, [onOk, doHide]);
    var handleCancel = useCallback(function (reason) {
        var shouldHide = true;
        if (onCancel) {
            shouldHide = onCancel(reason);
        }
        if (shouldHide !== false) {
            doHide(reason);
        }
    }, [onCancel, doHide]);
    var handleClear = useCallback(function (e) {
        e.preventDefault();
        stopPropagation(e);
        if (onClear) {
            onClear();
        }
    }, [onClear]);
    var handleOpen = function () {
        if (disabled || readOnly || visible) {
            return;
        }
        if (_children || notFoundContent) {
            setVisible(true);
            if (onVisibleChange) {
                onVisibleChange(true, undefined);
            }
        }
    };
    useImperativeHandle(ref, function () {
        return Object.assign(ref.current || {}, {
            hide: doHide,
            show: handleOpen,
            ok: handleOk,
            cancel: handleCancel,
        });
    }, [doHide, handleCancel, handleOk, handleOpen, ref]);
    var getContent = function () {
        var _a, _b;
        if (!content) {
            return (React.createElement("div", { className: classNames((_a = {},
                    _a["".concat(clsPrefix, "-placeholder")] = true,
                    _a["".concat(clsPrefix, "-placeholder--").concat(align)] = true,
                    _a["".concat(clsPrefix, "-placeholder--disabled")] = disabled,
                    _a["".concat(clsPrefix, "-placeholder--inverse")] = isInverse,
                    _a["".concat(clsPrefix, "-placeholder--inverse-disabled")] = disabled && isInverse,
                    _a)) }, placeholder ||
                $i18n.get({ id: 'PleaseSelect', dm: '请选择', ns: 'CnSelect' })));
        }
        else if (isString(content)) {
            return (React.createElement("span", { className: classNames((_b = {},
                    _b["".concat(clsPrefix, "-content-text")] = true,
                    _b["".concat(clsPrefix, "-content-text--").concat(align)] = true,
                    _b["".concat(clsPrefix, "-content-text--").concat(size)] = size,
                    _b["".concat(clsPrefix, "-content-text--disabled")] = disabled,
                    _b["".concat(clsPrefix, "-content-text--inverse")] = isInverse,
                    _b["".concat(clsPrefix, "-content-text--inverse-disabled")] = isInverse && disabled,
                    _b)), style: {
                    textAlign: align,
                } }, content));
        }
        else {
            return content;
        }
    };
    var renderChildren = function () {
        var ret = null;
        if (_children) {
            ret = React.createElement("div", { className: "".concat(clsPrefix, "-drawer-content") }, _children);
        }
        else if (isString(notFoundContent)) {
            ret = (React.createElement("div", { className: "".concat(clsPrefix, "-drawer-content ").concat(clsPrefix, "-drawer--empty") },
                React.createElement("span", { className: "".concat(clsPrefix, "-drawer-empty-text") }, notFoundContent)));
        }
        else if (notFoundContent) {
            ret = (React.createElement("div", { className: "".concat(clsPrefix, "-drawer-content ").concat(clsPrefix, "-drawer--empty") },
                React.createElement("div", { className: "".concat(clsPrefix, "-drawer-empty-text") }, notFoundContent)));
        }
        return ret;
    };
    return (React.createElement(Fragment, null,
        React.createElement("div", __assign({ ref: ref }, others, { className: classNames(CN_UI_HASH_CLASS_NAME, clsPrefix, "".concat(clsPrefix, "--").concat(size), className, (_a = {},
                _a["".concat(clsPrefix, "-form")] = insideForm,
                _a["".concat(clsPrefix, "-filter")] = insideFilter,
                _a)), "data-testid": "select-drawer", onClick: handleOpen }),
            React.createElement("div", { className: classNames("".concat(clsPrefix, "-content"), "".concat(clsPrefix, "-content--").concat(size), "".concat(clsPrefix, "-content--").concat(align), (_b = {},
                    _b["".concat(clsPrefix, "-content--inside-filter")] = insideFilter,
                    _b["".concat(clsPrefix, "-content--overflow")] = isOverflowEllipsis && insideFilter,
                    _b)) }, getContent()),
            shouldShowIcon ? (React.createElement("div", { className: classNames("".concat(clsPrefix, "-icon"), "".concat(clsPrefix, "-icon--").concat(size)) },
                React.createElement(CnIcon, { className: classNames("".concat(clsPrefix, "-icon-text--").concat(size), (_c = {},
                        _c["".concat(clsPrefix, "-icon-text")] = !insideForm,
                        _c["".concat(clsPrefix, "-icon-text--inverse")] = isInverse,
                        _c["".concat(clsPrefix, "-icon-text--disabled")] = disabled,
                        _c["".concat(clsPrefix, "-icon-text--inverse-disabled")] = isInverse && disabled,
                        _c)), type: iconType, "data-testid": "select-drawer-right-arrow-icon" }))) : null,
            shouldShowClear ? (React.createElement("div", { className: classNames("".concat(clsPrefix, "-icon"), "".concat(clsPrefix, "-icon--").concat(size)), onClick: handleClear, "data-testid": "select-drawer-clear-icon" },
                React.createElement(CnIcon, { className: classNames("".concat(clsPrefix, "-icon-text--").concat(size), (_d = {},
                        _d["".concat(clsPrefix, "-icon-text--inverse")] = isInverse,
                        _d["".concat(clsPrefix, "-icon-text--disabled")] = disabled,
                        _d["".concat(clsPrefix, "-icon-text--inverse-disabled")] = isInverse && disabled,
                        _d)), type: "error-r-fill" }))) : null),
        React.createElement(CnDrawer, { visible: visible, title: drawerTitle || formLabel, placement: "bottom", closeMode: drawerTitle || formLabel ? ['mask', 'close'] : ['mask'], onClose: handleCancel, zIndex: zIndex, className: classNames(CN_UI_HASH_CLASS_NAME, "".concat(clsPrefix, "-drawer"), drawerClassName), containerClassName: "".concat(clsPrefix, "-container"), noCard: true, "data-testid": "select-drawer-drawer" },
            showToolbar ? (React.createElement("div", { className: classNames("".concat(clsPrefix, "-toolbar"), (_e = {},
                    _e["".concat(clsPrefix, "-toolbar--no-border")] = buttonPosition === 'bottom',
                    _e)) },
                !hideButton && buttonPosition !== 'bottom' ? (React.createElement(CnButton, { className: "".concat(clsPrefix, "-toolbar-btn-cancel"), text: true, onClick: function () { return handleCancel('cancel-button'); }, "data-testid": "select-drawer-btn-cancel" }, cancelText ||
                    $i18n.get({ id: 'Cancel', dm: '取消', ns: 'CnSelect' }))) : null,
                React.createElement("div", { className: "".concat(clsPrefix, "-toolbar-title") }, drawerTitle),
                !hideButton && buttonPosition !== 'bottom' ? (React.createElement(CnButton, { className: "".concat(clsPrefix, "-toolbar-btn-ok"), text: true, type: "primary", onClick: handleOk, "data-testid": "select-drawer-btn-ok" }, okText ||
                    $i18n.get({ id: 'Confirm', dm: '确认', ns: 'CnSelect' }))) : null)) : null,
            renderChildren(),
            showBottomBtn ? (React.createElement(BottomButton, { okText: okText || $i18n.get({ id: 'Confirm', dm: '确认', ns: 'CnSelect' }), cancelText: cancelText ||
                    $i18n.get({ id: 'Cancel', dm: '取消', ns: 'CnSelect' }), onOk: handleOk, onCancel: function () { return handleCancel('cancel-button'); }, enableSafeArea: true })) : (React.createElement(CnSafeArea, null)))));
};
SelectDrawer.displayName = 'SelectDrawer';
export default forwardRef(SelectDrawer);
