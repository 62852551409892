import { __assign, __spreadArray } from "tslib";
import * as React from 'react';
import classNames from 'classnames';
import { CnSelectTag } from '@/components/cn-tag';
import { CnBox } from '@/components/cn-box';
import { useValue } from '@/hooks/use-value';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
// 检查值是否能够完全等同
var checkSelected = function (val, values) {
    if (Array.isArray(values)) {
        return values.includes(val);
    }
    else {
        return val === values;
    }
};
/**
 * @deprecated 已废弃，请使用 CnRadioGroup tag 模式代替
 */
export var CnSelector = function (props) {
    var _a = props.prefix, prefix = _a === void 0 ? 'cn-ui-m-' : _a, isPreview = props.isPreview, _b = props.dataSource, dataSource = _b === void 0 ? [] : _b, _c = props.mode, mode = _c === void 0 ? 'single' : _c, onChange = props.onChange, disabled = props.disabled, columns = props.columns, cnBoxProps = props.cnBoxProps;
    var _d = useValue(props, [], {
        fitValue: function (v) {
            return Array.isArray(v) ? v : [v];
        },
        compare: function (a, b) {
            return a.join(',') === b.join(',');
        },
    }), value = _d[0], setValue = _d[1], isControlled = _d[2];
    var handleClick = function (v) {
        if (isPreview) {
            return;
        }
        if (mode === 'single') {
            var hasChecked = checkSelected(v, value);
            if (isControlled) {
                if (onChange) {
                    onChange(hasChecked ? undefined : v);
                }
            }
            else {
                setValue(hasChecked ? [] : [v]);
            }
        }
        else {
            var newValue = __spreadArray([], value, true);
            if (newValue.includes(v)) {
                newValue.splice(newValue.indexOf(v), 1);
            }
            else {
                newValue.push(v);
            }
            if (isControlled) {
                if (onChange) {
                    onChange(newValue);
                }
            }
            else {
                setValue(newValue);
            }
        }
    };
    var clsPrefix = "".concat(prefix, "selector");
    var items = dataSource.map(function (it) {
        var v = typeof it === 'object' ? it.value : it;
        var label = typeof it === 'object' ? it.label : it;
        var itemDisabled = disabled || (typeof it === 'object' ? it.disabled : false);
        var isSelected = checkSelected(v, value);
        return (React.createElement(CnSelectTag, { key: v, type: isSelected ? 'secondary' : 'default', disabled: itemDisabled, onClick: function () {
                if (itemDisabled)
                    return;
                handleClick(v);
            }, checked: isSelected }, label));
    });
    return withNativeProps(props, React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, clsPrefix, "".concat(clsPrefix, "--medium")) }, columns ? (React.createElement("div", { className: "".concat(clsPrefix, "-grid"), style: { '--columns': columns } }, items)) : (React.createElement(CnBox, __assign({ wrap: true, direction: "row", spacing: 8 }, cnBoxProps), items))));
};
CnSelector.displayName = 'CnSelector';
CnSelector.defaultProps = {
    prefix: 'cn-ui-m-',
    mode: 'single',
};
