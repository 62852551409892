import { __assign } from "tslib";
import * as React from 'react';
import { getDeptCacheByKey } from './request-cache';
export default function DepartmentValueItem(props) {
    var items = props.items, separator = props.separator, mokelay = props.mokelay, host = props.host;
    var _a = React.useState({}), departmentCache = _a[0], setDepartmentCache = _a[1];
    React.useEffect(function () {
        var values = items.map(function (i) { return i.value; });
        var newCache = {};
        values.forEach(function (value) {
            if (departmentCache[value]) {
                newCache[value] = departmentCache[value];
            }
        });
        setDepartmentCache(newCache);
        items.forEach(function (item) {
            if (departmentCache[item.value])
                return;
            if (!item.fullName) {
                getDeptCacheByKey({ value: item.value, mokelay: mokelay, host: host }).then(function (res) {
                    setDepartmentCache(function (cur) {
                        var _a;
                        return (__assign(__assign({}, cur), (_a = {}, _a[item.value] = res, _a)));
                    });
                });
            }
            else {
                setDepartmentCache(function (cur) {
                    var _a;
                    return (__assign(__assign({}, cur), (_a = {}, _a[item.value] = item, _a)));
                });
            }
        });
    }, [items]);
    return items.map(function (i) { var _a; return (_a = departmentCache[i.value]) === null || _a === void 0 ? void 0 : _a.label; }).join(separator);
}
