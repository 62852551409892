var sizeWidthMap = {
    xss: 320,
    xs: 480,
    xms: 620,
    s: 720,
    m: 990,
    l: 1200,
    xl: 1500,
};
/**
 * 根据宽度获取对应的屏幕尺寸
 * @param width 页面宽度
 * @returns 对应的屏幕尺寸
 */
export function getCnListProSizeBasedOnWidth(width) {
    var matchedSize = 'xss';
    var prevSize = 'xss';
    Object.entries(sizeWidthMap).forEach(function (_a) {
        var size = _a[0], cutWidth = _a[1];
        if (width > sizeWidthMap.xl) {
            matchedSize = 'xl';
        }
        if (width > cutWidth) {
            prevSize = size;
        }
        if (width < cutWidth) {
            matchedSize = prevSize;
        }
    });
    return matchedSize;
}
