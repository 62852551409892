import { __assign } from "tslib";
import React from 'react';
import { HocBaseComponents, useCreatePluginInstance, } from '@/utils/plugin';
import { CnPage as CnPageView } from './cn-page';
import './index.scss';
export function CnPage(props) {
    var _a;
    var pluginIns = useCreatePluginInstance('CnPage', props === null || props === void 0 ? void 0 : props.usePlugin);
    var plugins = (_a = pluginIns === null || pluginIns === void 0 ? void 0 : pluginIns.getPlugin) === null || _a === void 0 ? void 0 : _a.call(pluginIns);
    if ((plugins === null || plugins === void 0 ? void 0 : plugins.length) === 0) {
        return React.createElement(CnPageView, __assign({}, props));
    }
    return HocBaseComponents(CnPageView, {
        props: props,
        plugins: plugins,
    });
}
CnPage.displayName = 'CnPage';
