import { __assign, __extends, __rest } from "tslib";
import * as React from 'react';
import { Text } from './text';
export var createTitle = function (Tag) {
    /**
     * Typography.Title
     * @description 分为 H1, H2, H3, H4, H5, H6 不同的组件，表示不同层级，继承 Typography.Text API
     * @order 1
     */
    var Title = /** @class */ (function (_super) {
        __extends(Title, _super);
        function Title() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        Title.prototype.render = function () {
            var _a = this.props, className = _a.className, others = __rest(_a, ["className"]);
            return (React.createElement(Text, __assign({}, others, { component: Tag, className: "cn-ui-m-typography-title ".concat(className || '') })));
        };
        Title.defaultProps = {
            prefix: 'cn-next-',
        };
        return Title;
    }(React.Component));
    Title.displayName = Tag.toUpperCase();
    return Title;
};
