import { __assign, __awaiter, __generator, __rest } from "tslib";
import * as React from 'react';
import { useField } from '@formily/react';
import { CnSelect } from '@/components/cn-select';
import { handleRequestService } from './service';
import { useRequest } from 'ahooks';
import LRU from 'lru-cache';
import useInitRequest from './useInitRequest';
import isFunction from 'lodash/isFunction';
import { loopDataSource } from '@/utils/tree';
var AsyncSelect = function (props, ref) {
    var _a, _b, _c, _d;
    var baseSelectRef = React.useRef(null);
    var lru = React.useState(new LRU({
        max: 5000,
    }))[0];
    var _e = React.useState(false), searchLoading = _e[0], setSearchLoading = _e[1];
    var url = props.remoteUrl, _f = props.requestConfig, requestConfig = _f === void 0 ? {} : _f, _g = props.initRequestConfig, initRequestConfig = _g === void 0 ? {} : _g, propOnVisibleChange = props.onVisibleChange, fieldNames = props.fieldNames, otherProps = __rest(props, ["remoteUrl", "requestConfig", "initRequestConfig", "onVisibleChange", "fieldNames"]);
    if (Array.isArray(otherProps === null || otherProps === void 0 ? void 0 : otherProps.dataSource)) {
        otherProps.dataSource = loopDataSource(otherProps === null || otherProps === void 0 ? void 0 : otherProps.dataSource, fieldNames);
    }
    // 读取requestConfig
    var service = requestConfig.service, searchFormat = requestConfig.searchFormat, otherRequestConfig = __rest(requestConfig, ["service", "searchFormat"]);
    // 请求地址，优先requestConfig，其次remoteUrl
    var remoteUrl = requestConfig.url || url;
    // 请求服务封装
    var requestService = handleRequestService(requestConfig, remoteUrl);
    // 初始请求服务，用于数据回显
    var initRequestService = handleRequestService(initRequestConfig, (initRequestConfig === null || initRequestConfig === void 0 ? void 0 : initRequestConfig.url) || url);
    // form、filter中默认缓存数据
    var formField;
    try {
        // eslint-disable-next-line react-hooks/rules-of-hooks, @typescript-eslint/no-unused-vars
        formField = useField();
    }
    catch (e) {
        console.error('err-mes', e);
    }
    // 通过useRequest返回数据
    var _h = useRequest(requestService, __assign({ ready: !!(remoteUrl || service), staleTime: -1 }, otherRequestConfig)), runAsync = _h.runAsync, run = _h.run, mutate = _h.mutate, 
    // error,
    _j = _h.data, 
    // error,
    data = _j === void 0 ? [] : _j;
    React.useImperativeHandle(ref, function () {
        var _a;
        return (__assign({ mutateDataSource: mutate, sendRequest: run }, ((_a = baseSelectRef === null || baseSelectRef === void 0 ? void 0 : baseSelectRef.current) !== null && _a !== void 0 ? _a : {})));
    });
    // 组装的select props
    var insertSelectProps = {};
    insertSelectProps.dataSource = loopDataSource(data, fieldNames);
    insertSelectProps.onVisibleChange = function (visible, reason) {
        if (isFunction(propOnVisibleChange)) {
            propOnVisibleChange(visible, reason, run);
        }
    };
    // onSearch 自动包装
    // 仅在 showSearch 且 filterLocal 为 false 情况下启用
    var realSearch = otherProps.hasSearch || otherProps.showSearch;
    var enableOnSearch = realSearch && !otherProps.onSearch && props.filterLocal === false;
    if (enableOnSearch) {
        insertSelectProps.searchLoading = searchLoading;
        insertSelectProps.onSearch = function (inputValue) { return __awaiter(void 0, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setSearchLoading(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, runAsync(inputValue)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.error(error_1);
                        return [3 /*break*/, 4];
                    case 4:
                        setSearchLoading(false);
                        return [2 /*return*/];
                }
            });
        }); };
    }
    // 数据源缓存
    React.useEffect(function () {
        var _a;
        data === null || data === void 0 ? void 0 : data.forEach(function (item) {
            lru.set(item.value, Promise.resolve(item));
        });
        (_a = otherProps.dataSource) === null || _a === void 0 ? void 0 : _a.forEach(function (item) {
            lru.set(item.value, Promise.resolve(item));
        });
    }, [data, otherProps.dataSource, lru]);
    var value = props.value, filterLocal = props.filterLocal, useDetailValue = props.useDetailValue;
    // 根据value做数据回显
    useInitRequest({
        value: value,
        filterLocal: filterLocal,
        useDetailValue: useDetailValue,
        requestService: requestService,
        initRequestService: initRequestService,
        lru: lru,
        mutate: mutate,
    });
    var handleChange = function (newValue, actionType, items) {
        if (isFunction(props.onChange)) {
            // isArrayValue 为 true 时，value 不是数组时，需要转换为数组
            var currentValue = newValue;
            if (!Array.isArray(newValue) && (props === null || props === void 0 ? void 0 : props.isArrayValue)) {
                currentValue = [newValue];
            }
            // isArrayValue 为 true 时，mode 为 single 时，清空时，value 需要转换为 undefined
            if ((props === null || props === void 0 ? void 0 : props.isArrayValue) &&
                ((props === null || props === void 0 ? void 0 : props.mode) === 'single' || props.mode === undefined) &&
                actionType === 'clear' &&
                newValue === undefined) {
                currentValue = undefined;
            }
            props.onChange(currentValue, actionType, items);
        }
    };
    // isArrayValue 为 true 时, value 为数组时，需要转换为单值
    var componentValue = props === null || props === void 0 ? void 0 : props.value;
    if ((props === null || props === void 0 ? void 0 : props.isArrayValue) &&
        Array.isArray(props === null || props === void 0 ? void 0 : props.value) &&
        ((_a = props === null || props === void 0 ? void 0 : props.value) === null || _a === void 0 ? void 0 : _a.length) &&
        ((props === null || props === void 0 ? void 0 : props.mode) === 'single' || (props === null || props === void 0 ? void 0 : props.mode) === undefined)) {
        componentValue = (_b = props === null || props === void 0 ? void 0 : props.value) === null || _b === void 0 ? void 0 : _b[0];
    }
    if (componentValue) {
        otherProps.value = componentValue;
    }
    // isArrayValue 为 true 时, defaultValue 为数组时，需要转换为单值
    var componentDefaultValue = props === null || props === void 0 ? void 0 : props.defaultValue;
    if ((props === null || props === void 0 ? void 0 : props.isArrayValue) &&
        Array.isArray(props === null || props === void 0 ? void 0 : props.defaultValue) &&
        ((_c = props === null || props === void 0 ? void 0 : props.defaultValue) === null || _c === void 0 ? void 0 : _c.length) &&
        ((props === null || props === void 0 ? void 0 : props.mode) === 'single' || (props === null || props === void 0 ? void 0 : props.mode) === undefined)) {
        componentDefaultValue = (_d = props === null || props === void 0 ? void 0 : props.defaultValue) === null || _d === void 0 ? void 0 : _d[0];
    }
    return (
    // @ts-ignore ref
    React.createElement(CnSelect, __assign({ ref: baseSelectRef }, insertSelectProps, otherProps, { defaultValue: componentDefaultValue, onChange: handleChange })));
};
var CnAsyncSelect = React.forwardRef(AsyncSelect);
CnAsyncSelect.displayName = 'CnAsyncSelect';
export default CnAsyncSelect;
