import { useContext } from 'react';
import { default as Context } from './context';
import { getLocale } from './utils';
// export interface LocaledComponentProps<T> {
//   locale?: T;
// }
export var LocaledComponentProps = {};
export function useLocale(componentName, props, defaultLocale) {
    if (props === void 0) { props = {}; }
    if (defaultLocale === void 0) { defaultLocale = {}; }
    var locale = useContext(Context).locale;
    return getLocale(locale || {}, componentName, props, defaultLocale);
}
