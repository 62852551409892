function getError(xhr) {
    try {
        var text = xhr.responseText || xhr.response;
        // 创建一个新的DOM解析器对象
        var parser = new DOMParser();
        // 将XML字符串解析为XML文档对象
        var xmlDoc = parser.parseFromString(text, 'application/xml');
        // 获取Code标签的内容
        var errCode = xmlDoc.getElementsByTagName('Code')[0].textContent;
        return new Error(errCode);
    }
    catch ( /* empty */_a) { /* empty */ }
    return new Error();
}
export default function uploadFile(option) {
    var xhr = new XMLHttpRequest();
    if (xhr.upload)
        xhr.upload.onprogress = option.onProgress;
    var formData = new FormData();
    Object.keys(option.data).forEach(function (key) {
        var value = option.data[key];
        // support key-value array data
        if (Array.isArray(value)) {
            value.forEach(function (item) {
                // { list: [ 11, 22 ] }
                // formData.append('list[]', 11);
                formData.append("".concat(key, "[]"), item);
            });
            return;
        }
        formData.append(key, value);
    });
    formData.append('file', option.file);
    xhr.onerror = function () {
        option.onError(getError(xhr));
    };
    xhr.onload = function () {
        // oss 上传固定状态为 200 时代表上传成功
        if (xhr.status === 200) {
            option.onSuccess();
        }
        else {
            option.onError(getError(xhr));
        }
    };
    xhr.open('post', option.action, true);
    // Has to be after `.open()`. See https://github.com/enyo/dropzone/issues/179
    if (option.withCredentials && 'withCredentials' in xhr) {
        xhr.withCredentials = true;
    }
    var headers = option.headers || {};
    Object.keys(headers).forEach(function (h) {
        if (headers[h] !== null) {
            xhr.setRequestHeader(h, headers[h]);
        }
    });
    xhr.send(formData);
    return {
        abort: function () {
            xhr.abort();
        },
    };
}
