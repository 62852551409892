/**
 * 格式化输出值
 * @param v dayjs格式日期值
 * @param fmt 格式
 * @returns
 */
export var formatValue = function (v, fmt) {
    if (!v || !fmt)
        return v || undefined;
    var formatter = function (i, idx) {
        var _fmt = fmt;
        if (Array.isArray(fmt)) {
            _fmt = fmt[idx];
        }
        if (typeof _fmt === 'function') {
            return _fmt(i);
        }
        if (i && i.format) {
            return i.format(_fmt);
        }
        return null;
    };
    return Array.isArray(v) ? v.map(function (i, idx) { return formatter(i, idx); }) : formatter(v);
};
