import React, { useMemo } from 'react';
import { withNativeProps } from '@/utils/with-native-props';
import cx from 'classnames';
var SkeletonParagraph = function (props) {
    var _a = props.lineCount, lineCount = _a === void 0 ? 1 : _a, animated = props.animated;
    var itemCls = cx({
        'cn-ui-m-skeleton-paragraph-item': true,
        'cn-ui-m-skeleton-paragraph-item-animation': animated,
    });
    var list = useMemo(function () {
        var data = [];
        for (var i = 0; i < lineCount; i++) {
            data.push(i);
        }
        return data;
    }, [lineCount]);
    return withNativeProps(props, React.createElement("div", { className: "cn-ui-m-skeleton-paragraph" }, list.map(function () { return (React.createElement("div", { className: itemCls })); })));
};
export default SkeletonParagraph;
