import { __assign, __rest, __spreadArray } from "tslib";
import $i18n from 'panda-i18n';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { SelectDrawer } from '@/components/select-drawer';
import { CnDatePickerView } from '../date-picker-view';
import { useLatest } from 'ahooks';
import dayjs from 'dayjs';
import { toDate } from '@/utils/date';
import isFunction from 'lodash/isFunction';
import { DrawerTab } from '../drawer-tab';
import { compareTime, formatValue, getValueFromDayjs } from '../../utils';
import { CnMessage } from '../../../cn-message';
import { date2StringByMode } from '@/utils/date-to-string-by-mode';
function getInitValue(initValue) {
    return Array.isArray(initValue) ? initValue.map(function (i) { return i || toDate(i); }) : undefined;
}
export var DateRangePicker = function (props) {
    var hasClear = props.hasClear, valueProp = props.value, alignProp = props.align, sizeProp = props.size, iconType = props.iconType, placeholder = props.placeholder, disabled = props.disabled, readOnly = props.readOnly, insideForm = props.insideForm, insideFilter = props.insideFilter, className = props.className, zIndex = props.zIndex, format = props.format, outputFormat = props.outputFormat, defaultValue = props.defaultValue, onChange = props.onChange, onCancel = props.onCancel, onClear = props.onClear, extraFooterRender = props.extraFooterRender, mode = props.mode, prefix = props.prefix, onOk = props.onOk, others = __rest(props, ["hasClear", "value", "align", "size", "iconType", "placeholder", "disabled", "readOnly", "insideForm", "insideFilter", "className", "zIndex", "format", "outputFormat", "defaultValue", "onChange", "onCancel", "onClear", "extraFooterRender", "mode", "prefix", "onOk"]);
    var _a = useState('begin'), activeKey = _a[0], setActiveKey = _a[1];
    var _b = useState(getInitValue(valueProp || defaultValue)), value = _b[0], setValue = _b[1];
    var _c = useState(null), formatValueStr = _c[0], setFormatValueStr = _c[1];
    var _d = useState(value || [new Date(), new Date()]), pickerValue = _d[0], setPickerValue = _d[1];
    var _e = useState(null), pickerFormatValueStr = _e[0], setPickerFormatValueStr = _e[1];
    var ouputFormatRef = useLatest(outputFormat);
    var align = alignProp || 'left';
    var size = sizeProp || 'medium';
    var isControlled = 'value' in props;
    useEffect(function () {
        if (isControlled) {
            var newValue = getInitValue(valueProp || defaultValue);
            if (!newValue || (Array.isArray(newValue) && newValue.every(function (i) { return !i; }))) {
                setPickerValue([new Date(), new Date()]);
                setValue(undefined);
                setPickerFormatValueStr(null);
                setFormatValueStr(null);
                return;
            }
            else if (newValue === value) {
                return;
            }
            setValue(newValue);
            setPickerValue(newValue);
        }
    }, [valueProp, isControlled]);
    var handleCancel = function (reason) {
        setPickerValue(value || [new Date(), new Date()]);
        if (isFunction(onCancel)) {
            onCancel(reason);
        }
        setActiveKey('begin');
    };
    var handleClear = function () {
        if (!isControlled) {
            setPickerValue([new Date(), new Date()]);
            setValue(undefined);
            setFormatValueStr(null);
            setPickerFormatValueStr(null);
        }
        if (isFunction(onClear)) {
            onClear();
        }
        if (onChange) {
            onChange([undefined, undefined], ['', '']);
            setPickerValue([new Date(), new Date()]);
        }
    };
    var getFormated = useCallback(function (newValue) {
        if (!Array.isArray(newValue))
            return undefined;
        return newValue.map(function (i) {
            return dayjs(i).format(format);
        });
    }, [format]);
    var handleOk = useCallback(function () {
        var newValue = pickerValue;
        if (!newValue)
            return;
        if (!isCorrect) {
            CnMessage.error({
                content: $i18n.get({
                    id: 'TheStartTimeMustBeEarlierThanThe',
                    dm: '开始时间必须早于截止时间',
                    ns: 'CnDatePickerPro',
                }),
            });
            return false;
        }
        var dayjsValue = newValue.map(function (i) { return dayjs(i); });
        var v = (ouputFormatRef === null || ouputFormatRef === void 0 ? void 0 : ouputFormatRef.current)
            ? formatValue(dayjsValue, ouputFormatRef === null || ouputFormatRef === void 0 ? void 0 : ouputFormatRef.current)
            : getValueFromDayjs(dayjsValue, true, props.endOfDay);
        onChange === null || onChange === void 0 ? void 0 : onChange(v, format ? getFormated(newValue) : v === null || v === void 0 ? void 0 : v.map(function (ele) { return date2StringByMode(ele, mode); }));
        if (!isControlled) {
            setValue(newValue);
            setFormatValueStr(pickerFormatValueStr);
        }
        setActiveKey('begin');
    }, [pickerValue, getFormated, props.endOfDay, ouputFormatRef, pickerFormatValueStr, mode]);
    var content = useMemo(function () {
        var formatted = formatValueStr ||
            (format ? getFormated(value) : value === null || value === void 0 ? void 0 : value.map(function (ele) { return date2StringByMode(ele, mode); }));
        return Array.isArray(formatted) ? formatted.join(' - ') : null;
    }, [value, getFormated, formatValueStr, mode]);
    var isCorrect = useMemo(function () {
        return compareTime(pickerValue);
    }, [pickerValue]);
    if (readOnly) {
        return React.createElement("span", { className: "cn-ui-m-date-range-picker-pro-preview" }, content);
    }
    var handleChange = function (d, index) {
        var newPickerValue = Array.isArray(pickerValue) ? __spreadArray([], pickerValue, true) : [];
        newPickerValue[index] = d;
        setPickerValue(newPickerValue);
    };
    return (React.createElement(SelectDrawer, { disableScroll: true, hasClear: hasClear, content: content, align: align, size: size, iconType: iconType, placeholder: placeholder, disabled: disabled, readOnly: readOnly, insideForm: insideForm, insideFilter: insideFilter, className: classNames(className, 'cn-ui-m-date-range-picker-pro'), drawerClassName: "cn-ui-m-date-range-picker-pro-drawer", onOk: handleOk, onCancel: handleCancel, onClear: handleClear, buttonPosition: "bottom" },
        React.createElement(DrawerTab, { activeKey: activeKey, data: pickerValue, mode: mode, onTabChange: setActiveKey, isCorrect: isCorrect }),
        activeKey === 'begin' ? (React.createElement(CnDatePickerView, __assign({}, others, { prefix: prefix, precision: mode, value: (pickerValue === null || pickerValue === void 0 ? void 0 : pickerValue.length) ? pickerValue[0] : undefined, onSelect: function (v) { return handleChange(v, 0); } }))) : (React.createElement(CnDatePickerView, __assign({}, others, { prefix: prefix, precision: mode, value: (pickerValue === null || pickerValue === void 0 ? void 0 : pickerValue.length) > 1 ? pickerValue[1] : undefined, onSelect: function (v) { return handleChange(v, 1); } }))),
        extraFooterRender ? (React.createElement("div", { className: "cn-ui-m-date-picker-extra-footer" }, typeof extraFooterRender === 'function' ? extraFooterRender() : extraFooterRender)) : null));
};
DateRangePicker.displayName = 'CnRangePicker';
