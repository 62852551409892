import { __assign, __rest } from "tslib";
import classNames from 'classnames';
import * as React from 'react';
import isString from 'lodash/isString';
var ListItemExtra = function (props) {
    var _a = props.prefix, prefix = _a === void 0 ? 'cn-ui-m-' : _a, children = props.children, className = props.className, others = __rest(props, ["prefix", "children", "className"]);
    var newClassName = classNames("".concat(prefix, "list-item-extra"), className);
    return isString(children) ? (React.createElement("span", __assign({}, others, { className: newClassName }), children)) : (React.createElement("div", __assign({}, others, { className: newClassName }), children));
};
export default ListItemExtra;
