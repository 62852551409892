import { __assign, __rest } from "tslib";
import classNames from 'classnames';
import * as React from 'react';
import { CnEllipsis } from '@/components/cn-ellipsis';
import isNil from 'lodash/isNil';
import { getValueByDataIndex } from './util';
export var DefaultFormat = function (props) {
    var column = props.column, rowIndex = props.rowIndex, mode = props.mode, data = props.data;
    var dataIndex = column.dataIndex, getCellProps = column.getCellProps, cell = column.cell, render = column.render;
    var value = getValueByDataIndex(data, dataIndex);
    // 这段代码是在处理一个名为 getCellProps 的函数，该函数用于获取表格单元格的属性。这个函数接受三个参数：dataIndex 对应的数据，整个 data 数组，以及行索引 rowIndex。
    // 首先，代码检查 dataIndex 是否存在。如果 dataIndex 存在，那么就从 data 数组中获取对应的元素；如果 dataIndex 不存在，那么就传递 undefined。
    // 然后，代码调用 getCellProps 函数，并使用解构赋值（destructuring assignment）获取返回的对象。这个对象中的 onClick 属性被赋值给 onClickProps，而其他属性被包含在 cellProps 对象中。
    // 如果 getCellProps 未定义或返回 null 或 undefined，那么 onClickProps 和 cellProps 将被赋值为 {}。
    // 这段代码的目的是提供一种灵活的方式来自定义表格单元格的属性。通过 getCellProps 函数，你可以根据单元格的数据和位置来动态设置单元格的属性。
    var _a = (getCellProps === null || getCellProps === void 0 ? void 0 : getCellProps(dataIndex ? data[dataIndex] : undefined, data, rowIndex)) ||
        {}, onClickProps = _a.onClick, cellProps = __rest(_a, ["onClick"]);
    var onClick = function (event) {
        if (!onClickProps)
            return;
        onClickProps(event);
        if (mode === 'simple')
            event.stopPropagation();
    };
    if (cell && typeof cell === 'function') {
        return (React.createElement("span", __assign({}, cellProps, { onClick: onClick }), (cell === null || cell === void 0 ? void 0 : cell(value, rowIndex, data)) || '- -'));
    }
    if (render && typeof render === 'function') {
        return (React.createElement("span", __assign({}, cellProps, { onClick: onClick }), (render === null || render === void 0 ? void 0 : render(value, data, rowIndex)) || '- -'));
    }
    if (!dataIndex)
        return React.createElement(React.Fragment, null, "- -");
    if (value && typeof value === 'object') {
        var _value = value.value, text = value.text, link_1 = value.link, toolTips = value.toolTips, rest = __rest(value, ["value", "text", "link", "toolTips"]);
        var style = {
            color: rest.color,
        };
        var content = (_value !== null && _value !== void 0 ? _value : text) || '- -';
        if (mode === 'simple') {
            return React.createElement(CnEllipsis, { style: style }, content);
        }
        return (React.createElement("span", { className: classNames({ 'cn-table-format-text-link': !!link_1 }), style: style, onClick: function () { return link_1 && window.open(link_1); } }, content));
    }
    if (mode === 'simple' && !isNil(value)) {
        return React.createElement(CnEllipsis, null, value);
    }
    return (React.createElement("span", __assign({}, cellProps, { onClick: onClick }), value !== null && value !== void 0 ? value : '- -'));
};
