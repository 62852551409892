import { __assign, __awaiter, __generator } from "tslib";
import { request } from 'cn-request';
export function handleRequestService(requestConfig, remoteUrl) {
    var _this = this;
    var _a = requestConfig || {}, paramsProps = _a.params, dataProps = _a.data;
    // 配置了remoteUrl，promise由cn-request执行
    if (remoteUrl) {
        return function () { return __awaiter(_this, void 0, void 0, function () {
            var params, data, resultData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        params = paramsProps;
                        data = dataProps;
                        return [4 /*yield*/, request(__assign(__assign({}, requestConfig), { params: params, data: data, url: remoteUrl }))];
                    case 1:
                        resultData = _a.sent();
                        return [2 /*return*/, resultData === null || resultData === void 0 ? void 0 : resultData.data];
                }
            });
        }); };
    }
    // 什么都没配置，返回一个空promise
    // eslint-disable-next-line
    return function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, []];
        });
    }); };
}
