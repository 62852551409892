import React, { useContext } from 'react';
import isEmpty from 'lodash/isEmpty';
import { CnResult } from '@/components/cn-result';
import { CnCard } from '@/components/cn-card';
import { CnLoading } from '@/components/cn-loading';
import { CardItemsStateCtx } from '../../context/card-items';
import { LoadMore } from '../load-more';
import { Item } from '../item';
import './index.scss';
/** 卡片列表 */
export var List = function () {
    var _a = useContext(CardItemsStateCtx), loading = _a.loading, dataSource = _a.dataSource, primaryKey = _a.primaryKey, emptyContent = _a.emptyContent;
    if (!loading && !(dataSource === null || dataSource === void 0 ? void 0 : dataSource.length)) {
        return (React.createElement(CnCard, { className: "cn-ui-m-card-items-empty" }, isEmpty(emptyContent) ? React.createElement(CnResult, { type: "empty" }) : emptyContent));
    }
    var displayElements = [
        React.createElement("div", { className: "cn-ui-m-card-items" }, dataSource === null || dataSource === void 0 ? void 0 :
            dataSource.map(function (item, i) { return (React.createElement(Item, { key: primaryKey && item[primaryKey] ? String(item[primaryKey]) : i, rowIndex: i, data: item })); }),
            React.createElement(LoadMore, null)),
    ];
    if (loading) {
        if (!dataSource || (dataSource === null || dataSource === void 0 ? void 0 : dataSource.length) === 0) {
            // 如果没有数据，显示loading
            return (React.createElement(CnCard, null,
                React.createElement(CnLoading, { visible: true, size: "large", className: "cn-ui-m-card-items-loading" })));
        }
        else {
            // 如果有数据，显示在底部
            displayElements.push(React.createElement(CnLoading, { visible: true, size: "large", className: "cn-ui-m-card-items-loading" }));
        }
    }
    return displayElements;
};
