import { __extends } from "tslib";
import React from 'react';
import { CnBox } from '@/components/cn-box';
import { CnSafeArea } from '../cn-safe-area';
var CnDrawerFooter = /** @class */ (function (_super) {
    __extends(CnDrawerFooter, _super);
    function CnDrawerFooter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CnDrawerFooter.prototype.render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement(CnBox, { direction: "row", spacing: 8, justify: "space-between", align: "center", className: "cn-ui-m-drawer-footer" }, this.props.children),
            React.createElement(CnSafeArea, null)));
    };
    CnDrawerFooter.__type = 'DrawerFooter';
    return CnDrawerFooter;
}(React.Component));
export { CnDrawerFooter };
