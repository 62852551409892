import { __assign } from "tslib";
import * as React from 'react';
import { Plugin } from './plugin';
import { PluginManager } from './plugin-manager';
export var pluginManager = new PluginManager();
export function useCreatePluginInstance(pluginName, localPlugin) {
    var plugin = React.useMemo(function () {
        var pluginInstance = new Plugin(pluginName, pluginManager);
        localPlugin && pluginInstance.setLocalPlugin(localPlugin);
        pluginInstance.setGlobalPlugin(pluginManager
            .getPluginsByComponentName(pluginName)
            .map(function (item) { return item.method; }));
        return pluginInstance;
    }, []);
    return plugin;
}
export function takeExpandPropsFormPlugins(plguins, props) {
    return plguins.reduce(function (expandProps, handleFun) {
        return Object.assign(expandProps, handleFun(expandProps));
    }, __assign({}, props));
}
export * from './types';
export * from './plugin';
export * from './hoc-base-components';
