import { __assign, __rest } from "tslib";
import * as React from 'react';
import { Field, observer, useField, useForm } from '@formily/react';
import { CnFormItem } from '@/form/cn-form-item';
import { mapSize, mapStatus } from '@/form/__builtins__';
export var CnFormField = function (props) {
    var _a;
    var children = props.children, tip = props.tip, otherProps = __rest(props, ["children", "tip"]);
    var form = useForm();
    React.useEffect(function () {
        if (otherProps.name) {
            form.setFieldState(otherProps.name, function (state) {
                state.setDataSource(children.props);
            });
        }
    }, [children.props]);
    if (children) {
        return (React.createElement(Field, __assign({ decorator: [
                CnFormItem,
                {
                    tip: tip,
                    triggerName: (_a = children === null || children === void 0 ? void 0 : children.type) === null || _a === void 0 ? void 0 : _a.displayName,
                },
            ] }, otherProps, { component: [
                observer(function (childProps) {
                    var childrenProps = (childProps === null || childProps === void 0 ? void 0 : childProps.props) || {};
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    var field = useField();
                    var state = mapStatus(childrenProps, field).state;
                    var size = mapSize(childrenProps).size;
                    return React.cloneElement(children, __assign(__assign({}, field.dataSource), { insideForm: true, formLabel: field.props.title, field: field, state: state, size: size, disabled: field.disabled, readOnly: field.readOnly, value: field.value, onChange: field.onInput, onBlur: field.onBlur, onFocus: field.onFocus }));
                }),
            ], dataSource: children.props })));
    }
    return React.createElement(Field, __assign({ decorator: [CnFormItem] }, otherProps));
};
