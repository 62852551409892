export default {
    ActionSheet: {
        cancel: '取消',
    },
    Calendar: {
        weekdays: ['日', '一', '二', '三', '四', '五', '六'],
        months: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
        year: '年',
        month: '月',
        day: '日',
        timePickerLabel: '选择时间',
    },
    CascaderSelect: {
        rootTipText: '首页',
    },
    DatePicker: {
        year: '年',
        month: '月',
        day: '日',
        prev: '上一步',
        next: '下一步',
        selectStartTime: '选择开始时间',
        selectEndTime: '选择结束时间',
    },
    Dialog: {
        ok: '确定',
        cancel: '取消',
    },
    Pagination: {
        prev: '上一页',
        next: '下一页',
        tip: '请选择页数',
    },
    Picker: {
        empty: '无选项',
    },
    Search: {
        searchTipText: '搜索',
        cancelText: '取消',
    },
    Select: {
        searchTipText: '搜索',
        totalPrefixText: '当前已选中',
        totalSuffixText: '项',
        secondaryOkText: '完成',
        searchToOptionBtnText: '添加为选项',
        emptySearchText: '找不到关于 “${searchVal}” 的选项',
    },
    SelectDrawer: {
        placeHolderText: '请选择',
        ok: '确定',
        cancel: '取消',
    },
    Tab: {
        more: '更多',
    },
    TimePicker: {
        hour: '时',
        minute: '分',
        second: '秒',
        am: '上午',
        pm: '下午',
    },
    Upload: {
        reupload: '重新上传',
        limit: '最多只能上传 ${limit} 张照片',
    },
};
