import { __assign, __rest } from "tslib";
import React from 'react';
import classnames from 'classnames';
import { Icon } from './icon';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
var customCache = new Set();
/**
 * @category 方法
 * @description 通过自定义 iconfont源来使用使用svg格式的图片
 */
export function createFromIconfontCN(options) {
    if (options === void 0) { options = {}; }
    var scriptUrl = options.scriptUrl, _a = options.extraCommonProps, extraCommonProps = _a === void 0 ? {} : _a;
    var hasExist = customCache.has(scriptUrl);
    if (document.querySelector("script[data-namespace=\"".concat(scriptUrl, "\"]"))) {
        hasExist = true;
    }
    /**
     * DOM API required.
     * Make sure in browser environment.
     * The Custom Icon will create a <script/>
     * that loads SVG symbols and insert the SVG Element into the document body.
     */
    if (typeof document !== 'undefined' &&
        typeof window !== 'undefined' &&
        typeof document.createElement === 'function' &&
        typeof scriptUrl === 'string' &&
        scriptUrl.length &&
        !hasExist) {
        var script = document.createElement('script');
        script.setAttribute('src', scriptUrl);
        script.setAttribute('data-namespace', scriptUrl);
        customCache.add(scriptUrl);
        document.body.appendChild(script);
    }
    var Iconfont = function (props) {
        var type = props.type, size = props.size, children = props.children, className = props.className, outerClassName = props.outerClassName, rtl = props.rtl, ttb = props.ttb, others = __rest(props, ["type", "size", "children", "className", "outerClassName", "rtl", "ttb"]);
        // component > children > type
        var content = null;
        if (props.type) {
            content = React.createElement("use", { xlinkHref: "#".concat(type) });
        }
        if (children) {
            content = children;
        }
        if (rtl) {
            // @ts-ignore
            others.dir = 'rtl';
        }
        if (ttb) {
            others.dir = 'ttb';
        }
        var classes = classnames('cn-ui-m-icon-remote', className);
        return withNativeProps(props, React.createElement(Icon, __assign({ className: outerClassName, type: type, size: size }, others),
            React.createElement("svg", __assign({ className: classes, focusable: false }, extraCommonProps), content)));
    };
    Iconfont.displayName = 'Iconfont';
    return Iconfont;
}
