/**
 * 排序
 * 排序规则是根据pos，第一优先级是pos长度，长度越短，排序越靠前。第二优先级是touchOrder, touchOrder越小，排序越高。
 * @param nodes 节点列表
 */
export var sortNodesByPos = function (nodes) {
    nodes === null || nodes === void 0 ? void 0 : nodes.sort(function (a, b) {
        var _a, _b, _c, _d;
        // Compare based on pos length
        if (((_a = a === null || a === void 0 ? void 0 : a.pos) === null || _a === void 0 ? void 0 : _a.length) !== ((_b = b === null || b === void 0 ? void 0 : b.pos) === null || _b === void 0 ? void 0 : _b.length)) {
            return ((_c = a === null || a === void 0 ? void 0 : a.pos) === null || _c === void 0 ? void 0 : _c.length) - ((_d = b === null || b === void 0 ? void 0 : b.pos) === null || _d === void 0 ? void 0 : _d.length);
        }
        // If pos length is the same, compare based on touchOrder
        return (a === null || a === void 0 ? void 0 : a.touchOrder) - (b === null || b === void 0 ? void 0 : b.touchOrder);
    });
};
