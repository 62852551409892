import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { isInvalidValue } from '@/utils/date';
dayjs.extend(customParseFormat);
export function dateToDayjs(time, format) {
    if (isInvalidValue(time)) {
        return undefined;
    }
    var dayjsInstance;
    try {
        if (typeof time === 'number') {
            // If the input is a timestamp
            dayjsInstance = dayjs(time);
        }
        else if (typeof time === 'string') {
            // If the input is in the format "HH:mm:ss"
            dayjsInstance = dayjs(time, format || 'HH:mm:ss');
        }
        else {
            // If the input is a Date object
            dayjsInstance = dayjs(time);
        }
    }
    catch (e) {
        throw new Error('Invalid input format');
    }
    return dayjsInstance.toDate();
}
