import dayjs from 'dayjs';
/**
 * 比较两个时间的大小
 * @param dates
 * @returns
 */
export function compareTime(dates) {
    if (!dates || dates.length !== 2) {
        return false;
    }
    var start = dayjs(dates[0]);
    var end = dayjs(dates[1]);
    var isSameOrAfter = end.isSame(start) || end.isAfter(start);
    return isSameOrAfter;
}
