import React, { useMemo } from 'react';
import { isMobile } from '@/utils/detect';
import { CnPage } from '../cn-page';
import { getComponentName } from '@/utils/get-component-name';
var isMobileStatus = isMobile();
export var CnDemoPage = function (props) {
    var title = props.title, noPaddingProps = props.noPadding, children = props.children;
    var noPadding = useMemo(function () {
        if (typeof noPaddingProps === 'boolean')
            return;
        var temp;
        React.Children.forEach(children, function (item) {
            if (typeof temp === 'boolean')
                return;
            if (getComponentName(item) === 'CnDemoBlock') {
                temp = true;
            }
        });
        return temp;
    }, [noPaddingProps, children]);
    if (title && isMobileStatus) {
        document.title = title;
    }
    return React.createElement(CnPage, { noPadding: noPadding }, children);
};
CnDemoPage.defaultProps = {};
CnDemoPage.displayName = 'CnDemoPage';
