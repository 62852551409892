import { __assign } from "tslib";
import $i18n from 'panda-i18n';
import React from 'react';
import { CnDatePickerPro } from './cn-date-picker-pro';
var CnWeekPickerPro = function (props) {
    return (React.createElement(CnDatePickerPro, __assign({ precision: "week", placeholder: $i18n.get({
            id: 'PleaseSelectWeek',
            dm: '请选择周',
            ns: 'CnDatePickerPro',
        }) }, props)));
};
CnWeekPickerPro.displayName = 'CnWeekPickerPro';
export { CnWeekPickerPro };
