/**
 * 从 window 全局变量中获取数据
 * @param key 属性
 */
export var getWindowData = function (key) {
    try {
        return window[key];
    }
    catch (e) {
        console.error(e);
    }
    return null;
};
