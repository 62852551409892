import { __assign, __rest } from "tslib";
import * as React from 'react';
import classNames from 'classnames';
import { CnTabContext } from './context';
import { useGuid } from '@/utils/use-guid';
import isString from 'lodash/isString';
import './item.scss';
export var CnTabItem = React.forwardRef(function (props, ref) {
    var _a, _b, _c;
    var type = props.type, className = props.className, itemKey = props.itemKey, _d = props.title, title = _d === void 0 ? '' : _d, children = props.children, _e = props.onClick, onClick = _e === void 0 ? function () { } : _e, _f = props.renderContent, renderContent = _f === void 0 ? null : _f, others = __rest(props, ["type", "className", "itemKey", "title", "children", "onClick", "renderContent"]);
    var _g = React.useContext(CnTabContext), activeKey = _g.activeKey, change = _g.change;
    var active = "".concat(activeKey) === "".concat(itemKey);
    var clsPrefix = 'cn-ui-m-tab-item';
    var curItemId = useGuid('tab-item-');
    var classes = {
        tab: classNames(CN_UI_HASH_CLASS_NAME, clsPrefix, (_a = {},
            _a["".concat(clsPrefix, "--active")] = active,
            _a), "".concat(clsPrefix, "-type-").concat(type), className),
        activeLine: "".concat(clsPrefix, "-active-line"),
        tabText: classNames("".concat(clsPrefix, "-text"), (_b = {},
            _b["".concat(clsPrefix, "-text--active")] = active,
            _b["".concat(clsPrefix, "-text--secondary")] = type === 'secondary',
            _b)),
    };
    var renderTitle = function () {
        if (React.isValidElement(title)) {
            return title;
        }
        else if (isString(title)) {
            return React.createElement("span", { className: classes.tabText }, title);
        }
        return null;
    };
    var handleClick = function (e) {
        if (onClick) {
            onClick(__assign(__assign({}, e), { itemKey: itemKey }));
        }
        change(itemKey);
    };
    return (React.createElement("div", __assign({ ref: ref }, others, { id: curItemId, className: classes.tab, onClick: handleClick }),
        renderContent
            ? renderContent({
                title: title,
                active: active,
            })
            : renderTitle(),
        React.createElement("div", { className: classNames(classes.activeLine, (_c = {},
                _c["".concat(clsPrefix, "-active-line--enter")] = active,
                _c["".concat(clsPrefix, "-active-line--exit")] = !active,
                _c)) },
            React.createElement("div", { className: "".concat(clsPrefix, "-active-line-block") }))));
});
CnTabItem.displayName = 'CnTabItem';
