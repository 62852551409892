import * as React from 'react';
import { CnImageViewer } from '@/components/cn-image-viewer';
import { FormatText } from './text';
import classNames from 'classnames';
export var FormatImageText = function (props) {
    var value = props.value, mode = props.mode;
    if (value && Array.isArray(value)) {
        return (React.createElement(React.Fragment, null, value.map(function (item) { return (React.createElement("div", null,
            React.createElement(FormatText, { mode: mode, value: item }))); })));
    }
    if (typeof value === 'string') {
        return (React.createElement("div", { className: classNames('cn-ui-m-card-items-format-image-text-img', {
                'format-is-simple': mode === 'simple',
            }), style: { backgroundImage: "url(".concat(value, ")") }, onClick: function (e) {
                if (mode === 'simple')
                    return;
                e.stopPropagation();
                CnImageViewer.open({ src: value });
            } }));
    }
    if (value && typeof value === 'object') {
        var url_1 = value.url, title = value.title, content = value.content;
        return (React.createElement("div", { className: "cn-ui-m-card-items-format-image-text" },
            url_1 ? (React.createElement("div", { className: classNames('cn-ui-m-card-items-format-image-text-img', {
                    'format-is-simple': mode === 'simple',
                }), style: { backgroundImage: "url(".concat(url_1, ")") }, onClick: function (e) {
                    if (mode === 'simple')
                        return;
                    e.stopPropagation();
                    CnImageViewer.open({ src: url_1 });
                } })) : null,
            title && mode !== 'simple' ? (React.createElement("div", { className: "cn-ui-m-card-items-format-image-text-title" },
                React.createElement(FormatText, { mode: mode, value: title }))) : null,
            content && mode !== 'simple' ? (React.createElement("div", { className: "cn-ui-m-card-items-format-image-text-content" },
                React.createElement(FormatText, { mode: mode, value: content }))) : null));
    }
    return React.createElement(React.Fragment, null, "- -");
};
