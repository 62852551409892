/**
 * 获取无效值
 * @param dataSource 数据源
 * @param values 值
 * @returns 无效值
 */
export function getInvalidValue(dataSource, values) {
    if (!Array.isArray(values) || values.length === 0) {
        return [];
    }
    // 值集
    var valueSet = new Set(values);
    // 匹配值
    var matchedValues = new Set();
    // 未匹配值
    var unmatchedValues = [];
    function recursiveSearch(items) {
        items.forEach(function (item) {
            if (valueSet.has(item.value)) {
                matchedValues.add(item.value);
            }
            if (Array.isArray(item.children)) {
                recursiveSearch(item.children);
            }
        });
    }
    recursiveSearch(dataSource);
    valueSet.forEach(function (value) {
        if (!matchedValues.has(value)) {
            unmatchedValues.push(value);
        }
    });
    return unmatchedValues;
}
