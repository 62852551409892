import { __assign, __rest } from "tslib";
import classNames from 'classnames';
import React, { forwardRef, } from 'react';
import { defaultTextRender, progressBackgroundColor, progressiveColor, statusColor, } from './utils';
var CircleProgress = function (props, ref) {
    var _a = props.prefix, prefix = _a === void 0 ? 'cn-ui-m-' : _a, _b = props.percent, percent = _b === void 0 ? 0 : _b, color = props.color, _c = props.size, size = _c === void 0 ? 'medium' : _c, backgroundColor = props.backgroundColor, _d = props.progressive, progressive = _d === void 0 ? false : _d, _e = props.state, state = _e === void 0 ? 'normal' : _e, className = props.className, rtl = props.rtl, _f = props.textRender, textRender = _f === void 0 ? defaultTextRender : _f, others = __rest(props, ["prefix", "percent", "color", "size", "backgroundColor", "progressive", "state", "className", "rtl", "textRender"]);
    var progressiveColorEnabled = function () {
        if (progressive) {
            return progressiveColor(percent);
        }
        return color || statusColor(state);
    };
    var style = {
        '--percent': props.percent.toString(),
        '--fill-color': progressiveColorEnabled(),
        '--track-color': progressBackgroundColor(backgroundColor),
    };
    return (React.createElement("div", __assign({}, others, { ref: ref, className: classNames(CN_UI_HASH_CLASS_NAME, "".concat(prefix, "progress"), "".concat(prefix, "progress-circle--").concat(size), "".concat(prefix, "progress-circle"), className) }),
        React.createElement("div", { className: "".concat(prefix, "progress-circle-content"), style: style },
            React.createElement("svg", { className: "".concat(prefix, "progress-circle-svg") },
                React.createElement("circle", { className: "".concat(prefix, "progress-circle-track"), fill: "transparent" }),
                React.createElement("circle", { className: "".concat(prefix, "progress-circle-fill"), fill: "transparent" })),
            React.createElement("div", { className: "".concat(prefix, "progress-circle-info") }, textRender(percent, { rtl: rtl })))));
};
export default forwardRef(CircleProgress);
