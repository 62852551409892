import { __assign } from "tslib";
import * as React from 'react';
import { useI18n, withI18n } from 'panda-i18n';
import locale from '@/locales';
import { observer, useField } from '@formily/react';
import { CnOSSImageUpload as OSSImageUpload, } from '@/components/cn-oss-image-upload';
import { getFormUploadProps } from '@cainiaofe/cn-ui-common';
export var CnOSSImageUpload = withI18n(observer(function (props) {
    var $i18n = useI18n();
    var fileListRef = React.useRef([]);
    var field = useField();
    return (React.createElement(OSSImageUpload, __assign({}, props, getFormUploadProps({
        fileListRef: fileListRef,
        field: field,
        props: props,
        uploadErrMsg: $i18n.get({
            id: 'PleaseWaitForTheUploadTo_2061225702',
            dm: '请等待上传结束',
            ns: 'CnOssImageUpload',
        }),
    }))));
}), { locale: locale });
CnOSSImageUpload.displayName = 'CnOSSImageUpload';
