import * as React from 'react';
import './index.scss';
import classNames from 'classnames';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
export function CnSafeArea(props) {
    var _a = props.position, position = _a === void 0 ? 'bottom' : _a;
    if (position === 'bottom') {
        return (React.createElement(React.Fragment, null, withNativeProps(props, React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-m-half-safe-area-inset-bottom') }))));
    }
    return null;
}
CnSafeArea.displayName = 'CnSafeArea';
