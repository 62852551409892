import * as React from 'react';
import { CnPopover } from '@/components/cn-popover';
import { CnBox } from '@/components/cn-box';
var SingleUser = function (props) {
    var user = props.user;
    if (typeof user === 'object') {
        var nameDom = void 0;
        var nickNameDom = void 0;
        var workNoDom = void 0;
        var name = user.name, nickName = user.nickName, workNo = user.workNo;
        if (name)
            nameDom = name;
        if (nickName)
            nickNameDom = "(".concat(nickName, ")");
        if (workNo)
            workNoDom = workNo;
        var dom = (React.createElement("div", { className: "cn-ui-m-card-items-format-user-item" },
            React.createElement("img", { src: "https://work.alibaba-inc.com/photo/".concat(workNo, ".32x32.jpg") }),
            React.createElement("span", null,
                nameDom,
                nickNameDom)));
        if (workNoDom) {
            return React.createElement(CnPopover, { content: workNoDom }, dom);
        }
        return dom;
    }
    return user;
};
export var FormatUser = function (props) {
    var value = props.value;
    if (Array.isArray(value)) {
        return (React.createElement(CnBox, { className: "cn-ui-m-card-items-format-user", wrap: true, direction: "row", spacing: 4 }, value.map(function (item) { return (React.createElement("div", null,
            React.createElement(SingleUser, { user: item }))); })));
    }
    return (React.createElement("div", { className: "cn-ui-m-card-items-format-user" },
        React.createElement(SingleUser, { user: value })));
};
