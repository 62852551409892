import { __assign, __rest, __spreadArray } from "tslib";
import * as React from 'react';
import classNames from 'classnames';
import { CnCheckbox } from './cn-checkbox';
import isFunction from 'lodash/isFunction';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import { getDataSourceFromChildren, isValidArray } from '@/utils/func';
import { useValue } from '@/utils/use-value';
import { getItemsFromDataSource } from '@/utils/tree';
import { useFormLayout } from '@/form/cn-form-layout';
import { CnReadOnly } from '@/components/cn-read-only';
import { useCnRequest } from '@cainiaofe/cn-ui-common';
import './cn-checkbox-group.scss';
export var CnCheckboxGroup = function (props) {
    var _a;
    var size = props.size, direction = props.direction, defaultValue = props.defaultValue, dataSource = props.dataSource, className = props.className, onChange = props.onChange, disabled = props.disabled, children = props.children, readOnly = props.readOnly, readOnlyRender = props.readOnlyRender, valueProp = props.value, shape = props.shape, requestConfig = props.requestConfig, others = __rest(props, ["size", "direction", "defaultValue", "dataSource", "className", "onChange", "disabled", "children", "readOnly", "readOnlyRender", "value", "shape", "requestConfig"]);
    var data = useCnRequest(requestConfig).data;
    var prefix = 'cn-ui-m-';
    var labelAlign = useFormLayout().labelAlign;
    var _b = useValue(props, [], {
        fitValue: function (v) {
            if (v && !Array.isArray(v)) {
                return [v];
            }
            if (!isValidArray(v)) {
                return [];
            }
            return v;
        },
        compare: function (a, b) {
            return a.toString() === b.toString();
        },
    }), value = _b[0], setValue = _b[1], isControlled = _b[2];
    var handleChange = React.useCallback(function (selected, checkBoxValue, e) {
        // 取消选中
        if (!selected) {
            var index = value.indexOf(checkBoxValue);
            var newGroup = __spreadArray([], value, true);
            if (index >= 0) {
                newGroup.splice(index, 1);
                if (!isControlled) {
                    setValue(newGroup);
                }
            }
            if (!disabled && !readOnly && onChange) {
                onChange(newGroup, e);
            }
        }
        // 选中
        if (!disabled && !readOnly && selected) {
            var newGroup = __spreadArray(__spreadArray([], value, true), [checkBoxValue], false);
            if (!isControlled) {
                setValue(newGroup);
            }
            if (onChange) {
                onChange(newGroup, e);
            }
        }
    }, [value, setValue, isControlled, onChange]);
    var content;
    // dataSource优先级 > 远程数据源,行为和cn-ui保持一致
    var ds = dataSource || data;
    if (!ds && React.Children.count(children) > 0) {
        ds = getDataSourceFromChildren(children);
    }
    if (Array.isArray(ds) && ds.length > 0) {
        content = ds.map(function (item, index) {
            var _a;
            var itemData = {
                style: {},
                value: null,
                checked: false,
                label: '',
                text: '',
                disabled: false,
                size: size,
            };
            var itemOnClick = item.onClick, itemOnChange = item.onChange, itemClassName = item.className, itemLabel = item.label, itemText = item.text, itemRest = __rest(item, ["onClick", "onChange", "className", "label", "text"]);
            if (isString(item) || isNumber(item)) {
                itemData.value = item;
            }
            else {
                itemData = __assign(__assign({}, itemData), itemRest);
            }
            var label = itemLabel || itemText || itemData.value;
            var isLast = index === ds.length - 1;
            return (React.createElement(CnCheckbox, __assign({}, itemData, { key: "cnuim_ck_".concat(index), className: classNames(itemClassName, (_a = {},
                    _a["".concat(prefix, "checkbox-item--").concat(direction, "-").concat(size)] = !isLast,
                    _a["".concat(prefix, "checkbox-item--tag")] = shape === 'tag',
                    _a)), style: itemData.style, onChange: function (selected, e) { return handleChange(selected, itemData.value, e); }, checked: itemData.checked || value.includes(itemData.value), disabled: disabled || itemData.disabled, onClick: itemOnClick }), label));
        });
    }
    if (readOnly) {
        if (isFunction(readOnlyRender)) {
            return React.createElement("div", null, "readOnlyRender(getItemsFromDataSource(ds, value))");
        }
        return (React.createElement(CnReadOnly, { value: getItemsFromDataSource(ds, value)
                .map(function (v) { return v.label || v.text; })
                .join(',') }));
    }
    return (React.createElement("div", __assign({ className: classNames(className, "".concat(prefix, "checkbox-group"), "".concat(prefix, "checkbox-group--").concat(direction), "".concat(prefix, "checkbox-group--").concat(labelAlign, "-").concat(size), (_a = {},
            _a["".concat(prefix, "checkbox-group--tag")] = shape === 'tag',
            _a)) }, others), content));
};
CnCheckboxGroup.displayName = 'CnCheckboxGroup';
CnCheckboxGroup.defaultProps = {
    size: 'medium',
    direction: 'ver',
    requestConfig: {},
};
