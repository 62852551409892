import { __assign, __awaiter, __generator } from "tslib";
import $i18n from 'panda-i18n';
import React, { forwardRef, useState, useRef, useEffect, useImperativeHandle } from 'react';
import classNames from 'classnames';
import { CnListProItem } from './cn-list-pro-item';
import isFunction from 'lodash/isFunction';
import { CnListProContext } from '@/components/cn-list-pro/hooks';
import './cn-list-pro.scss';
import { CnResult } from '@/components/cn-result';
import { CnGridItem, CnGridLayout } from '@/components/cn-grid-layout';
import { CnScrollView } from '@/components/cn-scroll-view';
import { useSize } from 'ahooks';
import { getCnListProResponsiveGridColumns } from '@cainiaofe/cn-ui-common';
import { CnLoading } from '@/components/cn-loading';
export var CnListPro = forwardRef(function (props, ref) {
    var className = props.className, style = props.style, renderItem = props.renderItem, dataSource = props.dataSource, children = props.children, rowSelection = props.rowSelection, itemKey = props.itemKey, grid = props.grid, imagePosition = props.imagePosition, loading = props.loading, shape = props.shape, pagination = props.pagination;
    var currentPageRef = useRef(1);
    var currentPage = currentPageRef === null || currentPageRef === void 0 ? void 0 : currentPageRef.current;
    useEffect(function () {
        if (currentPageRef.current !== currentPage) {
            currentPageRef.current = currentPage;
        }
    }, [currentPage]);
    var classes = classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-m-list-pro', className, {
        'cn-ui-m-list-pro--card': shape === 'card',
    });
    var listRef = useRef(null);
    useImperativeHandle(ref, function () { return listRef.current; }, [listRef]);
    var _a = useState({
        selectedRowKeys: [],
        selectedRows: [],
    }), rowSelectionStore = _a[0], setRowSelectionStore = _a[1];
    /**
     * 根据配置获取列表的children
     * 当列表是通过 renderItem 自定义渲染时, 返回renderItem渲染dataSource的结果
     * 否则当dataSource非空时, 根据dataSource渲染CnListProItem
     * 否则当children非空时, 根据children渲染
     * @returns 列表的children
     */
    var getChildren = function () {
        if (isFunction(renderItem)) {
            return Array.isArray(dataSource)
                ? dataSource.map(function (item, index) {
                    return renderItem(item, index);
                })
                : null;
        }
        if (Array.isArray(dataSource) && dataSource.length > 0) {
            return dataSource.map(function (item) {
                return (React.createElement(CnListProItem, __assign({}, item, { key: item[itemKey], itemKey: item[itemKey], rowData: item })));
            });
        }
        // 循环 children，并把 id 传给 CnListProItem
        if (children) {
            return React.Children.map(children, function (child) {
                var _a;
                return React.cloneElement(child, {
                    itemKey: itemKey === 'key'
                        ? child.key
                        : (_a = child.props) === null || _a === void 0 ? void 0 : _a[itemKey],
                });
            });
        }
        return children;
    };
    var width = (useSize(listRef) || {}).width;
    /**
     * 根据配置渲染列表
     * 当列表是通过 renderItem 自定义渲染时, 返回renderItem渲染dataSource的结果
     * 否则当dataSource非空时, 根据dataSource渲染CnListProItem
     * 否则当children非空时, 根据children渲染
     * @returns 渲染的列表
     */
    var renderChildren = function () {
        var _children = getChildren();
        if (!_children || (Array.isArray(_children) && _children.length === 0)) {
            return loading ? React.createElement(CnLoading, { visible: true }) : React.createElement(CnResult, { type: "empty" });
        }
        if (grid && typeof grid === 'object') {
            return (React.createElement(CnGridLayout, { grids: grid.column || getCnListProResponsiveGridColumns(grid, width) || 1 }, React.Children.map(_children, function (child) {
                return React.createElement(CnGridItem, null, child);
            })));
        }
        return _children;
    };
    // 是否有更多
    var hasMore = !!(pagination === null || pagination === void 0 ? void 0 : pagination.totalCount) &&
        (pagination === null || pagination === void 0 ? void 0 : pagination.currentPage) * (pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) < (pagination === null || pagination === void 0 ? void 0 : pagination.totalCount) &&
        !loading;
    // 加载更多
    var loadMore = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    currentPageRef.current += 1;
                    return [4 /*yield*/, ((_a = pagination === null || pagination === void 0 ? void 0 : pagination.onChange) === null || _a === void 0 ? void 0 : _a.call(pagination, currentPageRef.current))];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    // 是否展示没有更多
    var showNoMore = !hasMore && !loading && Number(currentPageRef.current) !== 1;
    return (React.createElement("div", { ref: listRef, className: classes, style: style },
        React.createElement(CnListProContext.Provider, { value: {
                rowSelection: rowSelection,
                itemKey: itemKey,
                rowSelectionStore: rowSelectionStore,
                setRowSelectionStore: setRowSelectionStore,
                imagePosition: imagePosition,
                shape: shape,
            } }, !pagination ? (renderChildren()) : (React.createElement(CnScrollView, { scrollEventThrottle: 500, hasMore: hasMore, loadMore: loadMore, style: { overflow: 'unset' } },
            renderChildren(),
            showNoMore && (React.createElement("div", { className: "cn-ui-m-list-pro--no-more" }, $i18n.get({ id: 'TheresNoMore', dm: '没有更多了', ns: 'CnListPro' }))))))));
});
CnListPro.displayName = 'CnListPro';
CnListPro.defaultProps = {
    itemKey: 'key',
    imagePosition: 'left',
    shape: 'normal',
};
