/**
 * 将text滚动到可视范围
 * @param text 文本值
 */
export var scrollIntoViewByText = function (text) {
    var elements = document.querySelectorAll('.cn-ui-m-cascader-content .cn-ui-m-list-item-title');
    for (var _i = 0, elements_1 = elements; _i < elements_1.length; _i++) {
        var element = elements_1[_i];
        if ((element === null || element === void 0 ? void 0 : element.textContent) === text) {
            element === null || element === void 0 ? void 0 : element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            break;
        }
    }
};
