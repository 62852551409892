export function getWidth(id) {
    var ele = document.getElementById(id);
    if (ele) {
        return ele.clientWidth;
    }
    return 0;
}
/**
 * 更多按钮的宽度
 */
export var MORE_BUTTON_WIDTH = {
    small: 28 + 8,
    medium: 38 + 8,
    large: 65 + 8,
};
/**
 * 通过rem获取px
 * @param rem
 */
export var remToPx = function (rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};
