import { __assign, __awaiter, __generator } from "tslib";
import { request } from 'cn-request';
import isFunction from 'lodash/isFunction';
export function handleRequestService(requestConfig) {
    var _this = this;
    var _a = requestConfig || {}, service = _a.service, remoteUrl = _a.url, paramsProps = _a.params, dataProps = _a.data, _b = _a.formatResult, formatResult = _b === void 0 ? function (res) {
        var _a, _b, _c, _d;
        if (Array.isArray(res)) {
            return res;
        }
        else if (Array.isArray(res === null || res === void 0 ? void 0 : res.data)) {
            return res.data;
        }
        else if (Array.isArray((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.dataSource)) {
            return res.data.dataSource;
        }
        else if (Array.isArray((_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b.data)) {
            return res.data.data;
        }
        else if (Array.isArray((_d = (_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.dataSource)) {
            return res.data.data.dataSource;
        }
        return [];
    } : _b, _c = _a.method, method = _c === void 0 ? 'GET' : _c;
    // 配置了service时，promise由service托管
    if (isFunction(service)) {
        return function (searchConfig) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                return [2 /*return*/, (_a = service(searchConfig)) === null || _a === void 0 ? void 0 : _a.then(function (data) {
                        var dataSource = formatResult(data);
                        return Array.isArray(dataSource) ? dataSource : [];
                    })];
            });
        }); };
    }
    // 配置了remoteUrl，promise由cn-request执行
    if (remoteUrl) {
        return function (searchConfig) { return __awaiter(_this, void 0, void 0, function () {
            var params, data, resultData, dataSource;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        params = (method === null || method === void 0 ? void 0 : method.toLocaleUpperCase()) === 'GET'
                            ? __assign(__assign({}, searchConfig), paramsProps) : paramsProps;
                        data = (method === null || method === void 0 ? void 0 : method.toLocaleUpperCase()) === 'POST'
                            ? __assign(__assign({}, searchConfig), dataProps) : dataProps;
                        return [4 /*yield*/, request(__assign(__assign({}, requestConfig), { params: params, data: data, url: remoteUrl }))];
                    case 1:
                        resultData = _a.sent();
                        dataSource = formatResult(resultData);
                        return [2 /*return*/, (Array.isArray(dataSource) ? dataSource : [])];
                }
            });
        }); };
    }
    // 什么都没配置，返回一个空promise
    // eslint-disable-next-line
    return function (searchConfig) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, []];
        });
    }); };
}
