import { getCnListProSizeBasedOnWidth } from './get-cn-list-pro-size-based-on-width';
var defaultSizeColumnsMap = {
    xss: 1,
    xs: 1,
    xms: 2,
    s: 2,
    m: 3,
    l: 3,
    xl: 4,
};
/**
 * 根据响应式栅格配置获取对应的列数
 * @param grid 响应式栅格配置
 * @param containerWidth 容器宽度
 * @returns 对应的列数
 */
export function getCnListProResponsiveGridColumns(grid, containerWidth) {
    if (!grid || !containerWidth) {
        return 1;
    }
    if (grid.column) {
        return grid.column || 1;
    }
    var matchedSize = getCnListProSizeBasedOnWidth(containerWidth);
    return grid[matchedSize] || defaultSizeColumnsMap[matchedSize];
}
