import { __assign, __awaiter, __generator, __rest } from "tslib";
import $i18n from 'panda-i18n';
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { CnSelect } from '@/components/cn-select';
import { formatResponse, processDataSource, search } from './service';
import CnEmployeeItem from './employee-item';
import { useSetState, useUpdateEffect } from 'ahooks';
import { isFunction, isPlainObject } from 'lodash';
import classNames from 'classnames';
import { getFormattedValue, transformToEmployeeMap } from './util';
export var CnEmployeeSelect = function (props) {
    var _a = props.mokelay, mokelay = _a === void 0 ? true : _a, host = props.host, path = props.path, serviceType = props.serviceType, propDataSource = props.dataSource, typeList = props.typeList, isOnJob = props.isOnJob, isPreview = props.isPreview, readOnly = props.readOnly, _b = props.prefix, prefix = _b === void 0 ? 'cn-ui-m-' : _b, service = props.service, onChange = props.onChange, valuePaddingZero = props.valuePaddingZero, _value = props.value, others = __rest(props, ["mokelay", "host", "path", "serviceType", "dataSource", "typeList", "isOnJob", "isPreview", "readOnly", "prefix", "service", "onChange", "valuePaddingZero", "value"]);
    var _c = useSetState({
        dataSource: [],
        searchDataSource: null,
        selectedEmployeeMap: {},
    }), employeeData = _c[0], setEmployeeData = _c[1];
    var timer = React.useRef();
    var serviceRef = React.useRef(service);
    var clsPrefix = "".concat(prefix, "employee-select");
    var formattedValue = getFormattedValue(_value, valuePaddingZero);
    useUpdateEffect(function () {
        serviceRef.current = service;
    }, [service]);
    // value变化时查询具体员工信息
    useEffect(function () {
        var fetchInitData = function () { return __awaiter(void 0, void 0, void 0, function () {
            var searchKeys, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        searchKeys = (formattedValue || [])
                            .map(function (i) {
                            if (isPlainObject(i)) {
                                return i.value || i.workNo;
                            }
                            return i;
                        })
                            .join(',');
                        return [4 /*yield*/, search({
                                value: searchKeys,
                                mokelay: mokelay,
                                host: host,
                                path: path,
                                serviceType: serviceType,
                                typeList: typeList,
                                isOnJob: isOnJob,
                                fromType: 'staffId',
                            })];
                    case 1:
                        result = _a.sent();
                        setEmployeeData({
                            dataSource: result,
                            selectedEmployeeMap: transformToEmployeeMap(result),
                        });
                        return [2 /*return*/];
                }
            });
        }); };
        if (!formattedValue || !serviceType || propDataSource)
            return;
        fetchInitData();
    }, [_value, host, mokelay, path, serviceType, typeList, isOnJob, propDataSource]);
    var clearSearchDataSource = function () { setEmployeeData({ searchDataSource: null }); };
    // 搜索逻辑
    var handleSearch = useCallback(function (val) { return __awaiter(void 0, void 0, void 0, function () {
        var temp, result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!serviceRef.current) return [3 /*break*/, 2];
                    return [4 /*yield*/, serviceRef.current(val, 'keywords')];
                case 1:
                    temp = _a.sent();
                    result = formatResponse(temp);
                    setEmployeeData({
                        searchDataSource: result,
                    });
                    return [2 /*return*/];
                case 2:
                    if (!serviceType || propDataSource) {
                        return [2 /*return*/];
                    }
                    if (!val) {
                        setEmployeeData({
                            searchDataSource: [],
                        });
                        return [2 /*return*/];
                    }
                    clearTimeout(timer.current);
                    timer.current = setTimeout(function () { return __awaiter(void 0, void 0, void 0, function () {
                        var result;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, search({
                                        value: val,
                                        mokelay: mokelay,
                                        host: host,
                                        path: path,
                                        serviceType: serviceType,
                                        typeList: typeList,
                                        isOnJob: isOnJob,
                                        fromType: 'keywords',
                                    })];
                                case 1:
                                    result = _a.sent();
                                    setEmployeeData({
                                        searchDataSource: result,
                                    });
                                    return [2 /*return*/];
                            }
                        });
                    }); }, 300);
                    return [2 /*return*/];
            }
        });
    }); }, [host, mokelay, serviceType, path, typeList, isOnJob, propDataSource]);
    // 当传入外部数据源时，自动格式化并赋值到组件
    useEffect(function () {
        if (!Array.isArray(propDataSource))
            return;
        var processedDataSource = processDataSource(propDataSource);
        setEmployeeData({
            dataSource: processedDataSource,
        });
    }, [propDataSource]);
    return (React.createElement(CnSelect, __assign({ notFoundContent: $i18n.get({
            id: 'NoRelevantPersonnelDataHaveNot_1705518629',
            dm: '暂无未找到相关人员数据',
            ns: 'CnEmployeeSelect',
        }), placeholder: $i18n.get({
            id: 'PleaseEnterWorkNumberOrFlower_129025031',
            dm: '请输入工号或花名',
            ns: 'CnEmployeeSelect',
        }), onSearch: handleSearch, renderSelection: function (opts) {
            if (!opts.length)
                return null;
            return opts.map(function (i) {
                // 兼容传入工号数组的场景，此时需要从employeeMap中获取员工信息
                var employeeId = isPlainObject(i) ? i === null || i === void 0 ? void 0 : i.value : i;
                if (typeof employeeId === 'string') {
                    var employee = employeeData.selectedEmployeeMap[employeeId] || {};
                    return employee.label || employee.nickName || employee.name || employee.value;
                }
                return null;
            }).filter(function (item) { return item; }).join('，');
        }, renderPreview: function (values) {
            var list = values;
            if (props.mode === 'single') {
                if (!Array.isArray(values)) {
                    list = [values];
                }
            }
            return (React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, "".concat(clsPrefix, "-readonly")) }, processDataSource(list).map(function (item) { return (React.createElement(CnEmployeeItem, { data: item, mini: true })); })));
        }, isPreview: readOnly || isPreview, filterLocal: false, showSearch: true, dataSource: (employeeData === null || employeeData === void 0 ? void 0 : employeeData.searchDataSource) || (employeeData === null || employeeData === void 0 ? void 0 : employeeData.dataSource), renderItem: function (item) { return React.createElement(CnEmployeeItem, { data: item }); }, onCancel: clearSearchDataSource, onChange: function (value, actionType, items) {
            var _a;
            clearSearchDataSource();
            if (!isFunction(onChange))
                return;
            if (props.useDetailValue) {
                var valueItem = ((_a = employeeData === null || employeeData === void 0 ? void 0 : employeeData.dataSource) === null || _a === void 0 ? void 0 : _a.find(function (i) { return i.value === value.value; })) || value;
                onChange(valueItem, actionType, items);
            }
            else {
                onChange(value, actionType, items);
            }
        }, value: formattedValue }, others)));
};
CnEmployeeSelect.defaultProps = {
    mokelay: true,
    typeList: ['REGULAR', 'OUTSOURCING'],
    isOnJob: true,
    valuePaddingZero: true,
};
