function itemFilter(searchValue, path) {
    return path.some(function (item) { return String(item.label).toLowerCase().indexOf(String(searchValue).toLowerCase()) > -1; });
}
function getPath(itemCache, pos) {
    var items = [];
    var nums = pos.split('-');
    if (nums === 2) {
        items.push(itemCache[pos]);
    }
    else {
        for (var i = 1; i < nums.length; i++) {
            var p = nums.slice(0, i + 1).join('-');
            items.push(itemCache[p]);
        }
    }
    return items;
}
export function cascaderFilter(itemCache, searchText, filterLocal) {
    if (!searchText)
        return [];
    var items = Object.keys(itemCache)
        .map(function (p) { return itemCache[p]; })
        .filter(function (item) { return !item.children || !item.children.length; });
    var result = items
        .map(function (item) { return getPath(itemCache, item.pos); })
        .filter(function (path) { return (filterLocal ? itemFilter(searchText, path) : true); });
    return result;
}
