import { __assign, __rest } from "tslib";
import $i18n from 'panda-i18n';
import React, { forwardRef, useEffect, useRef, useState, useCallback, } from 'react';
import isString from 'lodash/isString';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
import { CnLoading } from '../cn-loading/loading';
import { CnIcon } from '../cn-icon';
import { CnDialog } from '../cn-dialog';
import { useValue } from '../../utils/use-value';
var clsPrefix = 'cn-ui-m-message';
var ICON_MAP = {
    success: 'complete-r-fill',
    warning: 'exclamation-t-fill',
    error: 'error-r-fill',
    notice: 'exclamation-r-fill',
    help: 'help-fill',
    loading: '',
};
var TOAST_ICON_MAP = {
    success: 'complete-r',
    warning: 'exclamation-r',
    error: 'error-r',
    notice: '',
    help: 'help',
    loading: '',
};
var Message = function (props, ref) {
    var _a, _b, _c, _d, _e;
    var className = props.className, _f = props.shape, shape = _f === void 0 ? 'inline' : _f, _g = props.type, type = _g === void 0 ? 'notice' : _g, visibleProp = props.visible, _h = props.defaultVisible, defaultVisible = _h === void 0 ? true : _h, title = props.title, iconType = props.iconType, _j = props.closable, closable = _j === void 0 ? false : _j, children = props.children, zIndex = props.zIndex, style = props.style, _k = props.showType, showType = _k === void 0 ? 'beyond' : _k, onClose = props.onClose, onClick = props.onClick, others = __rest(props, ["className", "shape", "type", "visible", "defaultVisible", "title", "iconType", "closable", "children", "zIndex", "style", "showType", "onClose", "onClick"]);
    var contentRef = useRef();
    var _l = useState(false), isOverflow = _l[0], setOverflow = _l[1];
    useEffect(function () {
        var _a, _b;
        if (!contentRef.current)
            return;
        if (shape === 'toast' || !children) {
            setOverflow(false);
            return;
        }
        var contentStyle = getComputedStyle(contentRef.current);
        var lineHeight = Number((_a = contentStyle === null || contentStyle === void 0 ? void 0 : contentStyle.lineHeight) === null || _a === void 0 ? void 0 : _a.replace('px', '')) || 21;
        var height = Number((_b = contentStyle === null || contentStyle === void 0 ? void 0 : contentStyle.height) === null || _b === void 0 ? void 0 : _b.replace('px', ''));
        if (height - 10 > lineHeight * 3) {
            setOverflow(true);
        }
        else {
            setOverflow(false);
        }
    }, [shape, children, className]);
    var handleMessageClick = useCallback(function () {
        if (!isOverflow || showType === 'multiple') {
            return;
        }
        CnDialog.notice({
            title: $i18n.get({ id: 'Hint', dm: '提示', ns: 'CnMessage' }),
            content: children,
            className: "".concat(clsPrefix, "-full-notice-wrap"),
            okProps: {
                text: $i18n.get({
                    id: '31255237377527808.CNTM',
                    dm: '知道了',
                    ns: 'CnMessage',
                }),
                type: 'primary',
            },
        });
    }, [isOverflow, children]);
    var newStyle = __assign({ zIndex: zIndex }, style);
    var _m = useValue(props, defaultVisible, {
        valueName: 'visible',
    }), visible = _m[0], setVisible = _m[1], isControlled = _m[2];
    if (!visible) {
        return null;
    }
    var toastContent = title || children;
    var isToast = shape === 'toast';
    var hideIcon = (iconType === null ||
        iconType === '' ||
        iconType === false ||
        (iconType === undefined && !ICON_MAP[type])) &&
        type !== 'loading';
    // const hideIcon = isToast ? hideToastIcon : hideInlineIcon;
    var isSimpleMode = shape === 'inline' && !title && children;
    var handleClose = function () {
        if (!isControlled) {
            setVisible(false);
        }
        if (isFunction(onClose)) {
            onClose();
        }
    };
    var mappedIconType = shape === 'toast' ? TOAST_ICON_MAP[type] : ICON_MAP[type];
    return (React.createElement("div", __assign({ ref: ref, className: classNames(CN_UI_HASH_CLASS_NAME, clsPrefix, "".concat(clsPrefix, "--").concat(type), "".concat(clsPrefix, "--").concat(shape), "".concat(clsPrefix, "--").concat(showType), className, (_a = {},
            _a["".concat(clsPrefix, "--").concat(shape, "-").concat(type)] = shape && type,
            _a["".concat(clsPrefix, "--inline--no-title")] = shape === 'inline' && !toastContent,
            _a["".concat(clsPrefix, "--toast-with-icon")] = shape === 'toast' && !hideIcon,
            _a)), style: newStyle }, others),
        !hideIcon && (React.createElement("div", { className: "".concat(clsPrefix, "-icon-container--").concat(shape) }, type === 'loading' ? (React.createElement(CnLoading, { size: "large" })) : (React.createElement(CnIcon, { type: iconType || mappedIconType || type, outerClassName: classNames("".concat(clsPrefix, "-icon"), "".concat(clsPrefix, "-icon--").concat(type), "".concat(clsPrefix, "-icon--").concat(shape)) })))),
        React.createElement("div", { className: "".concat(clsPrefix, "-container ").concat(clsPrefix, "-container--").concat(shape) },
            title && !isToast && (React.createElement("div", { className: classNames("".concat(clsPrefix, "-title")) }, isString(title) ? (React.createElement("span", { className: classNames("".concat(clsPrefix, "-title"), (_b = {},
                    _b["".concat(clsPrefix, "-title--").concat(type)] = isSimpleMode,
                    _b)) }, title || children)) : (title))),
            React.createElement("div", { className: classNames("".concat(clsPrefix, "-content-container"), "".concat(clsPrefix, "-content-container--").concat(shape)) },
                isToast && isString(toastContent) ? (React.createElement("span", { className: classNames("".concat(clsPrefix, "-content"), "".concat(clsPrefix, "-content--").concat(shape)) }, toastContent)) : null,
                isToast && !isString(toastContent) ? toastContent : null,
                !isToast && children ? (React.createElement("div", { className: classNames("".concat(clsPrefix, "-content--inline"), (_c = {},
                        _c["".concat(clsPrefix, "-content--no-title")] = shape === 'inline' && !title,
                        _c["".concat(clsPrefix, "-content--inline-").concat(type)] = isSimpleMode,
                        _c["".concat(clsPrefix, "-content--inline-simple")] = isSimpleMode,
                        _c)), onClick: handleMessageClick, "data-testid": "CnMessage-overflow-click-btn" }, children)) : null,
                !isToast && children ? (React.createElement("div", { className: classNames("".concat(clsPrefix, "-content--inline-temp"), (_d = {},
                        _d["".concat(clsPrefix, "-content--no-title")] = shape === 'inline' && !title,
                        _d["".concat(clsPrefix, "-content--inline-").concat(type)] = isSimpleMode,
                        _d["".concat(clsPrefix, "-content--inline-simple")] = isSimpleMode,
                        _d)), 
                    // @ts-ignore
                    ref: contentRef }, children)) : null)),
        onClick && !isToast ? (React.createElement(CnIcon, { type: "triangle-right-fill", size: "small", outerClassName: classNames("".concat(clsPrefix, "-action"), "".concat(clsPrefix, "-close--").concat(type)), onClick: onClick, "data-testid": "CnMessage-click-btn" })) : null,
        closable && !isToast ? (React.createElement(CnIcon, { type: "close", size: "small", outerClassName: classNames("".concat(clsPrefix, "-close"), (_e = {},
                _e["".concat(clsPrefix, "-close--").concat(type)] = isSimpleMode,
                _e["".concat(clsPrefix, "-close-after-icon")] = onClick && !isToast,
                _e)), onClick: function (e) {
                e.stopPropagation();
                handleClose();
            } })) : null));
};
export var CnMessage = forwardRef(Message);
CnMessage.defaultProps = {
    shape: 'inline',
    type: 'notice',
    defaultVisible: true,
    closable: false,
    showType: 'beyond',
};
CnMessage.displayName = 'CnMessage';
