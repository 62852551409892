import { __extends } from "tslib";
import React from 'react';
var CnDrawerHeader = /** @class */ (function (_super) {
    __extends(CnDrawerHeader, _super);
    function CnDrawerHeader() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CnDrawerHeader.prototype.render = function () {
        return React.createElement("div", { className: "cn-ui-m-drawer-header" }, this.props.children);
    };
    CnDrawerHeader.__type = 'DrawerHeader';
    return CnDrawerHeader;
}(React.Component));
export { CnDrawerHeader };
