import { __assign } from "tslib";
import React from 'react';
import classNames from 'classnames';
import { CnIcon } from '@/components/cn-icon';
import { CnButton } from '@/components/cn-button';
import { formilyReact, formilyShared } from '@/form/formily';
import { useArray, useIndex } from '../hooks';
import { cssPrefix } from '../constant/css-prefix';
var clone = formilyShared.clone;
var useField = formilyReact.useField;
export var Copy = React.forwardRef(function (props, ref) {
    var _a;
    var _b;
    var index = useIndex(props.index);
    var self = useField();
    var array = useArray();
    if (!array)
        return null;
    if (((_b = array.field) === null || _b === void 0 ? void 0 : _b.pattern) !== 'editable')
        return null;
    return (React.createElement(CnButton, __assign({ type: "primary", text: true }, props, { ref: ref, onClick: function (e) {
            var _a, _b, _c, _d, _e;
            if (self === null || self === void 0 ? void 0 : self.disabled)
                return;
            e.stopPropagation();
            var value = clone((_a = array === null || array === void 0 ? void 0 : array.field) === null || _a === void 0 ? void 0 : _a.value[index]);
            var distIndex = index + 1;
            (_c = (_b = array.field) === null || _b === void 0 ? void 0 : _b.insert) === null || _c === void 0 ? void 0 : _c.call(_b, distIndex, value);
            (_e = (_d = array.props) === null || _d === void 0 ? void 0 : _d.onCopy) === null || _e === void 0 ? void 0 : _e.call(_d, distIndex);
            if (props.onClick) {
                props.onClick(e);
            }
        } }),
        React.createElement(CnIcon, { type: "copy", className: classNames(CN_UI_HASH_CLASS_NAME, "".concat(cssPrefix, "-copy"), (_a = {},
                _a["".concat(cssPrefix, "-copy-disabled")] = self === null || self === void 0 ? void 0 : self.disabled,
                _a)) })));
});
