import { __assign, __rest, __spreadArray } from "tslib";
import $i18n from 'panda-i18n';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { SelectDrawer } from '@/components/select-drawer';
import { CnEmbedPicker } from './embed-picker';
import { useLatest } from 'ahooks';
import dayjs from 'dayjs';
import isFunction from 'lodash/isFunction';
import { DrawerTab } from './drawer-tab';
import { CnMessage } from '@/components/cn-message';
import { dateToDayjs } from '../utils';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
/**
 * 比较两个时间的大小
 * @param dates
 * @returns
 */
export function compareTime(dates) {
    if (!dates || dates.length !== 2) {
        return false;
    }
    var start = dayjs(dates[0]);
    var end = dayjs(dates[1]);
    var isSameOrAfter = end.isSame(start) || end.isAfter(start);
    return isSameOrAfter;
}
function getInitValue(initValue, format) {
    return Array.isArray(initValue) ? initValue.map(function (i) { return dateToDayjs(i, format); }) : undefined;
}
export var CnRangeTimePickerPro = function (props) {
    var hasClear = props.hasClear, valueProp = props.value, alignProp = props.align, sizeProp = props.size, iconType = props.iconType, placeholder = props.placeholder, disabled = props.disabled, readOnly = props.readOnly, insideForm = props.insideForm, className = props.className, zIndex = props.zIndex, format = props.format, outputFormat = props.outputFormat, defaultValue = props.defaultValue, onChange = props.onChange, onCancel = props.onCancel, onClear = props.onClear, prefix = props.prefix, others = __rest(props, ["hasClear", "value", "align", "size", "iconType", "placeholder", "disabled", "readOnly", "insideForm", "className", "zIndex", "format", "outputFormat", "defaultValue", "onChange", "onCancel", "onClear", "prefix"]);
    var _a = useState('begin'), activeKey = _a[0], setActiveKey = _a[1];
    var _b = useState(getInitValue(valueProp || defaultValue, format)), value = _b[0], setValue = _b[1];
    var _c = useState(null), formatValueStr = _c[0], setFormatValueStr = _c[1];
    var _d = useState(value || [new Date(), new Date()]), pickerValue = _d[0], setPickerValue = _d[1];
    var _e = useState(null), pickerFormatValueStr = _e[0], setPickerFormatValueStr = _e[1];
    var ouputFormatRef = useLatest(outputFormat);
    var align = alignProp || 'left';
    var size = sizeProp || 'medium';
    var isControlled = 'value' in props;
    useEffect(function () {
        if (isControlled) {
            var newValue = getInitValue(valueProp || defaultValue, format);
            if (!newValue || (Array.isArray(newValue) && newValue.every(function (i) { return !i; }))) {
                setPickerValue([new Date(), new Date()]);
                setValue(undefined);
                setPickerFormatValueStr(null);
                setFormatValueStr(null);
                return;
            }
            else if (newValue === value) {
                return;
            }
            setValue(newValue);
            setPickerValue(newValue);
        }
    }, [valueProp, isControlled]);
    var handleCancel = function (reason) {
        setPickerValue(value || [new Date(), new Date()]);
        if (isFunction(onCancel)) {
            onCancel(reason);
        }
        setActiveKey('begin');
    };
    var handleClear = function () {
        if (!isControlled) {
            setPickerValue([new Date(), new Date()]);
            setValue(undefined);
            setFormatValueStr(null);
            setPickerFormatValueStr(null);
        }
        if (isFunction(onClear)) {
            onClear();
        }
        if (onChange) {
            onChange([undefined, undefined], ['', '']);
            setPickerValue([new Date(), new Date()]);
        }
    };
    var getFormated = useCallback(function (newValue) {
        if (!Array.isArray(newValue))
            return undefined;
        return newValue.map(function (i) {
            return dayjs(i).format(format);
        });
    }, [format]);
    var handleOk = useCallback(function () {
        var newValue = pickerValue;
        if (!newValue)
            return;
        if (!isCorrect) {
            CnMessage.error({
                content: $i18n.get({
                    id: 'TheStartTimeMustBeEarlierThanThe',
                    dm: '开始时间必须早于截止时间',
                    ns: 'CnTimePickerPro',
                }),
            });
            return false;
        }
        var dayjsValue = newValue.map(function (i) { return dayjs(i); });
        var dayjsFormatValue = dayjsValue === null || dayjsValue === void 0 ? void 0 : dayjsValue.map(function (item) { return item.format(format || 'HH:mm:ss'); });
        var v = (ouputFormatRef === null || ouputFormatRef === void 0 ? void 0 : ouputFormatRef.current)
            ? dayjsValue === null || dayjsValue === void 0 ? void 0 : dayjsValue.map(function (item) { return item.format(ouputFormatRef === null || ouputFormatRef === void 0 ? void 0 : ouputFormatRef.current); })
            : dayjsValue === null || dayjsValue === void 0 ? void 0 : dayjsValue.map(function (item) { return item === null || item === void 0 ? void 0 : item.valueOf(); });
        onChange === null || onChange === void 0 ? void 0 : onChange(v, dayjsFormatValue);
        if (!isControlled) {
            setValue(newValue);
            setFormatValueStr(pickerFormatValueStr);
        }
        setActiveKey('begin');
    }, [pickerValue, getFormated, ouputFormatRef, pickerFormatValueStr]);
    var content = useMemo(function () {
        var formatted = formatValueStr ||
            (format
                ? getFormated(value)
                : value === null || value === void 0 ? void 0 : value.map(function (item) { var _a; return (_a = dayjs(item)) === null || _a === void 0 ? void 0 : _a.format(format || 'HH:mm:ss'); }));
        return Array.isArray(formatted) ? formatted.join(' - ') : null;
    }, [value, getFormated, formatValueStr]);
    var isCorrect = useMemo(function () {
        return compareTime(pickerValue);
    }, [pickerValue]);
    if (readOnly) {
        return React.createElement("span", { className: "cn-ui-m-date-range-picker-pro-preview" }, content);
    }
    var handleChange = function (d, index) {
        var newPickerValue = Array.isArray(pickerValue) ? __spreadArray([], pickerValue, true) : [];
        newPickerValue[index] = d;
        setPickerValue(newPickerValue);
    };
    return withNativeProps(props, React.createElement(SelectDrawer, { disableScroll: true, hasClear: hasClear, content: content, align: align, size: size, iconType: iconType, placeholder: placeholder, disabled: disabled, readOnly: readOnly, insideForm: insideForm, className: className, 
        // drawerClassName="cn-ui-m-date-range-picker-pro-drawer"
        onOk: handleOk, onCancel: handleCancel, onClear: handleClear, buttonPosition: "bottom" },
        React.createElement(DrawerTab, { activeKey: activeKey, data: pickerValue, onTabChange: setActiveKey, isCorrect: isCorrect, format: format || 'HH:mm:ss' }),
        activeKey === 'begin' ? (React.createElement(CnEmbedPicker, __assign({}, others, { prefix: prefix, format: format || 'HH:mm:ss', value: (pickerValue === null || pickerValue === void 0 ? void 0 : pickerValue.length) ? pickerValue[0] : undefined, onChange: function (v) { return handleChange(v, 0); } }))) : (React.createElement(CnEmbedPicker, __assign({}, others, { prefix: prefix, format: format || 'HH:mm:ss', value: (pickerValue === null || pickerValue === void 0 ? void 0 : pickerValue.length) > 1 ? pickerValue[1] : undefined, onChange: function (v) { return handleChange(v, 1); } })))));
};
CnRangeTimePickerPro.displayName = 'CnRangeTimePickerPro';
