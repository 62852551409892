import { __assign, __awaiter, __generator } from "tslib";
import { getBoundingClientRect } from 'universal-element';
import { isStrictWechatMiniProgram } from './env';
/**
 * 获取指定元素的 rect 值
 * @param id  格式为： `#id`
 */
export function getElementBoundingClientRect(id) {
    return __awaiter(this, void 0, void 0, function () {
        var getRect, rs;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    getRect = getBoundingClientRect;
                    if (isStrictWechatMiniProgram) {
                        getRect = function (selector) {
                            return new Promise(function (resolve) {
                                var ele = document.querySelector(selector);
                                if (!ele || !ele._internal) {
                                    if (process.env.NODE_ENV === 'development') {
                                        console.warn('element not found', selector);
                                    }
                                    resolve([]);
                                }
                                ele._internal
                                    .createSelectorQuery()
                                    .select(selector)
                                    .boundingClientRect()
                                    .exec(function (res) {
                                    resolve(res);
                                });
                            });
                        };
                    }
                    return [4 /*yield*/, getRect("#".concat(id)).then(function (ret) {
                            var rect = {
                                left: 0,
                                bottom: 0,
                                top: 0,
                                right: 0,
                                width: 0,
                                height: 0,
                                x: 0,
                                y: 0,
                            };
                            if (ret && ret[0]) {
                                var _a = ret[0], left = _a.left, bottom = _a.bottom, top = _a.top, right = _a.right, width = _a.width, height = _a.height, x = _a.x, y = _a.y;
                                return __assign(__assign({}, rect), { left: left, bottom: bottom, top: top, right: right, width: width, height: height, x: x, y: y });
                            }
                            return rect;
                        })];
                case 1:
                    rs = _a.sent();
                    return [2 /*return*/, rs];
            }
        });
    });
}
/**
 * 根据指定 Id 获取元素宽度
 * @param id
 */
export function getWidth(id) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getElementBoundingClientRect(id)];
                case 1: return [2 /*return*/, (_a.sent()).width];
            }
        });
    });
}
