export var DynamicFormSchemaMap = {
    initialValue: 'default',
    display: 'x-display',
    component: 'x-component',
    uipattern: 'x-pattern',
    dataSource: 'enum',
    reactions: 'x-reactions',
    validator: 'x-validator',
    index: 'x-index',
    decorator: 'x-decorator',
    content: 'x-content',
    visible: 'x-visible',
    hidden: 'x-hidden',
    disabled: 'x-disabled',
    editable: 'x-editable',
    readOnly: 'x-read-only',
    readPretty: 'x-read-pretty',
    data: 'x-data',
    'decorator-props': 'x-decorator-props',
    decoratorProps: 'x-decorator-props',
    xDecoratorProps: 'x-decorator-props',
    'component-props': 'x-component-props',
    componentProps: 'x-component-props',
    xComponentProps: 'x-component-props',
};
