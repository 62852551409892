import { __assign } from "tslib";
import * as React from 'react';
import omit from 'lodash/omit';
import { HocBaseComponents, Plugin, pluginManager } from "../../../utils";
import { registerFormat, getAllSupportFormat } from '../register';
var CnFormatFallback = function (props) {
    var cell = props.cell;
    if (cell) {
        return cell(props.value, omit(props, ['cell', 'value']));
    }
    return null;
};
export var CnFormat = function (props) {
    var plugin = React.useMemo(function () {
        var pluginInstance = new Plugin('CnFormat', pluginManager);
        pluginInstance.setBasicsPlugin([
            function (upperProps, context) {
                return {
                    props: upperProps,
                    context: __assign({}, context),
                };
            },
        ]);
        pluginInstance.setLocalPlugin(props === null || props === void 0 ? void 0 : props.usePlugin);
        pluginInstance.setGlobalPlugin(pluginManager
            .getPluginsByComponentName('CnFormat')
            .map(function (item) { return item.method; }));
        return pluginInstance;
    }, []);
    // 获取到的全量插件
    var plugins = plugin.getPlugin();
    // 传入下层
    return HocBaseComponents(CnFormatFallback, { props: props, plugins: plugins });
};
CnFormat.defaultProps = {
    type: 'CnInput',
};
CnFormat.displayName = 'CnFormat';
CnFormat.registerFormat = registerFormat;
CnFormat.getAllSupportFormat = getAllSupportFormat;
