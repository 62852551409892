import { __assign, __rest } from "tslib";
import * as React from 'react';
import cx from 'classnames';
import { observer } from '@formily/react';
import { CnCard as PureCnCard } from '@/components/cn-card';
import './index.scss';
export var CnCard = observer(function (props) {
    var children = props.children, otherProps = __rest(props, ["children"]);
    return React.createElement(PureCnCard, __assign({}, otherProps), children);
});
export var CnCardSubCard = observer(function (props) {
    var className = props.className, children = props.children, otherProps = __rest(props, ["className", "children"]);
    return (React.createElement(PureCnCard, __assign({ className: cx(CN_UI_HASH_CLASS_NAME, className, 'cn-ui-onecode-sub-card') }, otherProps), children));
});
