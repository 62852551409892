import * as React from 'react';
export var getStepCurrent = function (props) {
    var current = props.current, children = props.children;
    var childCount = React.Children.count(children);
    var newCurrent = current;
    if (isNaN(current) || current < -1) {
        newCurrent = -1;
    }
    else if (current > childCount) {
        newCurrent = childCount - 1;
    }
    return newCurrent;
};
