/**
 * 根据format和showTime映射出precision
 * @param format 格式化字符串
 * @param showTime 是否显示时间
 * @returns
 */
export var getPrecisionFromFormat = function (format, showTime) {
    if (showTime) {
        if (format === 'YYYY-MM-DD HH:mm') {
            return 'minute';
        }
        if (format === 'YYYY-MM-DD HH') {
            return 'hour';
        }
        return 'second';
    }
    if (format === 'YYYY-MM') {
        return 'month';
    }
    if (format === 'YYYY') {
        return 'year';
    }
    return 'day';
};
