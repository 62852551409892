import * as React from 'react';
import { CnStatusTag } from '@/components/cn-tag';
import { CnBox } from '@/components/cn-box';
var tagStatusList = [
    'info',
    'success',
    'error',
    'warning',
    'invalid',
];
var getTagStatus = function (status) {
    if (tagStatusList.includes(status))
        return status;
    return 'info';
};
export var FormatTag = function (props) {
    var mode = props.mode, value = props.value;
    if (!value)
        return React.createElement(React.Fragment, null, "- -");
    if (Array.isArray(value)) {
        var showNum = mode === 'simple' ? 3 : value.length;
        var hasMore = mode === 'simple' && value.length > showNum;
        return (React.createElement(CnBox, { className: "cn-ui-m-card-items-format-tag", direction: "row", wrap: true, spacing: 4 },
            value.slice(0, showNum).map(function (item) { return (React.createElement(CnStatusTag, { status: getTagStatus(item.state) }, item.value)); }),
            hasMore ? (React.createElement("div", { className: "cn-ui-m-card-items-format-more" },
                showNum,
                "+")) : null));
    }
    if (typeof value === 'object') {
        return (React.createElement(CnStatusTag, { status: getTagStatus(value.state) }, value.value));
    }
    return React.createElement(CnStatusTag, { status: "info" }, value);
};
