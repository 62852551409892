import { __assign } from "tslib";
import React, { useEffect, useMemo, useState } from 'react';
import { useCnRequest } from '@cainiaofe/cn-ui-common';
import { useLatest } from 'ahooks';
import isNil from 'lodash/isNil';
export var FormatEnumeration = function (props) {
    var _a;
    var value = props.value, _b = props.column, column = _b === void 0 ? {} : _b;
    var _c = column.options, options = _c === void 0 ? {} : _c;
    var dataSourceProps = options.dataSource, requestConfig = options.requestConfig;
    var _d = useState([]), dataSource = _d[0], setDataSource = _d[1];
    var _e = useState(!dataSourceProps), loading = _e[0], setLoading = _e[1];
    var requestConfigRef = useLatest(requestConfig);
    var runAsync = useCnRequest(__assign({ manual: true, cacheKey: "".concat(requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.url, "+").concat(requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.method, "+").concat(JSON.stringify((requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.params) || {}), "+").concat(JSON.stringify((requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.data) || {})), staleTime: 5000 }, requestConfig)).runAsync;
    useEffect(function () {
        if (dataSourceProps || !value || !requestConfig) {
            setLoading(false);
            return;
        }
        var config = requestConfigRef.current;
        if (config) {
            runAsync()
                .then(function (data) { return setDataSource(data); })
                .finally(function () { return setLoading(false); });
        }
    }, [dataSourceProps, requestConfigRef, runAsync, value]);
    var result = useMemo(function () {
        var _a;
        if (isNil(value))
            return;
        var list = dataSourceProps || dataSource;
        if (Array.isArray(list)) {
            if (Array.isArray(value)) {
                if (value.length === 0)
                    return React.createElement(React.Fragment, null, '- -');
                return value
                    .map(function (valueItem) { var _a; return (_a = list.find(function (item) { return item.value === valueItem; })) === null || _a === void 0 ? void 0 : _a.label; })
                    .filter(Boolean)
                    .join(',');
            }
            return (_a = list.find(function (item) { return item.value === value; })) === null || _a === void 0 ? void 0 : _a.label;
        }
        return null;
    }, [dataSource, dataSourceProps, value]);
    if (loading)
        return null;
    return React.createElement(React.Fragment, null, (_a = result !== null && result !== void 0 ? result : value) !== null && _a !== void 0 ? _a : '- -');
};
