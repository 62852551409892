import dayjs from 'dayjs';
/**
 * 根据mode将日期格式转string格式
 * @param date 日期格式
 * @param mode 日期模式
 * @returns string格式
 */
export function date2StringByMode(date, mode, format) {
    try {
        var res = '';
        if (dayjs(date).isValid() === false) {
            throw new Error('Invalid date');
        }
        if (format) {
            res = dayjs(date).format(format);
        }
        else {
            switch (mode) {
                case 'year':
                    res = dayjs(date).format('YYYY');
                    break;
                case 'month':
                    res = dayjs(date).format('YYYY-MM');
                    break;
                case 'quarter':
                    res = "".concat(dayjs(date).format('YYYY'), "-Q").concat(dayjs(date).format('Q'));
                    break;
                default:
                    break;
            }
        }
        return res;
    }
    catch (error) {
        throw new Error(error);
    }
}
