import * as React from 'react';
import classNames from 'classnames';
import { CnIcon } from '@/components/cn-icon';
import { clsPrefix } from '../../constant';
/**
 * 右侧状态icon
 */
export default function RightStateIcon(props) {
    var _a = props.canTogglePasswordShow, canTogglePasswordShow = _a === void 0 ? true : _a, onClear = props.onClear, defaultTextType = props.defaultTextType, textType = props.textType, setTextType = props.setTextType, isPassword = props.isPassword, state = props.state, hasClear = props.hasClear, setValue = props.setValue;
    var className = classNames("".concat(clsPrefix, "--icon"), "".concat(clsPrefix, "--icon-").concat(hasClear ? 'clear' : state));
    if (hasClear) {
        // 删除 icon
        return (React.createElement(CnIcon, { type: "error-r-fill", size: "small", outerClassName: className, onClick: function () {
                setValue(undefined);
                onClear === null || onClear === void 0 ? void 0 : onClear();
            } }));
    }
    if (canTogglePasswordShow && isPassword) {
        // 密码可切换显示隐藏
        return (React.createElement(CnIcon, { type: textType === 'password' ? 'eye-close-fill' : 'eye-fill', size: "small", outerClassName: className, onClick: function () {
                setTextType(textType === 'password' ? 'text' : defaultTextType);
            } }));
    }
    if (state === 'loading') {
        return React.createElement(CnIcon, { type: "loading", size: "small", outerClassName: className });
    }
    if (state === 'warning') {
        return (React.createElement(CnIcon, { type: "exclamation-t-fill", size: "small", outerClassName: className }));
    }
    if (state === 'success') {
        return (React.createElement(CnIcon, { type: "complete-r-fill", size: "small", outerClassName: className }));
    }
    if (state === 'error') {
        return (React.createElement(CnIcon, { type: "error-r-fill", size: "small", outerClassName: className }));
    }
    return null;
}
