export function pickerToDate(values, options) {
    var hasHour = options.hasHour, hasMinute = options.hasMinute, hasSecond = options.hasSecond, use12Hours = options.use12Hours;
    var d = new Date();
    // 如果有相关选项，则设置值。否则重置回0
    if (hasHour) {
        var h = values.shift();
        if (use12Hours) {
            var isAm = values.pop() === 'am';
            if (isAm) {
                d.setHours(h);
            }
            else {
                d.setHours(h + 12);
            }
        }
        else {
            d.setHours(h);
        }
    }
    else {
        d.setHours(0);
    }
    if (hasMinute) {
        d.setMinutes(values.shift());
    }
    else {
        d.setMinutes(0);
    }
    if (hasSecond) {
        d.setSeconds(values.shift());
    }
    else {
        d.setSeconds(0);
    }
    d.setMilliseconds(0);
    return d;
}
