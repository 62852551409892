import { __assign } from "tslib";
import React from 'react';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { SchemaField } from '@/form/schema-field';
/**
 * 快捷筛选容器
 * @param
 * @returns
 */
export function QuickDeractor(_a) {
    var children = _a.children;
    return (React.createElement("div", { className: "cn-ui-m-filter-quick-filter-wrapper" },
        React.createElement("div", { className: "cn-ui-m-filter-quick-filter" }, children)));
}
/**
 * 次级快捷筛选容器
 * @param
 * @returns
 */
export function NoWrapQuickDeractor(_a) {
    var children = _a.children;
    return (React.createElement("div", { className: "cn-ui-m-filter-quick-filter-no-warp-wrapper" },
        React.createElement("div", { className: "cn-ui-m-filter-quick-filter-no-warp" }, children)));
}
/**
 * 次级快捷筛选容器-卡片
 * @param
 * @returns
 */
export function NoWrapQuickInCardDeractor(_a) {
    var children = _a.children;
    return (React.createElement("div", { className: "cn-ui-m-filter-quick-filter-no-warp-wrapper" },
        React.createElement("div", { className: "cn-ui-m-filter-quick-filter-no-warp cn-ui-m-filter-quick-filter-no-warp-card-padding" }, children)));
}
/**
 * 次级快捷筛选容器-卡片标题区域
 * @param
 * @returns
 */
export function NoWrapQuickInCardSubActionDeractor(_a) {
    var children = _a.children;
    return React.createElement("div", { className: "cn-ui-m-filter-quick-filter-card-sub-action-wrapper" }, children);
}
export function SearchDeractor(_a) {
    var children = _a.children;
    return React.createElement("div", { className: "cn-ui-m-filter-search" }, children);
}
/**
 * 获取快捷筛选容器
 * @param
 * @returns
 */
export function getQuickDecorator(isInCard, isInCardSubAction) {
    if (!isInCard) {
        return NoWrapQuickDeractor;
    }
    if (isInCardSubAction) {
        return NoWrapQuickInCardSubActionDeractor;
    }
    return NoWrapQuickInCardDeractor;
}
/**
 * shema分区域处理
 * @param schema
 * @returns
 */
export var useSplitSchema = function (param) {
    var schema = param.schema, components = param.components, isInCard = param.isInCard, isInCardSubAction = param.isInCardSubAction;
    var _a = React.useState([null, null, null, null]), result = _a[0], setResult = _a[1];
    React.useEffect(function () {
        var copySchema = cloneDeep(schema);
        var _a = (copySchema || {}).properties, properties = _a === void 0 ? {} : _a;
        var quickSchema = [];
        var quickNoWrapSchema = [];
        var normalFilterSchema = [];
        var searchSchema = [];
        Object.keys(properties).forEach(function (key) {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            var _j;
            var item = properties[key];
            var componentName = item['x-component'];
            var decoratorProps = item['x-decorator-props'];
            var quick = get(decoratorProps, 'quick', false);
            if (!quick) {
                if (componentName === 'CnSearch') {
                    var curItem = __assign({}, item);
                    delete curItem.title;
                    curItem['x-component-props'] = __assign(__assign({}, (curItem['x-component-props'] || {})), { cnBoxProps: { wrap: false } });
                    searchSchema.push((_a = {}, _a[key] = curItem, _a));
                }
                else {
                    normalFilterSchema.push((_b = {}, _b[key] = item, _b));
                }
                return;
            }
            // 黑名单模式，解决不支持快捷筛选的组件配置quick后不显示的问题
            if (['CnInput', 'CnSwitch', 'CnInputTextArea', 'CnNumberPicker'].includes(componentName)) {
                normalFilterSchema.push((_c = {}, _c[key] = item, _c));
                return;
            }
            // CnSelector放置到次级快捷筛选区
            if (componentName === 'CnSelector') {
                var curItem = __assign(__assign({}, item), { 'x-decorator': getQuickDecorator(isInCard, isInCardSubAction) });
                delete curItem.title;
                curItem['x-component-props'] = __assign(__assign({}, (curItem['x-component-props'] || {})), { cnBoxProps: { wrap: false } });
                quickNoWrapSchema.push((_d = {}, _d[key] = curItem, _d));
            }
            else if (componentName === 'CnCheckboxGroup') {
                var curItem = __assign(__assign({}, item), { 'x-decorator': getQuickDecorator(isInCard, isInCardSubAction) });
                delete curItem.title;
                curItem['x-component-props'] = __assign(__assign({}, (curItem['x-component-props'] || {})), { shape: 'tag', direction: 'hoz' });
                quickNoWrapSchema.push((_e = {}, _e[key] = curItem, _e));
                // CnRadioGroup放置到次级快捷筛选区
            }
            else if (componentName === 'CnRadioGroup' || componentName === 'RadioGroup') {
                var curItem = __assign(__assign({}, item), { 'x-decorator': getQuickDecorator(isInCard, isInCardSubAction) });
                delete curItem.title;
                curItem['x-component-props'] = __assign(__assign({}, (curItem['x-component-props'] || {})), { shape: ((_j = item['x-component-props']) === null || _j === void 0 ? void 0 : _j.shape) || 'tag', direction: 'hoz' });
                quickNoWrapSchema.push((_f = {}, _f[key] = curItem, _f));
                // CnDatePicker放置到快捷筛选区
            }
            else if ([
                'CnDatePicker',
                'CnDatePickerPro',
                'CnMonthPickerPro',
                'CnYearPickerPro',
                'CnWeekPickerPro',
                'CnRangeDatePickerPro',
                'CnDateRangePickerPro',
            ].includes(componentName)) {
                var curItem = __assign({}, item);
                delete curItem.title;
                curItem['x-component-props'] = __assign(__assign({}, (curItem['x-component-props'] || {})), { placeholder: (item === null || item === void 0 ? void 0 : item.label) || (item === null || item === void 0 ? void 0 : item.title), insideFilter: true });
                quickSchema.push((_g = {}, _g[key] = curItem, _g));
                // 其他放置到快捷筛选区
            }
            else {
                var curItem = __assign({}, item);
                delete curItem.title;
                curItem['x-component-props'] = __assign(__assign({}, (curItem['x-component-props'] || {})), { placeholder: (item === null || item === void 0 ? void 0 : item.label) || (item === null || item === void 0 ? void 0 : item.title), insideFilter: true });
                quickSchema.push((_h = {}, _h[key] = curItem, _h));
            }
        });
        var quickChild = quickSchema === null || quickSchema === void 0 ? void 0 : quickSchema.map(function (item) {
            return (React.createElement(QuickDeractor, null,
                React.createElement(SchemaField, { key: item.key, components: components, schema: {
                        type: 'object',
                        properties: item,
                    } })));
        });
        var quickNoWrapChild = quickNoWrapSchema === null || quickNoWrapSchema === void 0 ? void 0 : quickNoWrapSchema.map(function (item) {
            return (React.createElement(SchemaField, { key: item.key, components: components, schema: {
                    type: 'object',
                    properties: item,
                } }));
        });
        var normalFilterChild = normalFilterSchema === null || normalFilterSchema === void 0 ? void 0 : normalFilterSchema.map(function (item) {
            return (React.createElement(SchemaField, { key: item.key, components: components, schema: {
                    type: 'object',
                    properties: item,
                } }));
        });
        var searchChild = searchSchema === null || searchSchema === void 0 ? void 0 : searchSchema.map(function (item) {
            return (React.createElement(SearchDeractor, null,
                React.createElement(SchemaField, { key: item.key, components: components, schema: {
                        type: 'object',
                        properties: item,
                    } })));
        });
        if (quickChild.length === 0 && quickNoWrapChild.length !== 0) {
            var item = quickNoWrapChild.shift();
            quickChild.push(item);
        }
        setResult([quickChild, quickNoWrapChild, normalFilterChild, searchChild]);
    }, [schema, isInCard, components]);
    return result;
};
