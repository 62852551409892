import * as React from 'react';
export var getComponentName = function (component) {
    if (!React.isValidElement(component))
        return undefined;
    try {
        var type = component.type;
        return type.displayName || type.name || undefined;
    }
    catch (e) {
        console.error(e);
    }
    return undefined;
};
