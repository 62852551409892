import React from 'react';
import $i18n from 'panda-i18n';
import { CnIcon } from '@/components/cn-icon';
import { RESULT_TYPE_MAP } from './constant';
export var getIcon = function (_icon, type) {
    var icon = _icon || (type && RESULT_TYPE_MAP($i18n)[type].icon);
    if (!icon)
        return null;
    if (icon.startsWith('http') || icon.startsWith('//')) {
        return (React.createElement("div", { className: "cn-result-img", style: { backgroundImage: "url(".concat(icon, ")") } }));
    }
    return (React.createElement("div", { className: "cn-result-icon" },
        React.createElement(CnIcon, { size: "xxxl", type: icon })));
};
