import { __assign, __rest, __spreadArray } from "tslib";
import $i18n from 'panda-i18n';
import React, { forwardRef, useCallback, useEffect, useState, } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { CnCalendar } from '@/components/cn-calendar';
import { SelectDrawer as CnSelectDrawer } from '@/components/select-drawer';
import { CnReadOnly } from '@/components/cn-read-only';
import isFunction from 'lodash/isFunction';
import { toDate } from '@/utils/date';
import { mergeProps } from '@/components/cn-picker/with-default-props';
import { useFormLayout } from '@/form/cn-form-layout';
import { formatValue, getFormatStrFromPrecision, getPrecisionFromFormat, getRangeStr, getValueFromDayjs, } from '../../utils';
import './date-picker.scss';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
var DateTimeCalendarPicker = function (props, ref) {
    var defaultProps = {
        placeholder: $i18n.get({
            id: 'PleaseSelect',
            dm: '请选择',
            ns: 'CnDatePickerPro',
        }),
        precision: 'second',
    };
    var wrapperAlign = useFormLayout().wrapperAlign;
    var _a = mergeProps(defaultProps, __assign(__assign({}, props), { precision: props.precision || getPrecisionFromFormat(props.format, props.showTime) })), drawerTitle = _a.drawerTitle, valueProp = _a.value, arrayValue = _a.arrayValue, arrayIndex = _a.arrayIndex, defaultValue = _a.defaultValue, sizeProp = _a.size, alignProp = _a.align, iconType = _a.iconType, placeholder = _a.placeholder, format = _a.format, outputFormat = _a.outputFormat, hasClear = _a.hasClear, disabled = _a.disabled, readOnly = _a.readOnly, _b = _a.onChange, onChange = _b === void 0 ? function () { } : _b, _c = _a.onClear, onClear = _c === void 0 ? function () { } : _c, _d = _a.onCancel, onCancel = _d === void 0 ? function () { } : _d, className = _a.className, zIndex = _a.zIndex, precision = _a.precision, mode = _a.mode, formLabel = _a.formLabel, insideFilter = _a.insideFilter, extraFooterRender = _a.extraFooterRender, others = __rest(_a, ["drawerTitle", "value", "arrayValue", "arrayIndex", "defaultValue", "size", "align", "iconType", "placeholder", "format", "outputFormat", "hasClear", "disabled", "readOnly", "onChange", "onClear", "onCancel", "className", "zIndex", "precision", "mode", "formLabel", "insideFilter", "extraFooterRender"]);
    var _e = useState(new Date()), pickerValue = _e[0], setPickerValue = _e[1];
    var align = alignProp || wrapperAlign || 'left';
    var size = sizeProp || 'medium';
    useEffect(function () {
        if (arrayValue[arrayIndex]) {
            setPickerValue(toDate(arrayValue[arrayIndex]));
        }
        // 当选中一个日期时，联动另一个日期的初始值
        if (arrayIndex === 0 && !arrayValue[0] && arrayValue[1]) {
            setPickerValue(toDate(arrayValue[1]));
        }
        if (arrayIndex === 1 && !arrayValue[1] && arrayValue[0]) {
            setPickerValue(toDate(arrayValue[0]));
        }
    }, [arrayValue]);
    var handleCancel = function (reason) {
        if (arrayValue[arrayIndex]) {
            var newValue = toDate(arrayValue[arrayIndex]);
            setPickerValue(newValue);
        }
        if (isFunction(onCancel)) {
            onCancel(reason);
        }
    };
    var handleClear = function () {
        if (onClear) {
            onClear();
        }
        if (onChange) {
            var newValue = __spreadArray([], arrayValue, true);
            newValue[arrayIndex] = null;
            onChange(newValue, getRangeStr(newValue, format));
        }
    };
    var getFormated = useCallback(function () {
        var _a, _b;
        var clsPrefix = 'cn-ui-m-selectdrawer';
        var newValue = arrayIndex === 0 ? arrayValue[0] : arrayValue[1];
        var contentFormat = newValue &&
            format &&
            (isFunction(format) ? format(dayjs(newValue)) : dayjs(newValue).format(format));
        var contentWithprecision = newValue && dayjs(newValue).format(getFormatStrFromPrecision(precision || 'second'));
        var showedContent = contentFormat || contentWithprecision || '';
        return (React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-m-date-picker-pro-date-time-range-item') },
            drawerTitle,
            showedContent ? (React.createElement("span", { className: classNames((_a = {},
                    _a["".concat(clsPrefix, "-content-text")] = true,
                    _a["".concat(clsPrefix, "-content-text--").concat(align)] = true,
                    _a["".concat(clsPrefix, "-content-text--").concat(size)] = size,
                    _a["".concat(clsPrefix, "-content-text--disabled")] = disabled,
                    _a)), style: {
                    textAlign: align,
                } }, showedContent)) : (React.createElement("div", { className: classNames((_b = {},
                    _b["".concat(clsPrefix, "-placeholder")] = true,
                    _b["".concat(clsPrefix, "-placeholder--").concat(align)] = true,
                    _b["".concat(clsPrefix, "-placeholder--disabled")] = disabled,
                    _b)) }, $i18n.get({
                id: 'PleaseSelect',
                dm: '请选择',
                ns: 'CnDatePickerPro',
            })))));
    }, [format, precision, drawerTitle, align, size, disabled, arrayValue, arrayIndex]);
    var handleOk = function () {
        var newValue = pickerValue;
        var processTime = !precision || ['year', 'month', 'day', 'week', 'week-day', 'quarter'].includes(precision);
        var v = outputFormat
            ? formatValue(dayjs(newValue), outputFormat)
            : getValueFromDayjs(dayjs(newValue), processTime, props.endOfDay);
        var vals = __spreadArray([], arrayValue, true);
        vals[arrayIndex] = v;
        onChange === null || onChange === void 0 ? void 0 : onChange(vals, getRangeStr(vals, format));
    };
    // showTime 时的兼容逻辑，不必写 timePanelProps format
    var timePanelProps = props.timePanelProps;
    if (props.showTime && props.format && typeof props.format === 'string') {
        var timeFormat = props.format.trim().split(/\s+/)[1];
        if (timeFormat) {
            timePanelProps = __assign({}, timePanelProps) || {};
            timePanelProps.format = timeFormat;
        }
    }
    if (readOnly) {
        return React.createElement(CnReadOnly, { value: getFormated() });
    }
    var getValidRange = function () {
        var maxDate = new Date(2023, 1, 1, 23, 59, 59);
        var minDate = new Date(2023, 1, 1, 0, 0, 0);
        if (arrayIndex === 0) {
            if (arrayValue[1]) {
                var endDate = dayjs(arrayValue[1]);
                if (endDate.isSame(dayjs(pickerValue), 'day')) {
                    maxDate = endDate.toDate();
                }
            }
        }
        else if (arrayValue[0]) {
            var startDate = dayjs(arrayValue[0]);
            if (startDate.isSame(dayjs(pickerValue), 'day')) {
                minDate = startDate.toDate();
            }
        }
        return [minDate, maxDate];
    };
    return withNativeProps(props, React.createElement(CnSelectDrawer, { drawerTitle: drawerTitle, disableScroll: true, hasClear: hasClear, content: getFormated(), showClear: function () {
            return !!arrayValue[arrayIndex];
        }, align: align, size: size, iconType: iconType, placeholder: placeholder, disabled: disabled, readOnly: readOnly, insideForm: props.insideForm, className: classNames('cn-ui-m-date-picker-pro'), drawerClassName: "cn-ui-m-date-picker-pro-drawer", zIndex: zIndex, onOk: handleOk, onCancel: handleCancel, onClear: handleClear, formLabel: formLabel, insideFilter: insideFilter, buttonPosition: "bottom", ref: ref },
        React.createElement(CnCalendar, __assign({}, others, { disabledDate: function (d) {
                var compareDate = dayjs(d);
                if (arrayIndex === 0) {
                    if (arrayValue[1]) {
                        var endDate = dayjs(arrayValue[1]);
                        return compareDate.isAfter(endDate);
                    }
                }
                else if (arrayValue[0]) {
                    var startDate = dayjs(arrayValue[0]);
                    return compareDate.isBefore(dayjs(startDate));
                }
            }, timePanelProps: __assign(__assign({}, timePanelProps), { validRange: getValidRange() }), selectionMode: "single", value: pickerValue, onChange: function (val) {
                setPickerValue(val);
            } })),
        extraFooterRender ? (React.createElement("div", { className: "cn-ui-m-date-picker-extra-footer" }, typeof extraFooterRender === 'function' ? extraFooterRender() : extraFooterRender)) : null));
};
DateTimeCalendarPicker.displayName = 'DateTimeCalendarPicker';
export default forwardRef(DateTimeCalendarPicker);
