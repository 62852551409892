import { __awaiter, __generator } from "tslib";
import React, { useState } from 'react';
import { CnListItem } from '@/components/cn-list';
import { CnCheckbox } from '@/components/cn-checkbox';
import { CnIcon } from '@/components/cn-icon';
function LoadingIcon(_a) {
    var visible = _a.visible;
    if (visible) {
        return (React.createElement(CnIcon, { className: "cn-ui-m-cascader-icon--loading", type: "progressing", size: "small" }));
    }
    return null;
}
export function CascaderListItem(props) {
    var _this = this;
    var _a, _b;
    var onClick = props.onClick, data = props.data, loadData = props.loadData, multiple = props.multiple, handleSelect = props.handleSelect;
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var loadingVisible = !data.isLeaf && !((_a = data.children) === null || _a === void 0 ? void 0 : _a.length) && Boolean(loadData);
    var meida = (React.createElement("div", { className: "cn-ui-m-cascader-list-item-left" },
        React.createElement(CnCheckbox, { className: "cn-ui-m-cascader-list-item-left-checkbox", checked: data.checked, disabled: data.disabled, indeterminate: data.indeterminate, onChange: function (checked, event) {
                // 阻止事件冒泡
                event.stopPropagation();
                event.preventDefault();
                handleSelect(checked, data);
            } }),
        React.createElement("div", { className: "cn-ui-m-cascader-list-item-left-empty" })));
    return (React.createElement(CnListItem, { key: data.value, className: data.checked || data.indeterminate
            ? 'cn-ui-m-cascader-list-item-checked'
            : '', title: data.label, extra: React.createElement(LoadingIcon, { visible: loading }), arrow: ((_b = data.children) === null || _b === void 0 ? void 0 : _b.length) || (loadData && !(data === null || data === void 0 ? void 0 : data.isLeaf))
            ? 'right'
            : undefined, disabled: data.disabled, media: multiple ? meida : null, onClick: function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(loadingVisible && loadData)) return [3 /*break*/, 2];
                        setLoading(true);
                        return [4 /*yield*/, loadData(data)];
                    case 1:
                        _a.sent();
                        setLoading(false);
                        _a.label = 2;
                    case 2:
                        onClick(data);
                        return [2 /*return*/];
                }
            });
        }); } }));
}
