import { __assign, __rest } from "tslib";
import React from 'react';
import { CnSelect } from '@/components/cn-select';
import RegionFlag from './region-flag';
import currencyMap from './currency-map';
import classNames from 'classnames';
var CnCurrencySelect = function (props) {
    var _a = props.currencyDataSource, currencyDataSource = _a === void 0 ? [] : _a, otherProps = __rest(props, ["currencyDataSource"]);
    var clsPrefix = 'cn-ui-m-currency-select';
    var renderItem = function (item) {
        var itemValue = item.value;
        var currencyDetail = currencyMap[itemValue];
        if (currencyDetail) {
            return (React.createElement("div", { className: "".concat(clsPrefix, "-selection-item") },
                React.createElement(RegionFlag, { value: currencyDetail.country }),
                React.createElement("div", { className: "".concat(clsPrefix, "-selection-label") }, "".concat(currencyDetail.value, " ").concat(currencyDetail.label))));
        }
        return (React.createElement("div", { className: "".concat(clsPrefix, "-selection-item") },
            React.createElement(RegionFlag, null),
            itemValue));
    };
    var renderSelection = function (opts) {
        if (!opts.length)
            return null;
        return (React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, "".concat(clsPrefix, "-selection")) }, opts.map(function (item) { return renderItem(item); })));
    };
    var renderPreview = function (values) {
        if (!values.length)
            return null;
        return (React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, "".concat(clsPrefix, "-selection")) }, values.map(function (item) { return renderItem(item); })));
    };
    return (React.createElement(CnSelect, __assign({ renderItem: renderItem, renderSelection: renderSelection, renderPreview: renderPreview, dataSource: currencyDataSource }, otherProps)));
};
export { CnCurrencySelect };
