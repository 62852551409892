import React from 'react';
import { FORM_EMPTY_CONTENT, useUploadState } from '@cainiaofe/cn-ui-common';
import { UploadListItem } from './upload-list-item';
export var UploadList = function (props) {
    var _a = useUploadState(), getValue = _a.getValue, readOnly = _a.readOnly;
    var fileList = getValue();
    if (!fileList.length) {
        if (readOnly) {
            return React.createElement(React.Fragment, null, FORM_EMPTY_CONTENT);
        }
        return null;
    }
    return (React.createElement(React.Fragment, null, fileList.map(function (item, index) { return (React.createElement(UploadListItem, { key: item.key || "".concat(item.name, "_").concat(index), index: index, fileList: fileList, file: item, style: props.style })); })));
};
