import { useRef } from 'react';
import { useMemoizedFn, useUpdate } from 'ahooks';
export function usePropsValue(options) {
    var value = options.value, defaultValue = options.defaultValue, onChange = options.onChange;
    var update = useUpdate();
    var stateRef = useRef(value !== undefined ? value : defaultValue);
    if (value !== undefined) {
        stateRef.current = value;
    }
    var setState = useMemoizedFn(function (v, forceTrigger) {
        if (forceTrigger === void 0) { forceTrigger = false; }
        // `forceTrigger` means trigger `onChange` even if `v` is the same as `stateRef.current`
        var nextValue = typeof v === 'function'
            ? v(stateRef.current)
            : v;
        if (!forceTrigger && nextValue === stateRef.current)
            return;
        stateRef.current = nextValue;
        update();
        return onChange === null || onChange === void 0 ? void 0 : onChange(nextValue);
    });
    return [stateRef.current, setState];
}
