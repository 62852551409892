// 需要排除的属性
var omitList = [
    '$i18n',
    'value',
    'defaultValue',
    'onChange',
    'style',
    'className',
];
/**
 * 过滤掉非dom属性，同时过滤表单的受控属性
 */
export function filterFormAndNonDomProps(props) {
    var originDomProps = {};
    if (typeof props === 'object' && props !== null) {
        Object.keys(props).forEach(function (key) {
            if (!omitList.includes(key)) {
                originDomProps[key] = props[key];
            }
        });
    }
    return originDomProps;
}
