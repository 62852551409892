import { __assign, __rest } from "tslib";
import React, { useContext } from 'react';
import classNames from 'classnames';
import SelectContext from './context';
export default function OptionGroup(props) {
    var className = props.className, children = props.children, title = props.title, others = __rest(props, ["className", "children", "title"]);
    var prefix = useContext(SelectContext).prefix;
    var clsPrefix = "".concat(prefix, "select-option-group");
    return (React.createElement("div", __assign({}, others, { className: classNames(clsPrefix, className), title: title }),
        React.createElement("div", { className: "".concat(clsPrefix, "-title") }, title),
        children));
}
