import { __assign, __rest } from "tslib";
import classNames from 'classnames';
import * as React from 'react';
import { CnEllipsis } from '@/components/cn-ellipsis';
export var FormatText = function (props) {
    var column = props.column, mode = props.mode, value = props.value, data = props.data, rowIndex = props.rowIndex;
    var getCellProps = (column || {}).getCellProps;
    var _a = (getCellProps === null || getCellProps === void 0 ? void 0 : getCellProps(value, data, rowIndex)) ||
        {}, onClickProps = _a.onClick, cellProps = __rest(_a, ["onClick"]);
    var onClick = function (event) {
        if (!onClickProps)
            return;
        onClickProps(event);
        if (mode === 'simple')
            event.stopPropagation();
    };
    if (value && typeof value === 'object') {
        var _value = value.value, text = value.text, link_1 = value.link, toolTips = value.toolTips, rest = __rest(value, ["value", "text", "link", "toolTips"]);
        var style = rest;
        var content = (_value !== null && _value !== void 0 ? _value : text) || '- -';
        if (mode === 'simple') {
            return React.createElement(CnEllipsis, { style: style }, content);
        }
        return (React.createElement("span", __assign({}, cellProps, { className: classNames({ 'cn-table-format-text-link': !!link_1 }), style: style, onClick: function (e) {
                if (link_1) {
                    window.open(link_1);
                }
                if (onClick) {
                    onClick(e);
                }
            } }), content));
    }
    if (mode === 'simple') {
        return React.createElement(CnEllipsis, null, value || '- -');
    }
    return React.createElement("span", __assign({}, cellProps, { onClick: onClick }), value || '- -');
};
