import { __spreadArray } from "tslib";
import { useSpring, animated } from '@react-spring/web';
import React, { useRef } from 'react';
import { useDragAndPinch } from '../../use-drag-and-pinch';
import * as mat from '@/utils/matrix';
import { bound } from '@/utils/bound';
import { rubberbandIfOutOfBounds } from '@/utils/rubberband';
import './index.scss';
import { useSize } from 'ahooks';
var classPrefix = 'cn-ui-m-image-viewer-slider-item';
export var ImageViewerSliderItem = function (props) {
    var value = props.value, dragLockRef = props.dragLockRef;
    var controlRef = useRef(null);
    var imgRef = useRef(null);
    var _a = useSpring(function () { return ({
        matrix: mat.create(),
        config: { tension: 200 },
    }); }), matrix = _a[0].matrix, api = _a[1];
    var controlSize = useSize(controlRef);
    var imgSize = useSize(imgRef);
    var pinchLockRef = useRef(false);
    var boundMatrix = function (nextMatrix, type, last) {
        if (last === void 0) { last = false; }
        if (!controlSize || !imgSize)
            return nextMatrix;
        var controlLeft = -controlSize.width / 2;
        var controlTop = -controlSize.height / 2;
        var imgLeft = -imgSize.width / 2;
        var imgTop = -imgSize.height / 2;
        var zoom = mat.getScaleX(nextMatrix);
        var scaledImgWidth = zoom * imgSize.width;
        var scaledImgHeight = zoom * imgSize.height;
        var _a = mat.apply(nextMatrix, [imgLeft, imgTop]), x = _a[0], y = _a[1];
        if (type === 'translate') {
            var boundedX = x;
            var boundedY = y;
            if (scaledImgWidth > controlSize.width) {
                var minX = controlLeft - (scaledImgWidth - controlSize.width);
                var maxX = controlLeft;
                boundedX = last ? bound(x, minX, maxX) : rubberbandIfOutOfBounds(x, minX, maxX, zoom * 50);
            }
            else {
                boundedX = -scaledImgWidth / 2;
            }
            if (scaledImgHeight > controlSize.height) {
                var minY = controlTop - (scaledImgHeight - controlSize.height);
                var maxY = controlTop;
                boundedY = last ? bound(y, minY, maxY) : rubberbandIfOutOfBounds(y, minY, maxY, zoom * 50);
            }
            else {
                boundedY = -scaledImgHeight / 2;
            }
            return mat.translate(nextMatrix, boundedX - x, boundedY - y);
        }
        if (type === 'scale' && last) {
            var _b = [
                scaledImgWidth > controlSize.width
                    ? bound(x, controlLeft - (scaledImgWidth - controlSize.width), controlLeft)
                    : -scaledImgWidth / 2,
                scaledImgHeight > controlSize.height
                    ? bound(y, controlTop - (scaledImgHeight - controlSize.height), controlTop)
                    : -scaledImgHeight / 2,
            ], boundedX = _b[0], boundedY = _b[1];
            return mat.translate(nextMatrix, boundedX - x, boundedY - y);
        }
        return nextMatrix;
    };
    useDragAndPinch({
        onDrag: function (state) {
            if (state.first)
                return;
            if (state.pinching)
                return state.cancel();
            // 判断点击时间>0是为了过滤掉非正常操作，例如用户长按选择图片之后的取消操作（也是一次点击）
            if (state.tap && state.elapsedTime > 0 && state.elapsedTime < 1000) {
                // 延迟 50ms，是为了避免关闭后，触发了页面背后元素的 onTouchEnd 事件，导致间接触发背后元素 onClick 事件的迷幻现象
                setTimeout(function () {
                    var _a;
                    (_a = props.onTap) === null || _a === void 0 ? void 0 : _a.call(props);
                }, 50);
            }
            var currentZoom = mat.getScaleX(matrix.get());
            if (dragLockRef) {
                dragLockRef.current = currentZoom !== 1;
            }
            if (!pinchLockRef.current && currentZoom <= 1) {
                api.start({
                    matrix: mat.create(),
                });
            }
            else {
                var currentMatrix = matrix.get();
                var offset = [
                    state.offset[0] - mat.getTranslateX(currentMatrix),
                    state.offset[1] - mat.getTranslateY(currentMatrix),
                ];
                var nextMatrix = mat.translate.apply(mat, __spreadArray([currentMatrix], (state.last
                    ? [
                        offset[0] + state.velocity[0] * state.direction[0] * 200,
                        offset[1] + state.velocity[1] * state.direction[1] * 200,
                    ]
                    : offset), false));
                api.start({
                    matrix: boundMatrix(nextMatrix, 'translate', state.last),
                    immediate: !state.last,
                });
            }
        },
        onPinch: function (state) {
            var _a;
            pinchLockRef.current = !state.last;
            var d = state.offset[0];
            if (d < 0)
                return;
            var nextZoom = state.last ? bound(d, 1, props.maxZoom) : d;
            (_a = props.onZoomChange) === null || _a === void 0 ? void 0 : _a.call(props, nextZoom);
            if (state.last && nextZoom <= 1) {
                api.start({
                    matrix: mat.create(),
                });
                if (dragLockRef) {
                    dragLockRef.current = false;
                }
            }
            else {
                if (!controlSize)
                    return;
                var currentMatrix = matrix.get();
                var currentZoom = mat.getScaleX(currentMatrix);
                var originOffsetX = state.origin[0] - controlSize.width / 2;
                var originOffsetY = state.origin[1] - controlSize.height / 2;
                var nextMatrix = mat.translate(currentMatrix, -originOffsetX, -originOffsetY);
                nextMatrix = mat.scale(nextMatrix, nextZoom / currentZoom);
                nextMatrix = mat.translate(nextMatrix, originOffsetX, originOffsetY);
                api.start({
                    matrix: boundMatrix(nextMatrix, 'scale', state.last),
                    immediate: !state.last,
                });
                if (dragLockRef) {
                    dragLockRef.current = true;
                }
            }
        },
    }, {
        target: controlRef,
        drag: {
            // filterTaps: true,
            from: function () { return [mat.getTranslateX(matrix.get()), mat.getTranslateY(matrix.get())]; },
            pointer: { touch: true },
        },
        pinch: {
            from: function () { return [mat.getScaleX(matrix.get()), 0]; },
            pointer: { touch: true },
        },
    });
    return (React.createElement("div", { className: classPrefix, style: { marginRight: 16 }, ref: controlRef },
        React.createElement("div", { className: "".concat(classPrefix, "-content") },
            React.createElement(animated.div, { className: "".concat(classPrefix, "-content-wrapper"), style: {
                    matrix: matrix,
                } },
                React.createElement("img", { ref: imgRef, src: value, draggable: false, alt: value })))));
};
