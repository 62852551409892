import { __assign } from "tslib";
import $i18n from 'panda-i18n';
import * as React from 'react';
import { withNativeProps, useControlled } from '@cainiaofe/cn-ui-common';
import DateTimeCalendarPicker from './date-time-calendar-picker';
import { toDate } from '@/utils/date';
import classNames from 'classnames';
function getInitValue(initValue) {
    return Array.isArray(initValue) && initValue.length === 2
        ? initValue.map(function (i) { return i || toDate(i); })
        : [null, null];
}
export function DateTimeRangePicker(props) {
    var _a = useControlled(props), value = _a[0], onChange = _a[1];
    var startProps = __assign(__assign({}, props), { drawerTitle: $i18n.get({
            id: 'StartDate',
            dm: '开始日期',
            ns: 'CnDatePickerPro',
        }), onChange: onChange, arrayIndex: 0, arrayValue: getInitValue(value) });
    var endProps = __assign(__assign({}, props), { drawerTitle: $i18n.get({
            id: 'Deadline.ZbnHK',
            dm: '截止日期',
            ns: 'CnDatePickerPro',
        }), onChange: onChange, arrayIndex: 1, arrayValue: getInitValue(value) });
    return withNativeProps(props, React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-m-date-time-range-picker') },
        React.createElement(DateTimeCalendarPicker, __assign({}, startProps)),
        React.createElement(DateTimeCalendarPicker, __assign({}, endProps))));
}
DateTimeRangePicker.displayName = 'DateTimeRangePicker';
DateTimeRangePicker.defaultProps = {
    format: 'YYYY-MM-DD HH:mm:ss',
};
