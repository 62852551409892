import { __assign, __rest, __spreadArray } from "tslib";
import './cn-card.scss';
import React from 'react';
import { useI18n } from 'panda-i18n';
import omit from 'lodash/omit';
import classNames from 'classnames';
import { CardTitle } from './cn-card-title';
import { CnStatusTag } from '@/components/cn-tag';
import { CnCheckbox } from '@/components/cn-checkbox';
import { CnButton } from '@/components/cn-button';
import { CnButtonGroup } from '@/components/cn-button-group';
var CardFooterActions = function (props) {
    var checkBox = props.checkBox, _a = props.buttons, buttons = _a === void 0 ? [] : _a;
    var $i18n = useI18n();
    if (!checkBox && !buttons.length)
        return null;
    return (React.createElement(React.Fragment, null,
        checkBox ? (React.createElement("div", { className: "checkbox-group" },
            React.createElement(CnCheckbox, __assign({ checked: checkBox.checked }, checkBox), $i18n.get({ id: 'Selection', dm: '选择', ns: 'CnCard' })))) : null,
        buttons.length ? (React.createElement(CnButtonGroup, { shape: "card" }, __spreadArray([], buttons, true).reverse().map(function (btnProps, index) {
            if (typeof btnProps === 'object' && btnProps.children) {
                return React.createElement(CnButton, __assign({}, btnProps, { key: index }));
            }
            return btnProps;
        }))) : null));
};
/**
 * @category 组件
 * @description 卡片组件
 */
export var CnCard = function (props) {
    var title = props.title, desc = props.desc, icon = props.icon, action = props.action, subTitle = props.subTitle, subDesc = props.subDesc, subIcon = props.subIcon, subAction = props.subAction, noPadding = props.noPadding, tags = props.tags, children = props.children, footAction = props.footAction, className = props.className, onClick = props.onClick, onClickCapture = props.onClickCapture, others = __rest(props, ["title", "desc", "icon", "action", "subTitle", "subDesc", "subIcon", "subAction", "noPadding", "tags", "children", "footAction", "className", "onClick", "onClickCapture"]);
    var outerTitleProps = {
        title: title,
        desc: desc,
        icon: icon,
        action: action,
    };
    var innerTitleProps = {
        title: subTitle,
        desc: subDesc,
        icon: subIcon,
        action: subAction,
    };
    // 只要有一个字段不为空就展示subtitle区域
    // eslint-disable-next-line max-len
    var showSubtitleHeader = subTitle || subDesc || subIcon || subAction || (Array.isArray(tags) && tags.length > 0);
    var restProps = omit(others, ['$i18n']);
    return (React.createElement("div", __assign({ onClick: onClick, onClickCapture: onClickCapture, className: classNames('cn-ui-m-card-wrapper', className) }, restProps),
        title ? React.createElement(CardTitle, __assign({}, outerTitleProps)) : null,
        React.createElement("div", { className: classNames('cn-ui-m-card', {
                'cn-ui-m-card-no-padding': noPadding,
            }) },
            showSubtitleHeader ? (React.createElement("div", { className: "cn-ui-m-card-header" },
                React.createElement(CardTitle, __assign({}, innerTitleProps)),
                Array.isArray(tags) && tags.length > 0 && (React.createElement("div", { className: "tags" }, tags.map(function (cnStatusTagProps) { return (React.createElement("div", { className: "tag-item" },
                    React.createElement(CnStatusTag, __assign({}, cnStatusTagProps)))); }))))) : null,
            React.createElement("div", { className: "cn-ui-m-card-body" }, children),
            footAction ? (React.createElement("div", { className: "cn-ui-m-card-footer" },
                React.createElement(CardFooterActions, __assign({}, footAction)))) : null)));
};
CnCard.displayName = 'CnCard';
CnCard.defaultProps = {
    noPadding: false,
};
