import { __assign } from "tslib";
import React from 'react';
import classNames from 'classnames';
import { CnIcon } from '@/components/cn-icon';
import { UploadList } from './upload-list';
import { CnUploader, useUploadState, withNativeProps, } from '@cainiaofe/cn-ui-common';
import { useItemStyle } from '../hooks/use-item-style';
// eslint-disable-next-line react/no-unused-prop-types
export var UploadView = React.forwardRef(function (props, ref) {
    var mode = props.mode;
    var _a = useUploadState(), readOnly = _a.readOnly, limited = _a.limited, getValue = _a.getValue;
    var _b = useItemStyle({ mode: mode }), itemStyle = _b.itemStyle, containerRef = _b.containerRef;
    var hideAddStyle = limited ? { display: 'none' } : {};
    return withNativeProps(props, React.createElement("div", { ref: containerRef, className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-m-oss-image-upload-box'), "data-testid": "cn-ui-m-oss-image-upload-box" },
        React.createElement("div", { className: classNames('cn-ui-m-oss-image-upload', {
                'cn-ui-m-oss-image-upload-readonly': readOnly,
                'cn-ui-m-oss-image-upload-single': mode === 'single',
                'cn-ui-m-oss-image-upload--empty': readOnly && !getValue().length,
            }) },
            React.createElement(UploadList, { style: itemStyle }),
            readOnly ? null : (React.createElement("div", { className: "cn-ui-m-oss-image-upload-item cn-ui-m-oss-image-upload-add", style: __assign(__assign({}, itemStyle), hideAddStyle) },
                React.createElement(CnUploader, { ref: ref, className: "cn-ui-m-oss-image-upload-add-inner" },
                    React.createElement(CnIcon, { type: "add", size: "xl" })))))));
});
UploadView.displayName = 'UploadView';
