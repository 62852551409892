import { __assign, __extends, __rest } from "tslib";
import * as React from 'react';
import cx from 'classnames';
export var Text = function (props) {
    var prefix = props.prefix, className = props.className, component = props.component, strong = props.strong, underline = props.underline, deleteProp = props.delete, code = props.code, mark = props.mark, children = props.children, others = __rest(props, ["prefix", "className", "component", "strong", "underline", "delete", "code", "mark", "children"]);
    var Tag = component;
    var displayChildren = children;
    if (strong) {
        displayChildren = React.createElement("strong", null, children);
    }
    if (underline) {
        displayChildren = React.createElement("u", null, children);
    }
    if (deleteProp) {
        displayChildren = React.createElement("del", null, children);
    }
    if (code) {
        displayChildren = React.createElement("code", null, children);
    }
    if (mark) {
        displayChildren = React.createElement("mark", null, children);
    }
    return (React.createElement(Tag, __assign({ "data-name": "CnTypography" }, others, { className: "".concat(className || '', " cn-ui-m-typography") }), displayChildren));
};
Text.defaultProps = {
    delete: false,
    mark: false,
    underline: false,
    strong: false,
    code: false,
    component: 'div',
};
export var createText = function (displayName, className) {
    var Title = /** @class */ (function (_super) {
        __extends(Title, _super);
        function Title() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        Title.prototype.render = function () {
            var _a = this.props, propsClassName = _a.className, others = __rest(_a, ["className"]);
            var classes = cx(className, propsClassName);
            return React.createElement(Text, __assign({}, others, { className: classes }));
        };
        Title.displayName = displayName;
        return Title;
    }(React.Component));
    return Title;
};
