import $i18n from 'panda-i18n';
import { cloneDate, checkDateRange, RANGE_CHECK_RESULT, getRealRange, } from '@/utils/date';
import dayjs from 'dayjs';
import { rangeOptions } from './range-options';
export function getColumns(curValue, options, props) {
    var hasHour = options.hasHour, hasMinute = options.hasMinute, hasSecond = options.hasSecond, use12Hours = options.use12Hours;
    var _a = props.hourStep, hourStep = _a === void 0 ? 1 : _a, _b = props.minuteStep, minuteStep = _b === void 0 ? 1 : _b, _c = props.secondStep, secondStep = _c === void 0 ? 1 : _c, _d = props.disabledHours, disabledHours = _d === void 0 ? function () { } : _d, _e = props.disabledMinutes, disabledMinutes = _e === void 0 ? function () { } : _e, _f = props.disabledSeconds, disabledSeconds = _f === void 0 ? function () { } : _f, validRangeProp = props.validRange;
    var validRange = getRealRange(options, props, validRangeProp);
    var dataSource = [];
    var isInRange = function (type, n) {
        if (!validRange) {
            return true;
        }
        var checkOptions = {
            hasHour: true,
            hasMinute: false,
            hasSecond: false,
            use12Hours: false,
        };
        var d = cloneDate(curValue);
        if (type === 'h') {
            d.setHours(n);
        }
        else if (type === 'm') {
            checkOptions.hasMinute = true;
            d.setMinutes(n);
        }
        else if (type === 's') {
            checkOptions.hasMinute = true;
            checkOptions.hasSecond = true;
            d.setSeconds(n);
        }
        return (checkDateRange(d, {
            time: checkOptions,
        }, validRange) === RANGE_CHECK_RESULT.IN_RANGE);
    };
    var rules = [
        {
            enable: hasHour,
            min: 0,
            max: use12Hours ? 11 : 23,
            step: hourStep,
            format: function (n) {
                return dayjs()
                    .hour(n)
                    .format(use12Hours ? 'h' : 'H') +
                    $i18n.get({ id: 'When', dm: '时', ns: 'CnTimePickerPro' });
            },
            filter: function (n) { return isInRange('h', n) && !disabledHours(n); },
        },
        {
            enable: hasMinute,
            min: 0,
            max: 59,
            step: minuteStep,
            format: function (n) {
                return n + $i18n.get({ id: 'Points', dm: '分', ns: 'CnTimePickerPro' });
            },
            filter: function (n) { return isInRange('m', n) && !disabledMinutes(n); },
        },
        {
            enable: hasSecond,
            min: 0,
            max: 59,
            step: secondStep,
            format: function (n) {
                return n + $i18n.get({ id: 'Seconds', dm: '秒', ns: 'CnTimePickerPro' });
            },
            filter: function (n) { return isInRange('s', n) && !disabledSeconds(n); },
        },
    ];
    rules.forEach(function (rule) {
        if (!rule.enable) {
            return;
        }
        var opts = rangeOptions(rule.min, rule.max, rule.step, rule.format);
        if (rule.filter) {
            opts = opts.filter(function (it) { return rule.filter(it.value); });
        }
        dataSource.push(opts);
    });
    if (use12Hours) {
        dataSource.push([
            {
                label: $i18n.get({ id: 'Morning', dm: '上午', ns: 'CnTimePickerPro' }),
                value: 'am',
            },
            {
                label: $i18n.get({
                    id: 'Afternoon',
                    dm: '下午',
                    ns: 'CnTimePickerPro',
                }),
                value: 'pm',
            },
        ]);
    }
    return dataSource;
}
