export var create = function () {
    return [1, 0, 0, 1, 0, 0];
};
export var getTranslateX = function (m) {
    return m[4];
};
export var getTranslateY = function (m) {
    return m[5];
};
export var getScaleX = function (m) {
    return m[0];
};
export var getScaleY = function (m) {
    return m[3];
};
export var translate = function (m, x, y) {
    return multiply([1, 0, 0, 1, x, y], m);
};
export var scale = function (m, scaleX, scaleY) {
    if (scaleY === void 0) { scaleY = scaleX; }
    return multiply([scaleX, 0, 0, scaleY, 0, 0], m);
};
export var apply = function (m, _a) {
    var ox = _a[0], oy = _a[1];
    return [m[0] * ox + m[2] * oy + m[4], m[1] * ox + m[3] * oy + m[5]];
};
export var multiply = function (m1, m2) {
    return [
        m1[0] * m2[0] + m1[2] * m2[1],
        m1[1] * m2[0] + m1[3] * m2[1],
        m1[0] * m2[2] + m1[2] * m2[3],
        m1[1] * m2[2] + m1[3] * m2[3],
        m1[0] * m2[4] + m1[2] * m2[5] + m1[4],
        m1[1] * m2[4] + m1[3] * m2[5] + m1[5],
    ];
};
