import { Children, isValidElement } from 'react';
import { isValidArray } from './func';
/**
 * 过滤无效的child
 * @param children
 */
export function getValidChildren(children) {
    if (!children) {
        return null;
    }
    else if (isValidElement(children)) {
        return children;
    }
    else if (Children.count(children) > 0) {
        var c = Children.toArray(children).filter(function (item) { return !!item; });
        return isValidArray(c) ? c : null;
    }
    else {
        return children;
    }
}
