/**
 * 本都搜索
 * @param originList 原始数据
 * @param showSearch 是否支持过滤
 * @param searchKey 过滤关键字
 * @returns
 */
export var getFilterOptions = function (originList, showSearch, searchKey) {
    if (showSearch && searchKey) {
        return originList.filter(function (item) { return item.label.includes(searchKey); });
    }
    return originList;
};
