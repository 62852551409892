import React from 'react';
import cx from 'classnames';
import { withNativeProps } from '@/utils/with-native-props';
var SkeletonTitle = function (props) {
    return withNativeProps(props, React.createElement("div", { className: cx({
            'cn-ui-m-skeleton-title': true,
            'cn-ui-m-skeleton-title-animation': props.animated,
        }) }));
};
export default SkeletonTitle;
