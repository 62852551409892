import { __assign, __rest } from "tslib";
import React, { useCallback, useMemo } from 'react';
import { useI18n, withI18n } from 'panda-i18n';
import cx from 'classnames';
import locale from '@/locales';
import { CnSafeArea } from '@/components/cn-safe-area';
import { CnBadge } from '@/components/cn-badge';
import { CnIcon } from '@/components/cn-icon';
import { CnModal } from '@/components/cn-modal';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
var ActionSheet = function (props) {
    var _a, _b;
    var visible = props.visible, message = props.message, options = props.options, alignProps = props.align, onClick = props.onClick, onClose = props.onClose, showCancel = props.showCancel, cancelText = props.cancelText, maskClosable = props.maskClosable, destructiveIndex = props.destructiveIndex, disabledIndexes = props.disabledIndexes, others = __rest(props, ["visible", "message", "options", "align", "onClick", "onClose", "showCancel", "cancelText", "maskClosable", "destructiveIndex", "disabledIndexes"]);
    var fullWidth = true;
    var $i18n = useI18n();
    var handleOptionClick = useCallback(function (option, index, e) {
        if (onClick) {
            onClick(option, index, e);
        }
        if (onClose) {
            onClose(index, e);
        }
    }, [onClick, onClose]);
    var clsPrefix = 'cn-ui-m-action-sheet';
    var buttons = useMemo(function () {
        if (!options) {
            return null;
        }
        var len = options.length;
        return options.map(function (option, index) {
            var _a;
            var disabled = disabledIndexes
                ? disabledIndexes.indexOf(index) > -1
                : false;
            var content = null;
            var align = alignProps || 'center';
            if (typeof option === 'string') {
                content = (React.createElement("div", { className: "".concat(clsPrefix, "-option-text ").concat(clsPrefix, "-option--").concat(align) }, option));
            }
            else if (typeof option === 'object') {
                var badge = option.badge, iconType = option.iconType, text = option.text, badgeType = option.badgeType;
                content = (React.createElement(React.Fragment, null,
                    iconType ? (React.createElement("div", { className: "".concat(clsPrefix, "-icon") },
                        React.createElement(CnIcon, { type: iconType, size: "xl" }))) : null,
                    React.createElement("div", { className: "".concat(clsPrefix, "-option-content ").concat(clsPrefix, "-option--").concat(align) },
                        React.createElement("div", { className: "".concat(clsPrefix, "-option-text") }, text),
                        badge ? (React.createElement(CnBadge, { className: "".concat(clsPrefix, "-badge"), count: badge, shape: badgeType === 'dot' ? 'dot' : 'normal' })) : null)));
            }
            return (React.createElement("div", { key: "".concat(clsPrefix, "-option-").concat(index), onClick: function (e) {
                    if (!disabled) {
                        handleOptionClick(option, index, e);
                    }
                }, className: cx("".concat(clsPrefix, "-option"), (_a = {},
                    _a["".concat(clsPrefix, "-option--destructive")] = destructiveIndex === index,
                    _a["".concat(clsPrefix, "-option--disabled")] = disabled,
                    _a["".concat(clsPrefix, "-option--advanced")] = typeof option === 'object' && option.iconType,
                    _a["".concat(clsPrefix, "-option--last")] = index === len - 1,
                    _a)) }, content));
        });
    }, [
        options,
        clsPrefix,
        disabledIndexes,
        destructiveIndex,
        alignProps,
        handleOptionClick,
    ]);
    return withNativeProps(props, React.createElement(CnModal, __assign({}, others, { visible: visible, showMask: true, maskClosable: maskClosable, className: cx("".concat(clsPrefix, "-drawer")), placement: "bottom", onClose: onClose }),
        React.createElement("div", { className: cx(CN_UI_HASH_CLASS_NAME, "".concat(clsPrefix), (_a = {},
                _a["".concat(clsPrefix, "--fullwidth")] = fullWidth,
                _a)) },
            message ? React.createElement("div", { className: "".concat(clsPrefix, "-msg") }, message) : null,
            React.createElement("div", { className: cx("".concat(clsPrefix, "-option-list")) }, buttons),
            showCancel ? (React.createElement("div", { className: cx("".concat(clsPrefix, "-option"), "".concat(clsPrefix, "-cancel")), onClick: function (e) { return onClose === null || onClose === void 0 ? void 0 : onClose('cancel', e); } },
                React.createElement("div", { className: "".concat(clsPrefix, "-option-text") }, cancelText ||
                    $i18n.get({ id: 'Cancel', dm: '取消', ns: 'CnActionSheet' })))) : null,
            React.createElement(CnSafeArea, { className: cx("".concat(clsPrefix, "-safe-area"), (_b = {},
                    _b["".concat(clsPrefix, "-safe-area--fullwidth")] = fullWidth,
                    _b)) }))));
};
ActionSheet.displayName = 'CnActionSheet';
ActionSheet.defaultProps = {
    visible: false,
    showCancel: true,
    maskClosable: true,
};
export var CnActionSheet = withI18n(ActionSheet, { locale: locale });
