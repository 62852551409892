var _a;
import { __assign, __awaiter, __generator } from "tslib";
import { request } from 'cn-request';
/**
 * 请求类型
 */
var REQUEST_TYPE;
(function (REQUEST_TYPE) {
    /**
     * 搜索（阿里数据源）
     */
    // ALI_SEARCH = 'ALI_SEARCH',
    /**
     * 按关键词搜索（阿里数据源）
     */
    REQUEST_TYPE["ALI_SEARCH_BY_KEYWORD"] = "ALI_SEARCH_BY_KEYWORD";
    /**
     * 按工号搜索（阿里数据源）
     */
    REQUEST_TYPE["ALI_SEARCH_BY_STAFFID"] = "ALI_SEARCH_BY_STAFFID";
    /**
     * 按关键词搜索（菜鸟数据源）
     */
    REQUEST_TYPE["CAINIAO_SEARCH_BY_KEYWORD"] = "CAINIAO_SEARCH_BY_KEYWORD";
    /**
     * 按工号搜索（菜鸟数据源）
     */
    REQUEST_TYPE["CAINIAO_SEARCH_BY_STAFFID"] = "CAINIAO_SEARCH_BY_STAFFID";
})(REQUEST_TYPE || (REQUEST_TYPE = {}));
/**
 * 内置接口名称
 */
var INTERNAL_PATH_MAP = (_a = {},
    // [REQUEST_TYPE.ALI_SEARCH]: '/cmdp/hysmab/getAliUserInfoBySearchKey',
    _a[REQUEST_TYPE.ALI_SEARCH_BY_KEYWORD] = '/cmdp/hysmab/getAliUserInfoBySearchKey',
    _a[REQUEST_TYPE.ALI_SEARCH_BY_STAFFID] = '/cmdp/hysmab/getAliUserInfoByEmpNo',
    _a[REQUEST_TYPE.CAINIAO_SEARCH_BY_KEYWORD] = '/cmdp/hysmab/fuzzyQueryOnJobEmployee',
    _a[REQUEST_TYPE.CAINIAO_SEARCH_BY_STAFFID] = '/cmdp/hysmab/getOnJobEmployeeByEmpNo',
    _a);
/**
 * 非mokelay直接接入时的代理地址
 */
var NON_MOKELY_PROXY_PATH = '/cn-common-proxy/post';
/**
 * 拼接请求参数
 */
function buildRequestParams(params) {
    var data = params.data, serviceType = params.serviceType, fromType = params.fromType, _a = params.mokelay, mokelay = _a === void 0 ? false : _a, _b = params.host, host = _b === void 0 ? '' : _b, _c = params.path, path = _c === void 0 ? '' : _c;
    // let requestType: REQUEST_TYPE = REQUEST_TYPE.ALI_SEARCH;
    var requestType = REQUEST_TYPE.ALI_SEARCH_BY_KEYWORD;
    if (serviceType === 'ali') {
        // requestType = REQUEST_TYPE.ALI_SEARCH;
        requestType = fromType === 'keywords'
            ? REQUEST_TYPE.ALI_SEARCH_BY_KEYWORD
            : REQUEST_TYPE.ALI_SEARCH_BY_STAFFID;
    }
    else if (serviceType === 'cainiao') {
        requestType =
            fromType === 'keywords'
                ? REQUEST_TYPE.CAINIAO_SEARCH_BY_KEYWORD
                : REQUEST_TYPE.CAINIAO_SEARCH_BY_STAFFID;
    }
    else {
        throw new Error('invalid service type');
    }
    // 拼接地址，优先外部传入的path，其次host+mokelay判断
    var innerPath = INTERNAL_PATH_MAP[requestType];
    var builtPath = path || (mokelay ? innerPath : NON_MOKELY_PROXY_PATH);
    // 拼接参数
    var builtData = path || mokelay
        ? data
        : {
            path: innerPath,
            params: data,
        };
    // 最终请求参数
    var builtParams = {
        url: "".concat(host).concat(builtPath),
        method: 'post',
        withCredentials: true,
        data: builtData,
    };
    if (mokelay) {
        builtParams.crossOrigin = true;
        builtParams.isCommonRequest = true;
    }
    return builtParams;
}
/**
 * 数据源格式化
 * @param items 原始数据源
 * @returns 符合组件结构的数据源
 */
export function processDataSource(items) {
    if (!Array.isArray(items))
        return [];
    return (items
        // item判空：针对数据源中包含null等值的情况；item.enabled判空：对应员工接口规范中的字段
        .filter(function (item) { return item && item.enabled !== false; })
        .map(function (item) { return (__assign(__assign({}, item), { 
        // 集团接口返回的是personalPhotoUrl，菜鸟接口用工号拼接头像
        personalPhotoUrl: item.personalPhotoUrl ||
            "https://work.alibaba-inc.com/photo/".concat(item.workNo, ".50x50.jpg"), 
        // 集团接口返回的是deptName，菜鸟接口返回的是department
        department: item.department || item.deptName, label: item.nickName || item.name, value: item.workNo })); }));
}
/**
 * 格式化返回数据
 * @param res 原始返回数据
 * @returns 格式化后可被下拉组件使用的数据
 */
export function formatResponse(res) {
    var _a, _b;
    var resultData = (Array.isArray((_a = res.data) === null || _a === void 0 ? void 0 : _a.data) ? (_b = res.data) === null || _b === void 0 ? void 0 : _b.data : res === null || res === void 0 ? void 0 : res.data) || res;
    if (!Array.isArray(resultData))
        return [];
    return processDataSource(resultData);
}
/**
 * 搜索
 */
export function search(params) {
    return __awaiter(this, void 0, void 0, function () {
        var mokelay, host, serviceType, fromType, path, _a, value, _b, typeList, isOnJob, data, requestParams, result, fomattedResult;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    mokelay = params.mokelay, host = params.host, serviceType = params.serviceType, fromType = params.fromType, path = params.path, _a = params.value, value = _a === void 0 ? '' : _a, _b = params.typeList, typeList = _b === void 0 ? [] : _b, isOnJob = params.isOnJob;
                    data = fromType === 'staffId' && value
                        ? {
                            empId: value,
                        }
                        : {
                            searchKey: value,
                        };
                    if (serviceType === 'cainiao') {
                        data.typeList = typeList.join(',');
                        data.isOnJob = isOnJob;
                    }
                    requestParams = buildRequestParams({
                        data: data,
                        mokelay: mokelay,
                        host: host,
                        path: path,
                        serviceType: serviceType,
                        fromType: fromType,
                    });
                    return [4 /*yield*/, request(requestParams)];
                case 1:
                    result = _c.sent();
                    fomattedResult = formatResponse(result);
                    return [2 /*return*/, fomattedResult];
            }
        });
    });
}
