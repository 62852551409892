import { __assign, __rest } from "tslib";
import * as React from 'react';
import { withI18n } from 'panda-i18n';
import { CnCascaderSelect as CnCascaderSelectView } from './view/single-select/cascader-select';
import { CnCascaderSelectMultiply as CnCascaderSelectMultiplyView } from './view/multi-select/caseder-select-multi';
import locale from '@/locales';
import './styles/index.scss';
var CascaderSelect = function (props) {
    var multiple = props.multiple, restProps = __rest(props, ["multiple"]);
    if (multiple === true) {
        return React.createElement(CnCascaderSelectMultiplyView, __assign({}, props));
    }
    return React.createElement(CnCascaderSelectView, __assign({}, restProps));
};
export var CnCascaderSelectV2 = withI18n(CascaderSelect, {
    locale: locale,
});
