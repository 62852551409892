import { __assign } from "tslib";
import * as React from 'react';
import { CnFormField } from '@/form/cn-form-field';
import { CnFilter as CnFilterView } from './cn-filter';
import locale from '@/locales';
import { withI18n } from 'panda-i18n';
import './index.scss';
/**
 * @deprecated 请使用 CnFilterProItem 替换
 */
var CnFilterItem = function (props) {
    return React.createElement(CnFormField, __assign({ title: props.label }, props));
};
CnFilterItem.displayName = 'CnFilterItem';
var CnFilterProItem = function (props) {
    return React.createElement(CnFilterItem, __assign({}, props));
};
CnFilterProItem.displayName = 'CnFilterProItem';
/**
 * @deprecated 请使用 CnFilterPro 替换
 */
var CnFilter = withI18n(CnFilterView, { locale: locale });
var CnFilterPro = function (props) {
    return React.createElement(CnFilter, __assign({}, props));
};
CnFilterPro.displayName = 'CnFilterPro';
export { CnFilter, CnFilterPro, CnFilterItem, CnFilterProItem };
