import { isPromise } from "../utils/is-promise";
import isFunction from 'lodash/isFunction';
import { useState } from 'react';
export var useAsyncLoading = function (props) {
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = props || {}, asyncLoading = _b.asyncLoading, onClick = _b.onClick, originalLoading = _b.loading;
    if (asyncLoading && isFunction(onClick)) {
        var wrapOnClick = new Proxy(onClick, {
            apply: function (target, object, args) {
                setLoading(true);
                // @ts-ignore
                var result = target.apply(void 0, args);
                if (isPromise(result)) {
                    result.finally(function () {
                        setLoading(false);
                    });
                }
                else {
                    setLoading(false);
                }
                return result;
            },
        });
        return {
            onClick: wrapOnClick,
            loading: loading,
        };
    }
    return {
        onClick: onClick,
        loading: originalLoading,
    };
};
