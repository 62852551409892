import { __assign } from "tslib";
import $i18n from 'panda-i18n';
import React from 'react';
import { CnDatePickerPro } from './cn-date-picker-pro';
var CnQuarterPickerPro = function (props) {
    return (React.createElement(CnDatePickerPro, __assign({ precision: "quarter", placeholder: $i18n.get({
            id: 'PleaseSelectQuarter',
            dm: '请选择季度',
            ns: 'CnDatePickerPro',
        }) }, props)));
};
CnQuarterPickerPro.displayName = 'CnQuarterPickerPro';
export { CnQuarterPickerPro };
