import { useIsomorphicLayoutEffect, useMemoizedFn } from 'ahooks';
export function useResizeEffect(effect, targetRef) {
    var fn = useMemoizedFn(effect);
    useIsomorphicLayoutEffect(function () {
        var target = targetRef.current;
        if (!target)
            return;
        if (window.ResizeObserver) {
            var animationFrame_1;
            var observer_1 = new ResizeObserver(function () {
                animationFrame_1 = window.requestAnimationFrame(function () { return fn(target); });
            });
            observer_1.observe(target);
            return function () {
                window.cancelAnimationFrame(animationFrame_1);
                observer_1.disconnect();
            };
        }
        else {
            fn(target);
        }
    }, [targetRef]);
}
