import { __spreadArray } from "tslib";
import React, { memo, useCallback, useEffect, useState } from 'react';
import { mergeProps } from '@/components/cn-picker/with-default-props';
import { Wheel } from './wheel';
import { useColumnsExtend } from './columns-extend';
import { withNativeProps } from '@/utils/with-native-props';
import { useDebounceEffect } from 'ahooks';
import { defaultRenderLabel } from '@/components/cn-picker/picker-utils';
import { CnLoading } from '@/components/cn-loading';
import classNames from 'classnames';
var classPrefix = 'cn-ui-m-picker-view';
var defaultProps = {
    defaultValue: [],
    renderLabel: defaultRenderLabel,
    mouseWheel: false,
    loadingContent: (React.createElement("div", { className: "".concat(classPrefix, "-loading-content") },
        React.createElement(CnLoading, null))),
};
var PickerView = memo(function (p) {
    var props = mergeProps(defaultProps, p);
    var _a = useState(props.value === undefined ? props.defaultValue : props.value), innerValue = _a[0], setInnerValue = _a[1];
    // Sync `value` to `innerValue`
    useEffect(function () {
        if (props.value === undefined)
            return; // Uncontrolled mode
        if (props.value === innerValue)
            return;
        setInnerValue(props.value);
    }, [props.value]);
    useEffect(function () {
        if (props.value === innerValue)
            return;
        var timeout = window.setTimeout(function () {
            if (props.value !== undefined && props.value !== innerValue) {
                setInnerValue(props.value);
            }
        }, 1000);
        return function () {
            window.clearTimeout(timeout);
        };
    }, [props.value, innerValue]);
    var extend = useColumnsExtend(props.columns, innerValue);
    var columns = extend.columns;
    useDebounceEffect(function () {
        var _a;
        if (props.value === innerValue)
            return;
        (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, innerValue, extend);
    }, [innerValue], {
        wait: 0,
        leading: false,
        trailing: true,
    });
    var handleSelect = useCallback(function (val, index) {
        setInnerValue(function (prev) {
            var next = __spreadArray([], prev, true);
            next[index] = val;
            return next;
        });
    }, []);
    return withNativeProps(props, React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, "".concat(classPrefix)) }, props.loading ? (props.loadingContent) : (React.createElement(React.Fragment, null,
        columns.map(function (column, index) { return (React.createElement(Wheel, { key: index, index: index, column: column, value: innerValue[index], onSelect: handleSelect, renderLabel: props.renderLabel, mouseWheel: props.mouseWheel })); }),
        React.createElement("div", { className: "".concat(classPrefix, "-mask") },
            React.createElement("div", { className: "".concat(classPrefix, "-mask-top") }),
            React.createElement("div", { className: "".concat(classPrefix, "-mask-middle") }),
            React.createElement("div", { className: "".concat(classPrefix, "-mask-bottom") }))))));
});
var CnPickerView = PickerView;
CnPickerView.displayName = 'CnPickerView';
export { CnPickerView };
