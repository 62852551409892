import { __assign } from "tslib";
export function getLocale(locale, componentName, props, defaultLocale) {
    if (props === void 0) { props = {}; }
    if (defaultLocale === void 0) { defaultLocale = {}; }
    var _a = props.locale, componentLocale = _a === void 0 ? {} : _a;
    try {
        delete props.locale;
    }
    catch (e) {
        // ignore
    }
    return __assign(__assign(__assign({}, defaultLocale), (locale[componentName] || {})), (componentLocale || {}));
}
