import * as dateUtils from './date-picker-date-utils';
import * as weekUtils from './date-picker-week-utils';
var precisionLengthRecord = {
    year: 1,
    month: 2,
    day: 3,
    hour: 4,
    minute: 5,
    second: 6,
};
export var convertDateToStringArray = function (date, precision) {
    if (precision.includes('week')) {
        return weekUtils.convertDateToStringArray(date);
    }
    else if (precision === 'quarter') {
        return dateUtils.convertQuarterDateToStringArray(date);
    }
    else {
        var datePrecision = precision;
        var stringArray = dateUtils.convertDateToStringArray(date);
        return stringArray.slice(0, precisionLengthRecord[datePrecision]);
    }
};
export var convertStringArrayToDate = function (value, precision) {
    if (precision.includes('week')) {
        return weekUtils.convertStringArrayToDate(value);
    }
    else if (precision === 'quarter') {
        return dateUtils.convertQuarterArrayToDate(value);
    }
    else {
        return dateUtils.convertStringArrayToDate(value);
    }
};
export var generateDatePickerColumns = function (selected, min, max, precision, renderLabel, filter) {
    if (precision.startsWith('week')) {
        return weekUtils.generateDatePickerColumns(selected, min, max, precision, renderLabel, filter);
    }
    else {
        return dateUtils.generateDatePickerColumns(selected, min, max, precision, renderLabel, filter);
    }
};
export var defaultRenderLabel = function (precision, data) {
    if (precision.includes('week')) {
        return weekUtils.defaultRenderLabel(precision, data);
    }
    else {
        return dateUtils.defaultRenderLabel(precision, data);
    }
};
