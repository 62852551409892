import $i18n from 'panda-i18n';
import React, { forwardRef, useImperativeHandle, useRef, useState, } from 'react';
import classNames from 'classnames';
import { withNativeProps } from '@/utils/with-native-props';
import { usePropsValue } from '@/utils/use-props-value';
import { CnInput } from '../cn-input';
import { CnButton } from '../cn-button';
import { CnIcon } from '../cn-icon';
var classPrefix = 'cn-ui-m-search';
var Search = function (props, ref) {
    var _a, _b;
    var showCancelMode = props.showCancelMode, clearOnCancel = props.clearOnCancel, icon = props.icon, loading = props.loading, _c = props.showSearchButton, showSearchButton = _c === void 0 ? false : _c, _d = props.cancelText, cancelText = _d === void 0 ? $i18n.get({ id: 'Cancel', dm: '取消', ns: 'CnSearch' }) : _d, _e = props.placeholder, placeholder = _e === void 0 ? $i18n.get({
        id: 'PleaseEnterTheSearchContent',
        dm: '请输入搜索内容',
        ns: 'CnSearch',
    }) : _e;
    var _f = usePropsValue(props), value = _f[0], setValue = _f[1];
    var _g = useState(false), hasFocus = _g[0], setHasFocus = _g[1];
    var inputRef = useRef(null);
    var composingRef = useRef(false);
    useImperativeHandle(ref, function () { return ({
        clear: function () { var _a; return (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.clear(); },
        focus: function () { var _a; return (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus(); },
        blur: function () { var _a; return (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.blur(); },
        get nativeElement() {
            var _a, _b;
            return (_b = (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.nativeElement) !== null && _b !== void 0 ? _b : null;
        },
    }); });
    var renderCancelButton = function () {
        if (showSearchButton)
            return null;
        var isShowCancel;
        if (showCancelMode === 'always') {
            isShowCancel = true;
        }
        else {
            isShowCancel = showCancelMode === 'focus' && hasFocus;
        }
        return (isShowCancel && (React.createElement("div", { className: "".concat(classPrefix, "-suffix") },
            React.createElement(CnButton, { text: true, type: "primary", className: "".concat(classPrefix, "-cancel-button"), onClick: function () {
                    var _a, _b, _c;
                    if (clearOnCancel) {
                        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.clear();
                        setValue('');
                    }
                    (_b = inputRef.current) === null || _b === void 0 ? void 0 : _b.blur();
                    (_c = props.onCancel) === null || _c === void 0 ? void 0 : _c.call(props);
                }, onMouseDown: function (e) {
                    e.preventDefault();
                } }, cancelText))));
    };
    var renderSearchButton = function () {
        if (showSearchButton) {
            return (React.createElement(CnButton, { type: "primary", text: true, className: "".concat(classPrefix, "-search-button"), onClick: function () {
                    var _a;
                    (_a = props.onSearch) === null || _a === void 0 ? void 0 : _a.call(props, value);
                } }, $i18n.get({ id: 'Search', dm: '搜索', ns: 'CnSearch' })));
        }
        return null;
    };
    var handleKeydown = function (e) {
        var _a, _b;
        if (e.code === 'Enter' || e.keyCode === 13) {
            if (!composingRef.current) {
                (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.blur();
                (_b = props.onSearch) === null || _b === void 0 ? void 0 : _b.call(props, value);
            }
        }
    };
    return withNativeProps(props, React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, classPrefix, (_a = {},
            _a["".concat(classPrefix, "-active")] = hasFocus,
            _a)) },
        React.createElement("div", { className: "".concat(classPrefix, "-input-box") },
            icon && React.createElement("div", { className: "".concat(classPrefix, "-input-box-icon") }, icon),
            React.createElement(CnInput, { ref: inputRef, confirmType: "search", 
                // type="search" 时 hover 时会出现 X
                // htmlType="search"
                align: "left", enterKeyHint: "search", composition: true, className: classNames("".concat(classPrefix, "-input"), (_b = {},
                    _b["".concat(classPrefix, "-input-without-icon")] = !icon,
                    _b)), value: value, onChange: setValue, maxLength: props.maxLength, placeholder: placeholder, hasClear: loading ? false : props.hasClear, state: loading ? 'loading' : 'normal', onFocus: function (e) {
                    var _a;
                    setHasFocus(true);
                    (_a = props.onFocus) === null || _a === void 0 ? void 0 : _a.call(props, e);
                }, onBlur: function (e) {
                    var _a;
                    setHasFocus(false);
                    (_a = props.onBlur) === null || _a === void 0 ? void 0 : _a.call(props, e);
                }, onClear: props.onClear, onKeyDown: handleKeydown, type: "normal", onCompositionStart: function () {
                    composingRef.current = true;
                }, onCompositionEnd: function () {
                    composingRef.current = false;
                } })),
        renderCancelButton(),
        renderSearchButton()));
};
var CnSearch = forwardRef(Search);
CnSearch.displayName = 'CnSearch';
CnSearch.defaultProps = {
    showCancelMode: 'none',
    clearOnCancel: true,
    icon: React.createElement(CnIcon, { type: "search" }),
};
export { CnSearch };
