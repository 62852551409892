import { useUpdateEffect } from 'ahooks';
import { useMemo, useState } from 'react';
export var useIndex = function (props) {
    var setIndex = props.setIndex, index = props.index;
    var _a = useState(index), value = _a[0], setValue = _a[1];
    var _setValue = useMemo(function () {
        return setIndex || setValue;
    }, [setIndex]);
    useUpdateEffect(function () {
        setValue(index);
    }, [index]);
    return {
        index: value,
        setIndex: _setValue,
    };
};
