import { __assign, __rest } from "tslib";
import * as React from 'react';
import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { CnFormItem } from '@/components/cn-form-item';
import { CnTimePickerPro } from '@/components/cn-time-picker-pro';
import isFunction from 'lodash/isFunction';
import { isValidDate } from '@/utils/date';
export default function CalendarTimePicker(props) {
    var prefix = props.prefix, label = props.label, value = props.value, _a = props.format, format = _a === void 0 ? 'HH:mm:ss' : _a, onChange = props.onChange, others = __rest(props, ["prefix", "label", "value", "format", "onChange"]);
    var _b = useState(isValidDate(value) ? value : new Date()), curTime = _b[0], setTime = _b[1];
    var clsPrefix = "".concat(prefix, "-time");
    useEffect(function () {
        if (isValidDate(value)) {
            setTime(value);
        }
    }, [value]);
    /**
     * 处理 time picker 调整事件
     * @param date
     */
    var handlePickerChange = function (date) {
        var d = dayjs(date);
        var h = d.hour();
        var m = d.minute();
        var s = d.second();
        var newDate = dayjs(curTime).hour(h).minute(m).second(s);
        // setTime(newDate.toDate());
        // 当关闭时, 触发 change 回调
        if (isFunction(onChange)) {
            onChange(newDate.toDate());
        }
    };
    return (React.createElement("div", { className: clsPrefix },
        React.createElement(CnFormItem, { title: label },
            React.createElement(CnTimePickerPro, __assign({}, others, { insideForm: true, formLabel: label, className: "".concat(clsPrefix, "-picker"), format: format, value: curTime, onChange: handlePickerChange })))));
}
