import { __assign, __rest } from "tslib";
import React, { forwardRef, useEffect, useState, } from 'react';
import classNames from 'classnames';
import { useSpring, animated } from '@react-spring/web';
var isWeb = true;
var Mask = function (props, ref) {
    var _a;
    var _b = props.prefix, prefix = _b === void 0 ? 'cn-ui-m-' : _b, className = props.className, visible = props.visible, view = props.view, others = __rest(props, ["prefix", "className", "visible", "view"]);
    var _c = useState(visible), show = _c[0], setShow = _c[1];
    var initOverflow = '';
    // notice: 如果mask 打开顺序为 AB， 关闭顺序也为 AB 会出问题
    if (isWeb) {
        initOverflow = document.body.style.overflow;
    }
    // @ts-ignore
    useEffect(function () {
        if (!visible && show) {
            var t1_1 = setTimeout(function () {
                setShow(false);
            }, 100);
            return function () {
                clearTimeout(t1_1);
            };
        }
        else {
            setShow(visible);
        }
        // disable page scroll in web
        if (isWeb) {
            if (visible) {
                document.body.style.overflow = 'hidden';
            }
            else {
                document.body.style.overflow = initOverflow;
            }
            return function () {
                var activeMasks = document.getElementsByClassName("".concat(clsPrefix, "-mask--active"));
                if (activeMasks.length === 0) {
                    document.body.style.overflow = initOverflow;
                }
            };
        }
    }, [visible]);
    var opacity = useSpring({
        opacity: visible ? 1 : 0,
        config: {
            precision: 0.01,
            mass: 1,
            tension: 250,
            friction: 30,
            duration: 100,
            clamp: true,
        },
    }).opacity;
    if (!show) {
        return null;
    }
    var clsPrefix = "".concat(prefix, "modal");
    // Web
    return (React.createElement(animated.div, __assign({ "data-testid": "mask", ref: ref }, others, { 
        // disable-scroll
        // catchTouchMove
        className: classNames(className, "".concat(clsPrefix, "-mask"), (_a = {},
            _a["".concat(clsPrefix, "-mask--active")] = visible,
            _a["".concat(clsPrefix, "-mask--inactive")] = !visible,
            _a)), style: __assign(__assign({}, props.style), { opacity: opacity, display: show ? undefined : 'none' }) })));
};
Mask.displayName = 'Modal.Mask';
export default forwardRef(Mask);
