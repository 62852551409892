import { __assign } from "tslib";
/**
 * 递归改变子节点状态，主要为checked和indeterminate状态
 * @param node 节点
 * @returns 改变后的节点
 */
export function updateNodeState(node) {
    if (!node.children || node.children.length === 0) {
        return node;
    }
    var allChildrenChecked = true;
    var someChildrenChecked = false;
    for (var _i = 0, _a = node.children; _i < _a.length; _i++) {
        var child = _a[_i];
        if (!child.disabled) {
            var updatedChild = updateNodeState(child);
            allChildrenChecked = allChildrenChecked && updatedChild.checked;
            someChildrenChecked =
                someChildrenChecked ||
                    updatedChild.checked ||
                    updatedChild.indeterminate;
        }
    }
    var checked = false;
    var indeterminate = false;
    if (allChildrenChecked) {
        checked = true;
    }
    else if (someChildrenChecked) {
        indeterminate = true;
    }
    return __assign(__assign({}, node), { checked: checked, indeterminate: indeterminate });
}
