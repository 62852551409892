import * as React from 'react';
import { CnEllipsis } from '@/components/cn-ellipsis';
import { FormatText } from './text';
export var FormatMultipleText = function (props) {
    var mode = props.mode, _a = props.value, value = _a === void 0 ? '- -' : _a;
    if (mode === 'simple') {
        if (Array.isArray(value)) {
            return React.createElement(FormatText, { mode: mode, value: value[0] });
        }
        return React.createElement(CnEllipsis, null, value);
    }
    if (Array.isArray(value)) {
        return (React.createElement(React.Fragment, null, value.map(function (item) { return (React.createElement("div", null,
            React.createElement(FormatText, { mode: mode, value: item }))); })));
    }
    return React.createElement(React.Fragment, null, value);
};
