export function dateToPicker(d, options) {
    var hasHour = options.hasHour, hasMinute = options.hasMinute, hasSecond = options.hasSecond, use12Hours = options.use12Hours;
    var result = [];
    if (hasHour) {
        var h = d.getHours();
        result.push(use12Hours ? h % 12 : h);
    }
    if (hasMinute) {
        result.push(d.getMinutes());
    }
    if (hasSecond) {
        result.push(d.getSeconds());
    }
    if (use12Hours) {
        result.push(d.getHours() >= 12 ? 'pm' : 'am');
    }
    return result;
}
