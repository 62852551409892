import { __assign } from "tslib";
import { updateNodeState } from './update-node-state';
/**
 * 切换子节点状态
 * @param children 子节点列表
 * @param checked 是否选中
 * @returns 更新后的子节点列表
 */
export function toggleChildrenState(children, checked) {
    if (!children || children.length === 0) {
        return children;
    }
    return children.map(function (child) {
        if (!child.disabled) {
            var toggledChild = toggleNode(child, checked);
            return updateNodeState(toggledChild);
        }
        return child;
    });
}
/**
 * 切换节点状态
 * @param node 节点
 * @param checked 是否选中
 * @returns 切换后的节点
 */
export function toggleNode(node, checked) {
    // 父节点选中，子节点全部选中
    return __assign(__assign({}, node), { checked: checked, indeterminate: false, children: toggleChildrenState(node.children, checked) });
}
