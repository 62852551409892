import * as React from 'react';
export var Indicator = function (props) {
    var className = props.className, inverse = props.inverse;
    var id = inverse ? 'linearGradient-inverse' : 'linearGradient';
    return (React.createElement("svg", { className: className, viewBox: "0 0 36 36", version: "1.1", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", "data-icon": "spin" },
        React.createElement("defs", null,
            React.createElement("linearGradient", { x1: "0%", y1: "100%", x2: "100%", y2: "100%", id: id },
                React.createElement("stop", { stopColor: "currentColor", stopOpacity: "0", offset: "0%" }),
                React.createElement("stop", { stopColor: "currentColor", stopOpacity: "0.50", offset: "39.9430698%" }),
                React.createElement("stop", { stopColor: "currentColor", offset: "100%" }))),
        React.createElement("g", { stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("rect", { fillOpacity: "0.01", fill: "#FFFFFF", x: "0", y: "0", width: "36", height: "36" }),
            React.createElement("path", { d: "M34,18 C34,9.163444 26.836556,2 18,2 C11.6597233,2 6.18078805,5.68784135 3.59122325,11.0354951", stroke: "url(#".concat(id, ")"), strokeWidth: "4", strokeLinecap: "round" }))));
};
