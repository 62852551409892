import { __awaiter, __generator } from "tslib";
import React, { useState } from 'react';
import { isPromise } from '@/utils/object';
import { CnDialog } from '../../../cn-dialog';
export var UploadConfirm = function (props) {
    var openFileDialog = props.openFileDialog, _a = props.uploadConfirm, uploadConfirm = _a === void 0 ? {} : _a;
    var _b = useState(false), visible = _b[0], setVisible = _b[1];
    var title = uploadConfirm.title, content = uploadConfirm.content, okProps = uploadConfirm.okProps, cancelProps = uploadConfirm.cancelProps, footerActions = uploadConfirm.footerActions, onOk = uploadConfirm.onOk, onCancel = uploadConfirm.onCancel, beforeUploadConfirm = uploadConfirm.beforeUploadConfirm;
    if (!beforeUploadConfirm || !content)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "cn-ui-m-oss-upload-confirm", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                var result;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            result = beforeUploadConfirm();
                            if (!isPromise(result)) return [3 /*break*/, 2];
                            return [4 /*yield*/, result];
                        case 1:
                            result = _a.sent();
                            _a.label = 2;
                        case 2:
                            if (result === false)
                                return [2 /*return*/];
                            if (result === true) {
                                setVisible(true);
                                return [2 /*return*/];
                            }
                            openFileDialog();
                            return [2 /*return*/];
                    }
                });
            }); } }),
        React.createElement(CnDialog, { title: title, visible: visible, onOk: function (e) {
                setVisible(false);
                openFileDialog();
                onOk === null || onOk === void 0 ? void 0 : onOk(e);
            }, onClose: function (e) {
                setVisible(false);
                onCancel === null || onCancel === void 0 ? void 0 : onCancel(e);
            }, onCancel: function () { return setVisible(false); }, okProps: okProps, cancelProps: cancelProps, footerActions: footerActions }, content)));
};
