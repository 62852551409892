import { __assign, __rest } from "tslib";
import * as React from 'react';
import { ExpressionScope, FormProvider, FormConsumer } from '@formily/react';
import { createForm } from '@formily/core';
import { SchemaField } from '@/form/schema-field';
import { CnFormLayout } from '@/form/cn-form-layout';
import { CnSubmit } from '@/form/cn-submit';
import { CnReset } from '@/form/cn-reset';
import { CnButtonGroup } from '@/components/cn-button-group';
import { CnPageFooter } from '@/components/cn-page-footer';
export var CnForm = function (props) {
    var form = props.form, formProps = props.formProps, components = props.components, schema = props.schema, formLayoutProps = props.formLayoutProps, children = props.children, hasFooterSubmit = props.hasFooterSubmit, footerConfig = props.footerConfig, onSubmit = props.onSubmit, onReset = props.onReset;
    var formInstance = React.useMemo(function () {
        if (form) {
            return form;
        }
        return createForm(formProps);
    }, [form, schema]);
    var footer = React.useMemo(function () {
        if (!hasFooterSubmit && !footerConfig)
            return;
        var _a = footerConfig || {}, submitProps = _a.submitProps, resetProps = _a.resetProps, className = _a.className, customRender = _a.customRender, otherProps = __rest(_a, ["submitProps", "resetProps", "className", "customRender"]);
        if (customRender) {
            return (React.createElement(CnPageFooter, __assign({ className: className, rightSlot: React.createElement(FormConsumer, null, customRender) }, otherProps)));
        }
        return (React.createElement(CnPageFooter, __assign({ className: className }, otherProps),
            React.createElement(CnButtonGroup, { fullWidth: true, size: "large" },
                React.createElement(CnReset, __assign({}, resetProps, { form: formInstance, onReset: onReset })),
                React.createElement(CnSubmit, __assign({ size: "large", type: "primary" }, submitProps, { form: formInstance, onSubmit: onSubmit })))));
    }, [footerConfig, formInstance, hasFooterSubmit, onReset, onSubmit]);
    return (React.createElement(FormProvider, { form: formInstance },
        React.createElement(ExpressionScope, { value: { $$form: formInstance } },
            React.createElement(CnFormLayout, __assign({}, formLayoutProps),
                React.createElement(SchemaField, { schema: schema, components: components }),
                children)),
        footer));
};
CnForm.displayName = 'CnForm';
