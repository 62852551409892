import { __spreadArray } from "tslib";
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import { useEffect } from 'react';
// 当value的值在dataSource中不存在时，调用一次接口获取数据
export default function useInitRequest(params) {
    var value = params.value, filterLocal = params.filterLocal, useDetailValue = params.useDetailValue, requestService = params.requestService, initRequestService = params.initRequestService, lru = params.lru, mutate = params.mutate;
    useEffect(function () {
        if (filterLocal || useDetailValue)
            return;
        if (!isNumber(value) && !isString(value) && !Array.isArray(value))
            return;
        var listValue = Array.isArray(value) ? value : [value];
        var newValue = listValue.filter(function (item) { return !(lru === null || lru === void 0 ? void 0 : lru.get(item)); });
        if (isEmpty(newValue))
            return;
        if (!isFunction(initRequestService)) {
            newValue.reduce(function (pre, cur) {
                var result = requestService(cur);
                pre = pre.finally(function () {
                    return result.then(function (data) {
                        data === null || data === void 0 ? void 0 : data.forEach(function (item) {
                            lru === null || lru === void 0 ? void 0 : lru.set(item.value, item);
                        });
                        mutate(function (dataSource) {
                            if (dataSource === void 0) { dataSource = []; }
                            var safeDataSource = Array.isArray(dataSource)
                                ? dataSource
                                : [];
                            return __spreadArray(__spreadArray([], safeDataSource, true), data, true);
                        });
                    });
                });
                return pre;
            }, Promise.resolve());
        }
        else {
            initRequestService(value).then(function (data) {
                data === null || data === void 0 ? void 0 : data.forEach(function (item) {
                    lru === null || lru === void 0 ? void 0 : lru.set(item.value, Promise.resolve(item));
                });
                mutate(function (dataSource) {
                    var safeDataSource = Array.isArray(dataSource) ? dataSource : [];
                    return __spreadArray(__spreadArray([], safeDataSource, true), data, true);
                });
            });
        }
    }, [value]);
}
