import $i18n from 'panda-i18n';
import * as React from 'react';
import { CnList, CnListItem } from '@/components/cn-list';
import { CnIcon } from '@/components/cn-icon';
function CascaderItemList(props) {
    var options = props.options, mode = props.mode, clsPrefix = props.clsPrefix, loadData = props.loadData, val = props.val, loadingNode = props.loadingNode, onClick = props.onClick;
    var loadingIcon = (React.createElement(CnIcon, { className: "".concat(clsPrefix, "-icon--loading"), type: "progressing", size: "small" }));
    return options.length === 0 ? (React.createElement("div", { className: "".concat(clsPrefix, "-not-found") }, $i18n.get({
        id: 'NoDataForTheTimeBeing_958511081',
        dm: '暂无数据',
        ns: 'CnCascaderSelect',
    }))) : (React.createElement(CnList, { className: "".concat(clsPrefix, "-list") }, options.map(function (item) {
        var icon = null;
        var hasArrow = (loadData && !item.isLeaf) ||
            (!loadData && item.children && item.children.length > 0);
        var optionChecked = (loadData && item.value === val) || (!loadData && item.value === val);
        if (loadData && loadingNode === item.value) {
            icon = loadingIcon;
        }
        return (React.createElement(CnListItem, { className: optionChecked ? "".concat(clsPrefix, "-list-item-checked") : '', key: item.value, title: item.label, extra: icon, arrow: mode === 'cascade' && hasArrow && !icon ? 'right' : undefined, disabled: item.disabled, onClick: function () { return onClick(item); } }));
    })));
}
export { CascaderItemList };
