import { __assign } from "tslib";
import React, { forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useRef, } from 'react';
import { usePropsValue } from '@/utils/use-props-value';
import { mergeProps } from '@/utils/with-default-props';
import { Wrapper } from './wrapper';
import { useShouldRender } from './should-render';
import { CnPopoverModal } from './popover-modal';
var defaultProps = {
    placement: 'top',
    destroyOnHide: true,
    defaultVisible: false,
    getContainer: function () { return document.body; },
};
export var CnPopover = forwardRef(function (p, ref) {
    var props = mergeProps(defaultProps, p);
    var _a = props.mode, mode = _a === void 0 ? 'light' : _a, placement = props.placement;
    var modalRef = useRef(null);
    var _b = usePropsValue({
        value: props.visible,
        defaultValue: props.defaultVisible,
        onChange: props.onVisibleChange,
    }), visible = _b[0], setVisible = _b[1];
    useImperativeHandle(ref, function () {
        return {
            show: function () { return setVisible(true); },
            hide: function () { return setVisible(false); },
            visible: visible,
        };
    }, [setVisible, visible]);
    var targetRef = useRef(null);
    useLayoutEffect(function () {
        var _a;
        (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.floatingUpdate(targetRef);
    });
    useEffect(function () {
        var _a;
        var dom = (_a = targetRef.current) === null || _a === void 0 ? void 0 : _a.element;
        if (!dom)
            return;
        if (!props.trigger)
            return;
        function handleClick() {
            setVisible(function (v) { return !v; });
        }
        dom.addEventListener('click', handleClick);
        return function () {
            dom.removeEventListener('click', handleClick);
        };
    }, [props.trigger, setVisible]);
    var clickAwayCallback = React.useCallback(function () {
        if (!props.trigger)
            return;
        setVisible(false);
    }, [props.trigger, setVisible]);
    var shouldRender = useShouldRender(visible, false, props.destroyOnHide);
    return (React.createElement(React.Fragment, null,
        React.createElement(Wrapper, { ref: targetRef }, props.children),
        shouldRender ? (React.createElement(CnPopoverModal, __assign({}, props, { visible: visible, clickAwayCallback: clickAwayCallback, ref: modalRef, floatingMode: mode, floatingContent: props.content, placement: placement, container: props.getContainer(), className: props.className }))) : null));
});
CnPopover.displayName = 'CnPopover';
