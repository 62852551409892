import { __spreadArray } from "tslib";
import React, { createContext, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState, } from 'react';
import { useFetchDataService } from '../hooks/use-fetch-data-service';
import { useToolbarButtons } from '../hooks/use-toolbar-buttons';
import { useFormLabelAlign } from '@cainiaofe/cn-ui-common';
export var CardItemsStateCtx = createContext({});
export var CardItemsContext = forwardRef(function (props, ref) {
    var children = props.children, tableProps = props.tableProps;
    var operateColumn = tableProps.operateColumn, toolbar = tableProps.toolbar, showSelectProps = tableProps.showSelect, rowSelection = tableProps.rowSelection, onSelectChangeProps = tableProps.onSelectChange, icon = tableProps.icon, title = tableProps.title, desc = tableProps.desc, action = tableProps.action, subIcon = tableProps.subIcon, subTitle = tableProps.subTitle, subDesc = tableProps.subDesc, subAction = tableProps.subAction, tags = tableProps.tags, requestConfig = tableProps.requestConfig, disableDetail = tableProps.disableDetail, detailLabelAlign = tableProps.detailLabelAlign, isOnecode = tableProps.isOnecode, emptyContent = tableProps.emptyContent;
    var labelAlign = useFormLabelAlign(tableProps.labelAlign);
    var _a = rowSelection || {}, selectedRowKeys = _a.selectedRowKeys, defaultValue = _a.defaultValue;
    var selectedKeysIsControlled = rowSelection && 'selectedRowKeys' in rowSelection;
    var _b = useState(new Set(defaultValue)), selectedKeys = _b[0], setSelectedKeys = _b[1];
    var data = useFetchDataService({
        requestConfig: requestConfig,
        beforeRefreshCallback: function () {
            if (!selectedKeysIsControlled) {
                setSelectedKeys(new Set());
            }
        },
    });
    var totalCount = data.totalCount;
    var loading = data.loading, _c = data.dataSource, dataSource = _c === void 0 ? tableProps.dataSource || [] : _c, loadNextPage = data.loadNextPage, refreshAsync = data.refreshAsync, currentPageRefreshAsync = data.currentPageRefreshAsync;
    var columns = (tableProps.columns || data.columns || []).filter(function (item) {
        // hidden 可能是其它数据结构，所以这里严格判断是否是 true
        if (item.hidden === true) {
            return false;
        }
        return true;
    });
    var _primaryKey = useMemo(function () {
        if (tableProps.primaryKey)
            return tableProps.primaryKey;
        return columns === null || columns === void 0 ? void 0 : columns.reduce(function (result, item) {
            if (result)
                return result;
            return item.dataIndex;
        }, undefined);
    }, [columns, tableProps.primaryKey]);
    var primaryKey = _primaryKey || 'id';
    // 当数据源返回的数据中不存在配置的主键时，使用下标替换 primaryKey
    var useDataIndex = !(primaryKey in (dataSource[0] || {}));
    var showSelect = showSelectProps || Boolean(tableProps.rowSelection);
    useEffect(function () {
        if (!selectedKeysIsControlled)
            return;
        setSelectedKeys(new Set(selectedRowKeys));
    }, [selectedKeysIsControlled, selectedRowKeys]);
    var selectedDataSource = useMemo(function () {
        return Array.from(selectedKeys).map(function (val) {
            if (useDataIndex)
                return dataSource[val];
            return dataSource.find(function (item) { return item[primaryKey] === val; });
        });
    }, [dataSource, primaryKey, selectedKeys, useDataIndex]);
    var onSelectChange = useCallback(function (_selectedKeys) {
        var _a;
        var selectedKeysArray = Array.from(_selectedKeys);
        var _selectedDataSource = selectedKeysArray.map(function (val) {
            if (useDataIndex)
                return dataSource[val];
            return dataSource.find(function (item) { return item[primaryKey] === val; });
        });
        onSelectChangeProps === null || onSelectChangeProps === void 0 ? void 0 : onSelectChangeProps(__spreadArray([], selectedKeysArray, true), _selectedDataSource);
        (_a = rowSelection === null || rowSelection === void 0 ? void 0 : rowSelection.onChange) === null || _a === void 0 ? void 0 : _a.call(rowSelection, __spreadArray([], selectedKeysArray, true), _selectedDataSource);
    }, [
        dataSource,
        onSelectChangeProps,
        primaryKey,
        rowSelection,
        selectedKeys,
        useDataIndex,
    ]);
    useImperativeHandle(ref, function () { return ({
        refreshAsync: refreshAsync,
        currentPageRefreshAsync: currentPageRefreshAsync,
    }); });
    var entryTitle = 
    // @ts-ignore 临时兼容lowcode
    tableProps.entryTitle || tableProps.listPlaceholder || tableProps.tableName;
    var toolbarButtons = useToolbarButtons({
        toolbar: toolbar,
        selectedKeys: selectedKeys,
        selectedDataSource: selectedDataSource,
    });
    var showTotal = totalCount && totalCount !== 0;
    var output = {
        toolbarButtons: toolbarButtons,
        showToolbar: showSelect || showTotal || Boolean(toolbarButtons.length),
        entryTitle: entryTitle,
        loading: loading,
        icon: icon,
        title: title,
        desc: desc,
        action: action,
        subIcon: subIcon,
        subTitle: subTitle,
        subDesc: subDesc,
        subAction: subAction,
        tags: tags,
        totalCount: totalCount,
        hasNextPage: data.hasNextPage,
        primaryKey: primaryKey,
        useDataIndex: useDataIndex,
        onSelectChange: onSelectChange,
        dataSource: dataSource,
        columns: columns || [],
        operateColumn: operateColumn,
        loadNextPage: loadNextPage,
        toolbar: toolbar,
        showSelect: showSelect,
        selectedKeys: selectedKeys,
        setSelectedKeys: setSelectedKeys,
        selectedDataSource: selectedDataSource,
        disableDetail: disableDetail,
        labelAlign: labelAlign,
        detailLabelAlign: detailLabelAlign,
        isOnecode: isOnecode,
        emptyContent: emptyContent,
    };
    return (React.createElement(CardItemsStateCtx.Provider, { value: output }, children));
});
CardItemsContext.displayName = 'CardItemsContext';
