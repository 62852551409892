import isPlainObject from 'lodash/isPlainObject';
import isFunction from 'lodash/isFunction';
var Plugin = /** @class */ (function () {
    function Plugin(componentName, pluginManager) {
        var _this = this;
        this.componentName = '';
        this.plugin = {};
        this.basicsPlugin = [];
        this.localPlugin = [];
        this.globalPlugin = [];
        this.setGlobalPlugin = function (globalPlugin) {
            if (globalPlugin === void 0) { globalPlugin = []; }
            if (Array.isArray(globalPlugin)) {
                _this.globalPlugin = globalPlugin;
            }
            else if (isPlainObject(globalPlugin)) {
                _this.globalPlugin = Object.values(globalPlugin);
            }
        };
        this.pluginManager = pluginManager;
        this.componentName = componentName;
        pluginManager.getGlobalPlugin();
    }
    // 设置内置插件
    Plugin.prototype.setBasicsPlugin = function (basicsPlugin) {
        if (Array.isArray(basicsPlugin)) {
            this.basicsPlugin = this.basicsPlugin.concat(basicsPlugin);
        }
        else if (isPlainObject(basicsPlugin)) {
            this.basicsPlugin = this.basicsPlugin.concat(Object.values(basicsPlugin));
        }
    };
    Plugin.prototype.setLocalPlugin = function (localPlugin) {
        var _this = this;
        if (localPlugin === void 0) { localPlugin = []; }
        if (typeof localPlugin === 'string') {
            var plugin = this.pluginManager.getPluginByPluginName(localPlugin, this.componentName);
            if (isFunction(plugin === null || plugin === void 0 ? void 0 : plugin.method)) {
                this.localPlugin = [plugin.method];
            }
        }
        if (Array.isArray(localPlugin)) {
            this.localPlugin = localPlugin.reduce(function (pre, item) {
                if (isFunction(item)) {
                    pre.push(item);
                }
                else if (isPlainObject(localPlugin)) {
                    pre.push.apply(pre, Object.values(localPlugin));
                }
                else if (typeof item === 'string') {
                    var plugin = _this.pluginManager.getPluginByPluginName(item, _this.componentName);
                    if (isFunction(plugin === null || plugin === void 0 ? void 0 : plugin.method)) {
                        pre.push(plugin.method);
                    }
                }
                return pre;
            }, []);
        }
        else if (isPlainObject(localPlugin)) {
            this.localPlugin = Object.values(localPlugin);
        }
    };
    Plugin.prototype.getPlugin = function () {
        return this.basicsPlugin.concat(this.localPlugin).concat(this.globalPlugin);
    };
    return Plugin;
}());
export { Plugin };
