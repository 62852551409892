import { __assign } from "tslib";
import * as React from 'react';
import classNames from 'classnames';
export function withNativeProps(props, element) {
    var p = __assign({}, element.props);
    if (props.className) {
        p.className = classNames(element.props.className, props.className);
    }
    if (props.style) {
        p.style = __assign(__assign({}, p.style), props.style);
    }
    if (props.tabIndex !== undefined) {
        p.tabIndex = props.tabIndex;
    }
    if (props.elementtiming !== undefined) {
        p.elementtiming = props.elementtiming;
    }
    for (var key in props) {
        // eslint-disable-next-line no-prototype-builtins
        if (!props.hasOwnProperty(key))
            continue;
        if (key.startsWith('data-') || key.startsWith('aria-')) {
            p[key] = props[key];
        }
    }
    return React.cloneElement(element, p);
}
// 过滤Native props 正则，不希望被透传
export var nativePropsReg = /data-|elementtiming|-/;
