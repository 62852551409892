import { __assign } from "tslib";
import React, { forwardRef } from 'react';
import { CnLoading } from '@/components/cn-loading';
import { useStyleOverrides } from '../../hooks/use-style-overrides';
var More = forwardRef(function (props, ref) {
    var moreRender = props.moreRender, style = props.style;
    var overrides = useStyleOverrides(props, 'scroll-view-more');
    return (React.createElement("div", __assign({ className: "cn-ui-m-scroll-view-more" }, props, overrides, { style: style, ref: ref }), moreRender || React.createElement(CnLoading, { visible: true, size: "small" })));
});
export default More;
