import { __assign } from "tslib";
import * as React from 'react';
import { DateRangePicker } from '../date-range-picker';
import { DateRangeCalendar } from '../date-range-calendar';
import { DateTimeRangePicker } from '../date-time-range-picker';
export var CnRangeDatePickerPro = function (props) {
    var mode = props.mode, showTime = props.showTime;
    if (['year', 'month', 'quarter'].includes(mode)) {
        return React.createElement(DateRangePicker, __assign({}, props));
    }
    if (['hour', 'minute', 'second'].includes(mode) || showTime) {
        return React.createElement(DateTimeRangePicker, __assign({}, props));
    }
    return React.createElement(DateRangeCalendar, __assign({}, props));
};
CnRangeDatePickerPro.displayName = 'CnRangeDatePickerPro';
