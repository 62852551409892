import { __spreadArray } from "tslib";
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear';
import isLeapYear from 'dayjs/plugin/isLeapYear';
dayjs.extend(isoWeek);
dayjs.extend(isoWeeksInYear);
dayjs.extend(isLeapYear);
var precisionRankRecord = {
    year: 0,
    week: 1,
    'week-day': 2,
};
export function defaultRenderLabel(type, data) {
    return data.toString();
}
export function generateDatePickerColumns(selected, min, max, precision, renderLabel, filter) {
    var ret = [];
    var minYear = min.getFullYear();
    var maxYear = max.getFullYear();
    var rank = precisionRankRecord[precision];
    var selectedYear = parseInt(selected[0], 10);
    var isInMinYear = selectedYear === minYear;
    var isInMaxYear = selectedYear === maxYear;
    var minDay = dayjs(min);
    var maxDay = dayjs(max);
    var minWeek = minDay.isoWeek();
    var maxWeek = maxDay.isoWeek();
    var minWeekday = minDay.isoWeekday();
    var maxWeekday = maxDay.isoWeekday();
    var selectedWeek = parseInt(selected[1], 10);
    var isInMinWeek = isInMinYear && selectedWeek === minWeek;
    var isInMaxWeek = isInMaxYear && selectedWeek === maxWeek;
    var selectedYearWeeks = dayjs("".concat(selectedYear, "-01-01")).isoWeeksInYear();
    var generateColumn = function (from, to, precision2) {
        var column = [];
        for (var i = from; i <= to; i++) {
            column.push(i);
        }
        var prefix = selected.slice(0, precisionRankRecord[precision2]);
        var currentFilter = filter === null || filter === void 0 ? void 0 : filter[precision2];
        if (currentFilter && typeof currentFilter === 'function') {
            column = column.filter(function (i) {
                return currentFilter(i, {
                    get date() {
                        var stringArray = __spreadArray(__spreadArray([], prefix, true), [i.toString()], false);
                        return convertStringArrayToDate(stringArray);
                    },
                });
            });
        }
        return column;
    };
    if (rank >= precisionRankRecord.year) {
        var lower = minYear;
        var upper = maxYear;
        var years = generateColumn(lower, upper, 'year');
        ret.push(years.map(function (v) {
            return {
                label: renderLabel('year', v),
                value: v.toString(),
            };
        }));
    }
    if (rank >= precisionRankRecord.week) {
        var lower = isInMinYear ? minWeek : 1;
        var upper = isInMaxYear ? maxWeek : selectedYearWeeks;
        var weeks = generateColumn(lower, upper, 'week');
        ret.push(weeks.map(function (v) {
            return {
                label: renderLabel('week', v),
                value: v.toString(),
            };
        }));
    }
    if (rank >= precisionRankRecord['week-day']) {
        var lower = isInMinWeek ? minWeekday : 1;
        var upper = isInMaxWeek ? maxWeekday : 7;
        var weeks = generateColumn(lower, upper, 'week-day');
        ret.push(weeks.map(function (v) {
            return {
                label: renderLabel('week-day', v),
                value: v.toString(),
            };
        }));
    }
    return ret;
}
export function convertDateToStringArray(date) {
    if (!date)
        return [];
    var day = dayjs(date);
    return [day.isoWeekYear().toString(), day.isoWeek().toString(), day.isoWeekday().toString()];
}
export function convertStringArrayToDate(value) {
    var _a, _b, _c;
    var yearString = (_a = value[0]) !== null && _a !== void 0 ? _a : '1900';
    var weekString = (_b = value[1]) !== null && _b !== void 0 ? _b : '1';
    var weekdayString = (_c = value[2]) !== null && _c !== void 0 ? _c : '1';
    var day = dayjs()
        .year(parseInt(yearString, 10))
        .isoWeek(parseInt(weekString, 10))
        .isoWeekday(parseInt(weekdayString, 10))
        .hour(0)
        .minute(0)
        .second(0);
    return day.toDate();
}
