import './styles/index.scss';
import { withI18n } from 'panda-i18n';
import locale from '@/locales';
import { CnEmbedPicker } from './view/embed-picker';
import { CnTimePicker as UICnTimePicker } from './view/time-picker';
import { CnRangeTimePickerPro as UICnRangeTimePickerPro } from './view/cn-range-time-picker-pro';
var CnTimePicker = withI18n(UICnTimePicker, { locale: locale });
var CnRangeTimePickerPro = withI18n(UICnRangeTimePickerPro, { locale: locale });
// @ts-ignore
var CnTimePickerPro = CnTimePicker;
CnTimePicker.displayName = 'CnTimePickerPro';
CnTimePickerPro.CnEmbedPicker = CnTimePicker;
export { CnEmbedPicker, CnTimePickerPro, CnTimePicker, CnRangeTimePickerPro };
