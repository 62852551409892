import React, { forwardRef, useContext, useImperativeHandle, useState, } from 'react';
import { CnSearch } from '../../cn-search';
import isFunction from 'lodash/isFunction';
import SelectContext from '../context';
var SelectSearch = function (props, ref) {
    var _a = useContext(SelectContext), prefix = _a.prefix, setSearchValue = _a.setSearchValue, searchValue = _a.value, innerValue = _a.innerValue, contextSearch = _a.onSearch, searchLoading = _a.searchLoading, searchPlaceholder = _a.searchPlaceholder;
    var onSearch = props.onSearch;
    var _b = useState(''), value = _b[0], setValue = _b[1];
    useImperativeHandle(ref, function () { return ({
        setValue: function (v) {
            setValue(v);
            setSearchValue(v);
        },
    }); });
    var handleSearch = function (v) {
        setValue(v);
        setSearchValue(v);
        if (isFunction(onSearch)) {
            onSearch(v);
        }
        if (isFunction(contextSearch)) {
            // @ts-ignore
            contextSearch(v, searchValue, innerValue);
        }
    };
    return (React.createElement("div", { className: "".concat(prefix, "select-search-wrapper"), style: { width: '100%', height: '100rpx' } },
        React.createElement(CnSearch, { value: value, hasClear: true, placeholder: searchPlaceholder, onChange: handleSearch, onClear: function () {
                handleSearch('');
            }, loading: searchLoading })));
};
export default forwardRef(SelectSearch);
