import { updateParentNodeState } from './update-parent-node-state';
/**
 * 副作用1：当是选中时，需要将所有子节点除去disabled的全部选中。当是去除选中时，需要将所有子节点除去disabled的全部去除选中
 * @param nodes 节点列表
 * @param pos 节点位置
 */
export function updateParentNodeInTree(nodes, pos) {
    var callbacksList = [];
    nodes.forEach(function (node) {
        if (pos !== (node === null || node === void 0 ? void 0 : node.pos) && (pos === null || pos === void 0 ? void 0 : pos.startsWith(node === null || node === void 0 ? void 0 : node.pos))) {
            callbacksList.push(function () { return updateParentNodeState(node); });
        }
        else if (pos === (node === null || node === void 0 ? void 0 : node.pos) && node.checked) {
            var touchOrder = window.sessionStorage.getItem('CnCascaderSelect_touchOrder') || '0';
            var newTouchOrder = Number(touchOrder) + 1;
            window.sessionStorage.setItem('CnCascaderSelect_touchOrder', newTouchOrder.toString());
            node.touchOrder = newTouchOrder;
        }
        if (node.children && node.children.length > 0) {
            updateParentNodeInTree(node.children, pos);
        }
    });
    callbacksList.reverse().forEach(function (fn) { return fn(); });
}
