import { __assign, __rest, __spreadArray } from "tslib";
import * as React from 'react';
export function getDataSourceFromChildren(children) {
    if (!children) {
        return [];
    }
    return React.Children.map(children, function (child) {
        var _a = child.props, value = _a.value, text = _a.text, childText = _a.children, rest = __rest(_a, ["value", "text", "children"]);
        return __assign({ value: value, label: childText || text }, rest);
    });
}
function normalizeDataSource(dataSource) {
    if (!Array.isArray(dataSource))
        return [];
    var source = [];
    dataSource.forEach(function (item) {
        // enable array of basic type
        if (/string|boolean|number/.test(typeof item) ||
            item === null ||
            item === undefined) {
            item = { label: "".concat(item), value: item };
        }
        source.push(item);
    });
    return source;
}
export function getDataSource(dataSource, children) {
    var ds = normalizeDataSource(dataSource);
    if (!dataSource && React.Children.count(children) > 0) {
        ds = getDataSourceFromChildren(children);
    }
    return ds || [];
}
export function isValidArray(obj) {
    return Array.isArray(obj) && obj.length > 0;
}
export function findInArray(arr, fn) {
    if (arr === void 0) { arr = []; }
    var ret = null;
    function recursiveSearch(array) {
        if (isValidArray(array)) {
            for (var i = 0; i < array.length; i++) {
                var item = array[i];
                if (fn(item, i)) {
                    ret = item;
                    break;
                }
                if (item.children) {
                    recursiveSearch(item.children);
                }
                if (ret) {
                    break;
                }
            }
        }
    }
    recursiveSearch(arr);
    return ret;
}
export function isNumber(num) {
    if (num === '' || num === null) {
        return false;
    }
    return (typeof num === 'number' && !isNaN(num)) || !isNaN(Number(num));
}
/**
 * 判断变量是否未定义
 * @param obj
 */
export function isUndef(obj) {
    return typeof obj === 'undefined';
}
export function isNil(val) {
    return typeof val === 'undefined' || val === null;
}
/**
 * 合并两个数据
 * 注意： 只对 value 做对比， 如果 value 相同，则会忽略
 *  eg:
 *    mergeDataSource([{label:1, value:1}, {label:2, value:2}], [{label: 1, value:1}]) => [{label:1, value:1}, {label:2, value:2}]
 * @param ds1
 * @param ds2
 */
export function mergeDataSource(ds1, ds2) {
    var tmpArr = [];
    ds2.forEach(function (it) {
        if ((it === null || it === void 0 ? void 0 : it.value) &&
            !findInArray(__spreadArray(__spreadArray([], ds1, true), tmpArr, true), function (_it) { return _it.value === it.value; })) {
            tmpArr.push(it);
        }
    });
    return __spreadArray(__spreadArray([], tmpArr, true), ds1, true);
}
export function replaceMessage(template, kv) {
    return template.replace(/\${\w+}/g, function (str) {
        var key = str.slice(2, -1);
        return kv[key];
    });
}
export function stopPropagation(e) {
    if (!e) {
        return;
    }
    if (e.stopPropagation) {
        e.stopPropagation();
        return;
    }
    if (typeof e.originalEvent === 'object' && e.originalEvent.stopPropagation) {
        e.originalEvent.stopPropagation();
    }
}
