/**
 * 刷新数据，将数据刷新到childrenTree中
 * @param valueList 值列表
 * @param childrenTree 子节点树
 * @returns 更新后的子节点树
 */
export function initializeData(valueList, childrenTree) {
    var updateChildrenStatus = function (nodes, checked) {
        nodes.forEach(function (node) {
            var _a;
            if (node === null || node === void 0 ? void 0 : node.disabled) {
                return;
            }
            var curChecked = checked || !!(valueList === null || valueList === void 0 ? void 0 : valueList.includes(node.value));
            node.checked = curChecked;
            if ((_a = node === null || node === void 0 ? void 0 : node.children) === null || _a === void 0 ? void 0 : _a.length) {
                updateChildrenStatus(node.children, curChecked);
            }
        });
    };
    var updateParentStatus = function (nodes) {
        var someChecked = false;
        nodes === null || nodes === void 0 ? void 0 : nodes.forEach(function (node) {
            var _a;
            if ((_a = node === null || node === void 0 ? void 0 : node.children) === null || _a === void 0 ? void 0 : _a.length) {
                var some = updateParentStatus(node.children);
                node.indeterminate = !node.checked && some;
                someChecked = someChecked || some;
            }
            else {
                someChecked = someChecked || node.checked;
            }
        });
        return someChecked;
    };
    updateChildrenStatus(childrenTree);
    updateParentStatus(childrenTree);
    return childrenTree;
}
