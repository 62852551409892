import { isRemoveComponent } from './is-remove-component';
export var canDelete = function (props) {
    if (props.hasDeleteButton === true)
        return true;
    return (props.schema
        .mapProperties(function (fieldSchema) {
        if (fieldSchema.name === 'operateColumn') {
            return ((fieldSchema === null || fieldSchema === void 0 ? void 0 : fieldSchema.mapProperties(function (operateSchema) {
                return isRemoveComponent(operateSchema);
            }).filter(Boolean).length) > 0);
        }
        return false;
    })
        .filter(Boolean).length > 0);
};
