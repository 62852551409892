import { useMemo } from 'react';
import { withCache } from '@/components/cn-picker/with-cache';
export function generateColumnsExtend(rawColumns, val) {
    var columns = withCache(function () {
        var c = typeof rawColumns === 'function' ? rawColumns(val) : rawColumns;
        return c.map(function (column) {
            return column.map(function (item) {
                if (typeof item === 'string') {
                    return {
                        label: item,
                        value: item,
                    };
                }
                else {
                    return item;
                }
            });
        });
    });
    var items = withCache(function () {
        return val.map(function (v, index) {
            var _a;
            var column = columns()[index];
            if (!column)
                return null;
            return (_a = column.find(function (item) { return item.value === v; })) !== null && _a !== void 0 ? _a : null;
        });
    });
    var extend = {
        get columns() {
            return columns();
        },
        get items() {
            return items();
        },
    };
    return extend;
}
export function useColumnsExtend(rawColumns, value) {
    return useMemo(function () { return generateColumnsExtend(rawColumns, value); }, [rawColumns, value]);
}
