import { __assign } from "tslib";
import React, { useState } from 'react';
import { renderToRoot } from '@/utils/render';
import { CnDialog } from './dialog';
var DialogWrapper = function (props) {
    var onClose = props.onClose;
    var _a = useState(true), visible = _a[0], setVisible = _a[1];
    var handleClose = function (reason, e) {
        setVisible(false);
        setTimeout(function () {
            if (onClose) {
                onClose(reason, e);
            }
        }, 300);
    };
    return (React.createElement(CnDialog, __assign({}, props, { onClose: handleClose, visible: visible, disablePortal: true })));
};
var hideStack = [];
var methodFactory = function (type) { return function (props) {
    return show(__assign(__assign({}, props), { type: type }));
}; };
export var show = function (props) {
    var emptyFn = function () { };
    var _a = props.onClose, onClose = _a === void 0 ? emptyFn : _a;
    var hideCurrent = renderToRoot(React.createElement(DialogWrapper, __assign({}, props, { onClose: function (reason, e) {
            onClose(reason, e);
            onClose = emptyFn;
            hideCurrent();
        } })), function () { return onClose('api', null); });
    hideStack.push(hideCurrent);
    return {
        hide: hideCurrent,
    };
};
export var hide = function () {
    while (hideStack.length) {
        var h = hideStack.pop();
        if (h) {
            h.call(null);
        }
    }
};
export var alert = methodFactory('alert');
export var confirm = methodFactory('confirm');
export var success = methodFactory('success');
export var error = methodFactory('error');
export var notice = methodFactory('notice');
export var warning = methodFactory('warning');
export var help = methodFactory('help');
