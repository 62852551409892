import { useLayoutEffect, useRef, useState } from 'react';
export var useItemStyle = function (props) {
    var mode = props.mode;
    var _a = useState(0), itemSize = _a[0], setItemSize = _a[1];
    var containerRef = useRef(null);
    useLayoutEffect(function () {
        if (!containerRef.current)
            return;
        if (mode === 'single')
            return;
        var rect = containerRef.current.getBoundingClientRect();
        setItemSize(function (prev) {
            if (prev)
                return prev;
            if (rect.width < 300)
                return 80;
            var _itemSize = (rect.width - 12 * 3) / 4;
            if (_itemSize > 80)
                return 80;
            return _itemSize;
        });
    }, [mode]);
    if (mode === 'single') {
        return { containerRef: containerRef };
    }
    return {
        containerRef: containerRef,
        itemStyle: {
            width: itemSize,
            height: itemSize,
        },
    };
};
